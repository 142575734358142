import { useEffect, useState } from "react"
// custom hook to get current screen resolution
function useWindowSize() {
    const [currentResolution, setcurrentResolution] = useState({
        currentWidth: window.innerWidth,
        currentHeight: window.innerHeight,
    })

    useEffect(() => {
        function handleResize() {
            setcurrentResolution({
                currentWidth: window.innerWidth,
                currentHeight: window.innerHeight,
            })
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    return currentResolution;
}


export default useWindowSize;