import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";


export const getAllCrops = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/crops`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting crops."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting crops.");
        }
    });
}


export const addCrop = (crop) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/crops`,
                {
                    method: "POST",
                    body: JSON.stringify(crop),
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while adding crop."
                );
            }
        } catch (err) {
            reject("Something went wrong while adding crop.");
        }
    })
}


export const deleteCrop = (cropId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/crops/${cropId}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while deleting crop."
                );
            }
        } catch (err) {
            reject("Something went wrong while deleting crop.");
        }
    })
}

export const updateCrop = (crop) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/crops`,
                {
                    method: "PUT",
                    body: JSON.stringify(crop),
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while updating crop."
                );
            }
        } catch (err) {
            reject("Something went wrong while updating crop.");
        }
    })
}