import React, { useEffect, useState } from 'react'
import Popup from '../../ReusableFunctions/Popup'
import Loading from '../../ReusableFunctions/Loader'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { getAllUsers } from '../../apis/adminAPIs/users'
import ViewUser from './SubComponents/ViewUser'
import TableView from '../../ReusableComponents/TableView/TableView'
import Search from '../../ReusableComponents/Search'
import _debounce from 'lodash/debounce';
const PAGE_SIZE = 15

const Users = () => {
    const [loading, setLoading] = useState()
    const [users, setUsers] = useState([])
    const [userCount, setUserCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedUser, setSelectedUser] = useState()
    const [searchString, setSearchString] = useState("")

    const getAllUsersFunc = async () => {
            try {
                getAllUsers(PAGE_SIZE, currentPage, searchString).then(users => {
                    Loading.set(false)
                    setLoading(false)
                    setUsers(users?.allUsers)
                    setUserCount(users.count || 0)
                })
            } catch (err) {
                setLoading(false)
                Loading.set(false)
                Popup("Error", err, "ERROR")
            }
        }
    useEffect(() => {
        Loading.set(true)
        setLoading(true)
        getAllUsersFunc()
    }, [])

    useEffect(() => {
        const debouncedGetAllUsers = _debounce(() => {
            getAllUsersFunc();
        }, 3000);

        debouncedGetAllUsers();

        return () => {
            debouncedGetAllUsers.cancel();
        };
    }, [searchString, currentPage]);

    return (

        <MainContentWrapper leftContent={`Users (${userCount})`}
            rightContent={<Search
                searchValue={searchString}
                onChange={(value) => {
                    setLoading(true);
                    setSearchString(value)
                    setCurrentPage(1)
                }} />}
        >
            {selectedUser && <ViewUser open={selectedUser} onClose={() => {
                setSelectedUser()
                localStorage.setItem('dsp_id', "admin")
            }}
                user={selectedUser}
                callback={(dsp) => {
                    setUsers(prev => prev.map(d => {
                        if (d.dsp_id === dsp.dsp_id) return dsp;
                        else return d
                    }))
                    localStorage.setItem('dsp_id', dsp.dsp_id)
                    setSelectedUser(dsp)
                }}
            />}

            <TableView
                isLoading={loading}
                tableContainerHeight={userCount < 15 ? "calc(100% - 40px)" : "calc(100% - 65px)"}
                showSrNo
                onStateChange={(changedState) => {
                    setLoading(true);
                    setCurrentPage(changedState.pageNumber)
                }}
                onClickRow={(user) => {
                    localStorage.setItem("dsp_id", user.id)
                    setSelectedUser(user)
                }}
                onClickLink={(request) => { }}
                columns={[
                    { Header: "Name", accessor: "name", width: "10%" },
                    { Header: "Address", accessor: "address", width: "15%" },
                    { Header: "Phone number", accessor: "mobile_number", width: "10%", },
                    { Header: "Email", accessor: "email", width: "10%" },
                    { Header: "Occupation", accessor: "occupation", width: "5%", },
                    { Header: "Created date", accessor: "last_request_date", width: "10%", isDate: true },
                ]}
                data={users}
                count={userCount}
                pageNumber={currentPage}
                pageSize={PAGE_SIZE}
            />
        </MainContentWrapper>
    )
}

export default Users