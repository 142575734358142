import React, { useState } from "react";
import { GLOBAL_STYLES } from "../../../../../appConstants";
import Check from "../../../../../assets/check.svg";
import Drone from "../../../../../assets/drone.svg";
import Location from "../../../../../assets/location.svg";
import Money from "../../../../../assets/money.svg";
import Calender from "../../../../../assets/calender.svg";
import Survey from "../../../../../assets/survey.svg";
import { getFormatedDate, reduceLongName } from "../../../../../ReusableFunctions/reusableFunctions";
import { Link } from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import { ROUTES } from "../../../../../routes";
import '../../../../../index.css';
const SurveyServiceCard = ({ service, isEditDisabled }) => {
  const [morePopup, setMorePopup] = useState(false)
  return (
    <>
      <div className="service-card-style" >
        <div className="service-card-heading-style" style={{ background: "rgba(194, 105, 0, 0.098)" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM_HEADER,
            }}
          >
            <img className="service-card-header-icon" src={Survey} />{" "}
            <div className="service-card-heading-text-style"> {reduceLongName(service.service_type.split(" ")[0], 10)}</div>
          </div>
          {/* <Button text={"Edit"} onClick={() => setOpenUpdateServicePopup(true)} /> */}
          {isEditDisabled ? <></> : <Link
            to={ROUTES.ADD_SERVICE}
            state={{
              aeroMeghServiceId: service.aeromegh_service_id,
              serviceId: service.id,
            }}
          >
            <div style={{ position: "relative" }}>
              <span className='underlined-bg-active'
                style={{
                  cursor: "pointer",
                  color: GLOBAL_STYLES.BG_ACTIVE,
                  textDecoration: "none",
                  fontWeight: "600",
                }}
              >
                Edit
              </span>
            </div>
          </Link>}
        </div>
        <div
          className="service-card-containt-wrapper"
          style={{ fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "" }}>
            <img className="service-card-containt-icon" src={Location} />
            <div
              style={{
                fontWeight: 500,
                width: "75px",
                display: "flex",
                margin: "0px 15px 0px 15px",
                justifyContent: "space-between",
                fontSize: "15px",
                minWidth: "100px",
              }}
            >
              <span className="service-card-containt-labels">Locations</span>
              <div>:</div>
            </div>
            <span className="service-card-containt-labels" >{service.locations.length}</span>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "" }}>
            <img className="service-card-containt-icon" src={Money} />
            <div
              style={{
                fontWeight: 500,
                width: "75px",
                display: "flex", margin: "0px 15px 0px 15px",
                justifyContent: "space-between",
                fontSize: "15px",
                minWidth: "100px",
              }}
            >
              <span className="service-card-containt-labels">Rate/ Km <sup>2</sup></span>
              <div>:</div>
            </div>
            <span className="service-card-containt-labels" >
              {service.parameters.rate_per_sq_km} Rs.
            </span>
          </div>

          <div style={{ display: "flex", alignItems: "start", gap: "" }}>
            <img className="service-card-containt-icon" src={Drone} />
            <div
              style={{
                fontWeight: 500,
                width: "75px",
                display: "flex", margin: "0px 15px 0px 15px",
                justifyContent: "space-between",
                fontSize: "15px",
                minWidth: "100px",
              }}
            >
              <span className="service-card-containt-labels" style={{
                whiteSpace: "initial",
                wordWrap: "break-word",
              }}>Drones and devices</span>
              <div>:</div>
            </div>
            <div
              className="service-card-containt-labels"
              style={{
                width: "180px",
                whiteSpace: "initial",
                wordWrap: "break-word",
                fontSize: "15px",
                fontWeight: 500
              }}
            >
              {[...service.parameters.drone_types, ...service.parameters.devices].length > 0 ? (
                [...service.parameters.drone_types, ...service.parameters.devices].join(", ").length > 25 ? (
                  <div>
                    {" "}
                    <span className="service-card-containt-labels">
                      {[...service.parameters.drone_types, ...service.parameters.devices]
                        .join(", ")
                        .substring(0, 25)}
                    </span>
                    <Tippy trigger="click" content={[...service.parameters.drone_types, ...service.parameters.devices]
                      .join(", ")}>
                      <span style={{ color: GLOBAL_STYLES.BG_ACTIVE, cursor: "pointer" }} onClick={() => setMorePopup({ droneTypeOpen: true, })}>
                        {" "}
                        ...More
                      </span>
                    </Tippy>

                  </div>
                ) : (
                  <div>{[...service.parameters.drone_types, ...service.parameters.devices].join(", ")}</div>
                )
              ) : (
                0
              )}
            </div>
          </div>
          {/* <div style={{ display: "flex", alignItems: "start", gap: "20px" }}>
            <img style={{ width: "32px" }} src={Drone} />
            <div
              style={{
                fontWeight: 500,
                width: "75px",
                display: "flex",margin:"0px 15px 0px 15px",
                justifyContent: "space-between",
                fontSize:"15px",
                  minWidth:"100px",
              }}
            >
              <span>Devices</span>
              <div>:</div>
            </div>
            <div
              style={{
                color: "#1E1E1E",
                width: "130px",
                whiteSpace: "initial",
                wordWrap: "break-word",
              }}
            >
              {service.parameters.devices.length > 0 ? (
                service.parameters.devices.join(", ").length > 25 ? (
                  <div>
                    {" "}
                    <span>
                      {service.parameters.devices.join(", ").substring(0, 25)}
                    </span>{" "}
                    <Link
                      to={ROUTES.ADD_SERVICE}
                      state={{
                        aeroMeghServiceId: service.aeromegh_service_id,
                        serviceId: service.id,
                      }}
                    >
                      <Tippy trigger="click" content={service.parameters.devices.join(", ")}>
                        <div style={{ color: GLOBAL_STYLES.BG_ACTIVE }}>

                          ...More
                        </div>
                      </Tippy>
                    </Link>
                  </div>
                ) : (
                  <div>{service.parameters.devices.join(", ")}</div>
                )
              ) : (
                0
              )}
            </div>
          </div> */}

          <div style={{ display: "flex", alignItems: "center", gap: "" }}>
            <img className="service-card-containt-icon" src={Calender} />
            <div
              style={{
                fontWeight: 500,
                width: "75px",
                display: "flex", margin: "0px 15px 0px 15px",
                justifyContent: "space-between",
                fontSize: "15px",
                minWidth: "100px",
              }}
            >
              <span className="service-card-containt-labels">Started on</span>
              <div>:</div>
            </div>
            <span className="service-card-containt-labels" style={{ width: "60px" }}>
              {getFormatedDate(service.start_date)}
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "" }}>
            <img className="service-card-containt-icon" src={Check} />
            <div
              style={{
                fontWeight: 500,
                width: "75px",
                display: "flex", margin: "0px 15px 0px 15px",
                justifyContent: "space-between",
                fontSize: "15px",
                minWidth: "100px",
              }}
            >
              <span className="service-card-containt-labels">Status</span>
              <div>:</div>
            </div>
            <span
              className="service-card-containt-labels"
              style={{
                fontWeight: 700,
                color: service.is_active
                  ? GLOBAL_STYLES.ACTIVE
                  : GLOBAL_STYLES.ERROR,
              }}
            >
              {service.is_active ? "Active" : "Inactive"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyServiceCard;
