import React, { useState } from 'react'
import { validatePhoneNumber } from '../../../../../ReusableFunctions/reusableFunctions';
import { updateUserData } from '../../../../../apis/users';
import Modal from '../../../../../ReusableComponents/Modal';
import { Button } from '../../../../../Components/Helper';
import Input from '../../../../../ReusableComponents/Input';
import { GLOBAL_STYLES, LIMITS } from '../../../../../appConstants';
import Loading from '../../../../../ReusableFunctions/Loader';
import Popup from '../../../../../ReusableFunctions/Popup';
import OTPVerification from '../../../../../ReusableComponents/OTPVerification';

const EditPhone = ({ user, open, onClose, onUserUpdate }) => {
    let oldPhone = user.phone
    const [userInfo, setUserInfo] = useState(user)
    const [error, setError] = useState({
        phone: ''
    });
    const onChangeUserInfo = (e) => {
        const name = e.target.name
        const value = e.target.value.slice(0, 10)
        checkIsValid(name, value)
        setUserInfo((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    const checkIsValid = (name, value) => {
        setError((prevErrors) => ({ ...prevErrors, [name]: '' }));

        switch (name) {
            case 'phone':
                if (!validatePhoneNumber(value)) {
                    setError((prevErrors) => ({
                        ...prevErrors,
                        phone: 'Please enter a valid phone number',
                    }));
                    return false;
                }
                break;
            default:
                setError((prevErrors) => ({ ...prevErrors, [name]: '' }));
                break;
        }
        return true;
    }
    const updateProfileInfo = async () => {
        Loading.set(true);
        try {
            await updateUserData(userInfo)
                .then((res) => {
                    onUserUpdate(userInfo)
                    Loading.set(false);
                })
                .catch((e) => {
                    Loading.set(false);
                    console.log(e)
                    Popup.alert(
                        "Profile",
                        e,
                        "ERROR",
                        () => { }
                    );
                })
        } catch (e) {
            Loading(false)
            console.log(e)
            Popup.alert(
                "Profile",
                "Something went wrong while updating profile",
                "ERROR",
                () => { }
            );
        }
    }
    return (
        <Modal
            header={'Edit Number'}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "534px", height: "auto" }}
            divider={true}
            headingStyle={{ fontSize: "18px" }}
            iconSize={"18px"}
            headerContainerStyle={{ padding: '20px 40px' }}
        >
            <div style={{ padding: "15px 46px" }}>
                <p style={{ paddingBottom: "17px", fontSize: '16px', color: '#1e1e1e', fontWeight: 500 }}>
                    Enter your mobile number, we will send otp on that number to verify
                </p>
                <form>
                    <div>
                        <div style={{ paddingBottom: "7px", fontSize: '16px', color: '#3b3b3b', fontWeight: "400" }}>
                            Mobile number
                        </div>
                        <input
                            onChange={onChangeUserInfo}
                            name={"phone"}
                            placeholder={"88997756655"}
                            value={userInfo?.phone}
                            required={true}
                            style={{ height: '51px', width: "100%", borderRadius: "8px", border: '1px solid #BFC8D9', padding: '5px 15px' }}
                            maxLength={LIMITS.PHONE_NUMBER}
                            type={"number"}
                            inputStyle={{ fontSize: "16px" }}
                            onKeyDown={(e) => {                                
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    document.getElementById("sendOTPDiv").click()
                                }
                            }}
                        />
                        {error.phone &&
                            <div style={{ color: "red", padding: "5px 0px 0px 0px", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>
                                {error.phone}
                            </div>}
                    </div>
                </form>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    padding: "28px 138px 20px 122px",
                }}
            >
                <div>
                    <OTPVerification
                        userId={userInfo.id}
                        sendOtpTo={userInfo.phone}
                        type={"mobile"}
                        btnText={
                            <Button
                                text={"Continue"}
                                onClick={() => { }}
                                style={{ width: "141px", height: "47px", fontSize: '16px' }}
                                isDisabled={error.phone || oldPhone === userInfo.phone}
                            />}
                        callback={(verified) => {
                            if (verified)
                                updateProfileInfo()
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default EditPhone