import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import Popup from '../../ReusableFunctions/Popup'
import Loading from '../../ReusableFunctions/Loader'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import { addLeadingZero } from '../../ReusableFunctions/reusableFunctions'
import { PDRL_ADMIN_ROUTES } from '../../routes'
import droneCardIcon from "../../assets/droneCardIcon.svg"
import droneCardIconActive from "../../assets/droneCardIconActive.svg"
import customersCardIcon from "../../assets/customersCardIcon.svg"
import teamsCardIcon from "../../assets/teamsCardIcon.svg"
import teamsCardIconActive from "../../assets/teamsCardIconActive.svg"
import locationCardIcon from "../../assets/locationCardIcon.svg"
import serviceCardIcon from "../../assets/serviceCardIcon.svg"
import cropIcon from "../../assets/crop.png"
import plantIcon from "../../assets/Plant.png"
import serviceCardIconActive from "../../assets/serviceCardIconActive.svg"
import customersCardIconActive from "../../assets/customersCardIconActive.svg"
import locationCardIconActive from "../../assets/locationCardIconActive.svg"
import { getAdminDashboardOverview } from '../../apis/adminAPIs/overview'
import Orders from '../../features/HomePage/SubComponents/Graphs/Orders'

const AdminDashBoard = () => {
    const [dashBoardData, setDashBoardData] = useState({})

    const getAdminDashboardOverviewFunc = async () => {
        Loading.set(true)
        try {
            const overview = await getAdminDashboardOverview()
            setDashBoardData(overview)
            Loading.set(false)
        } catch (err) {
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(() => {
        getAdminDashboardOverviewFunc()
        localStorage.setItem('dsp_id', "admin")
    }, [])

    return (dashBoardData.dsps && <MainContentWrapper>
        <div className="dashboard-first-section-second-div" style={{ width: "100%" }} >
            <div className="dashboard-first-section-second-div-one" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }} >

                {/* ---- DSPs ---- */}
                <DashBoardCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.DRONE_SERVICE_PROVIDERS}
                    title="DSPs"
                    data={addLeadingZero(dashBoardData.dsps)}
                    icon={customersCardIcon}
                    hoverIcon={customersCardIconActive}
                />

                {/* ---- Users ---- */}
                <DashBoardCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    title="Users"
                    data={addLeadingZero(dashBoardData.users)}
                    icon={teamsCardIcon}
                    hoverIcon={teamsCardIconActive}
                />

                {/* ---- Requests ---- */}
                <DashBoardCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.REQUESTS}
                    title="Requests"
                    data={addLeadingZero(Object.values(dashBoardData.requests).reduce((acc, curr) => acc + Number(curr), 0))}
                    icon={serviceCardIcon}
                    hoverIcon={serviceCardIconActive}
                />

                {/* ---- Locations ---- */}
                <DashBoardCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.LOCATIONS}
                    title="Locations"
                    data={addLeadingZero(dashBoardData.locations)}
                    icon={locationCardIcon}
                    hoverIcon={locationCardIconActive}
                />

                {/* ---- Manufactures ---- */}
                <DashBoardCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.MANUFACTURES}
                    title="Manufactures"
                    data={addLeadingZero(dashBoardData.manufacturers)}
                    icon={droneCardIcon}
                    hoverIcon={droneCardIconActive}
                />

                {/* ---- Crops ---- */}
                <DashBoardCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.CROPS}
                    title="Crops"
                    data={addLeadingZero(dashBoardData.crops)}
                    icon={cropIcon}
                    hoverIcon={plantIcon}
                />
            </div>
        </div>
        <div style={{ display: "flex", width: "100%", gap: "15px", alignItems: "", height: "100%", justifyContent: "start", padding: "10px 0px" }}>
            <Orders width="100%" filter />
        </div>
    </MainContentWrapper>
    )
}

export default AdminDashBoard