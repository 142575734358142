import React from 'react'
import Modal from '../../../../../ReusableComponents/Modal'
import { Button } from '../../../../../Components/Helper'
import { ROUTES } from '../../../../../routes'
import { reduceLongName } from '../../../../../ReusableFunctions/reusableFunctions'
import { AEROMEGH_SERVICES, STATUS } from '../../../../../appConstants'
import dateFormat from 'dateformat';
import useWindowSize from '../../../../../hooks/useWindowSize'

const ViewRequestPopup = ({ open, onClose, request, user }) => {
    const isTrainingService = request?.type === AEROMEGH_SERVICES.TRAINING
    const isSprayingService = request?.type === AEROMEGH_SERVICES.SPRAYING;
    const isSurveyService = request?.type === AEROMEGH_SERVICES.SURVEY;
    const currentResolution = useWindowSize();
    const fieldData = [
        { label: 'Request Number', value: request?.req_number },
        { label: 'Customer Name', value: request?.user_name },
        { label: 'Location', value: request?.location_address, overflow: true },
        { label: 'Contact No.', value: request?.user_phone },
        { label: 'Service', value: request?.type },
        { label: 'Service Location', value: request?.field_address, overflow: true },
        (isSprayingService || isSurveyService) && { label: 'Drone Type', value: request?.assignedDrone?.type },
        isSprayingService && { label: 'Crop', value: request?.crop_name },
        isSprayingService && { label: 'Agro Chemicals', value: request?.pesticides },
        (isSprayingService || isSurveyService) && { label: 'Drone UIN', value: request?.assignedDrone?.drone_uin },
        isTrainingService && { label: 'Date', value: request?.date_created ? dateFormat(request.date_created, "dd/mm/yyyy") : "-" },
        isTrainingService && { label: 'Time', value: request?.date_created ? dateFormat(request.date_created, "hh:MM TT") : "-" },
        (isSprayingService || isSurveyService) && { label: 'Date', value: request?.time ? dateFormat(request.time, "dd/mm/yyyy") : "-" },
        (isSprayingService || isSurveyService) && { label: 'Time', value: request?.time ? dateFormat(request.time, "hh:MM TT") : "-" },
        (isSprayingService || isSurveyService) && {
            label: 'Pilot',
            value: request?.assingedPilot ? reduceLongName(
                request.assingedPilot.first_name && request.assingedPilot.last_name ?
                    `${request.assingedPilot.first_name} ${request.assingedPilot.last_name}` :
                    (request.assingedPilot.first_name || request.assingedPilot.last_name || '-'), 20
            ) : "-"
        },
        { label: 'Status', value: request?.status },
        (isSprayingService || isSurveyService) && { label: 'Estimated Amount', value: request?.estimated_amount ? request.estimated_amount + " Rs." : "-" },
        (isSprayingService || isSurveyService) && { label: 'Received Payment', value: request?.amount ? request.amount + " Rs." : "-" },
        isTrainingService && { label: 'Price', value: request?.price ? request.price + " Rs." : "-" }
    ].filter(Boolean);
    const Field = ({ label, value, overflow }) => (
        <div className='single_field_wrapper' style={{ lineHeight: overflow ? 1.15 : 1.1 }}>
            <div className='single_field_label' >{label}</div>
            <div className='single_field_value' style={{
                maxHeight: overflow && value?.length > 120 ? "100px" : "none",
                overflowY: overflow && value?.length > 120 ? "auto" : "hidden"
            }}>
                {value || "-"}
            </div>
        </div>
    );
    return (
        <Modal header={"Request Details"} divider open={open} onClose={onClose} headingStyle={{ padding: "7px 0px " }} contentStyle={{
            height: "auto", width: "850px", border: "1px solid #BFC8D9", boxShadow: "0px 1px 4px 0px #76767626",
            borderRadius: '7px', overflow: "hidden",

        }} headerContainerStyle={{ padding: currentResolution.currentHeight > 750 ? "20px 30px" : "10px 30px" }}>
            <div className='request_fields_wrapper'>
                {fieldData.map((field, index) => (
                    <Field key={index} label={field.label} value={field.value} overflow={field.overflow} />
                ))}
            </div>
            <div className='request_details_footer' style={{ justifyContent: (request?.user_id == null || request?.user_id == '') ? "space-between" : "flex-end" }}>
                {
                    (request?.user_id == null || request?.user_id == '') && (
                        <div style={{
                            color: "crimson",
                            maxWidth: "400px",
                            wordWrap: "break-word",
                            lineHeight: 1.1,
                            fontSize: "13px",
                        }}>
                            Request cannot be modified as the user has deleted their account.
                        </div>

                    )
                }{
                    request?.status === STATUS.PAID ?
                        null
                        : <Button
                            isDisabled={request?.user_id === null || request?.user_id === ''}
                            text={"Modify Request"}
                            redirectTo={request?.status === STATUS.PAID || request?.user_id === null || request?.user_id === '' ? null : ROUTES.VIEWREQUEST}
                            state={{ requestId: request?.id, requestType: request?.type, assingedPilot: request?.assingedPilot, assignedDrone: request?.assignedDrone, user: user }}
                            className={"request_details_modify_btn"}
                        />
                }
            </div>
        </Modal >
    )
}

export default ViewRequestPopup