import React from "react";
import ReactDOM from "react-dom";
import Loader from "../ReusableComponents/Loader";
const Loading = {
  set: (open) => {
    ReactDOM.render(<Loader open={open} />, document.getElementById("loader"));
  },
};

export default Loading;
