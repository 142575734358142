import React, { useEffect, useState } from 'react'
import { Button, CrossCloseButton } from '../Components/Helper';
import OTPInput from 'react-otp-input';
import Modal from './Modal';
import { sendVerificationOTP, verifyOTP } from '../apis/verification';
import Loading from '../ReusableFunctions/Loader';
import Popup from "../ReusableFunctions/Popup"
import { GLOBAL_STYLES } from '../appConstants';
const OTPVerification = ({ userId, sendOtpTo, type, btnText, callback }) => {
    const [otp, setOtp] = useState("")
    const [openOTP, setOpenOTP] = useState(false)
    const [error, setError] = useState("")
    const sendOTP = () => {
        setOpenOTP(true);
        sendVerificationOTP({ [type]: sendOtpTo })
            .then((res) => { })
            .catch((err) => {
                console.log(err);
                Popup.alert("Error", "Something went wrong while sending OTP", "ERROR");
            });
    };
    const handleVerification = () => {
        Loading.set(true);

        const OTP = { [type]: sendOtpTo, otp };
        verifyOTP(OTP)
            .then((res) => {
                Loading.set(false);
                Popup.alert(
                    "SUCCESS",
                    type === "mobile"
                        ? <p>Congratulations ! <br /> We are delighted to inform you that your number has been verified successfully</p>
                        : type === "email"
                            ? <p>Congratulations ! <br /> We are delighted to inform you that your email has been verified successfully</p>
                            : "",
                    "SUCCESS"
                );

                if (type === "mobile") {
                    localStorage.setItem("phone-verified", sendOtpTo);
                }
                if (type === "email") {
                    localStorage.setItem("email-verified", sendOtpTo);
                }
                if (localStorage.getItem("user")) {
                    localStorage.removeItem("user");
                }
                localStorage.setItem("user", userId);
                setOpenOTP(false);
                callback(true)
            })
            .catch((err) => {
                Loading.set(false);
                console.log(err);
                setError("Please enter valid OTP")
                const input = document.getElementById('input-otp-0')
                input.focus()
            });
        setOtp("");
    };
    useEffect(() => {
        if (otp.length >= 6) handleVerification();
    }, [otp.length]);
    return (
        <>
            <Modal
                open={openOTP}
                onClose={() => setOpenOTP(false)}
                position="center"
                contentStyle={{ height: "auto", width: "615px" }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        gap: "",
                        padding: "40px 55px",
                    }}
                >
                    <div style={{ width: "100%", padding: "0px 20px", display: "flex", height: "80px", flexDirection: "column", gap: "1rem" }}>
                        <div
                            style={{
                                fontWeight: "600",
                                fontSize: '18px',
                                width: "100%",
                                textAlign: "left",
                                color: '#1e1e1e',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <span>
                                OTP Verification
                            </span>
                        </div>
                        <div style={{ position: 'absolute', right: 25, top: 25 }}>
                            <CrossCloseButton width={'18px'} onClick={() => setOpenOTP(false)} />
                        </div>
                        <div style={{ fontSize: '16px', color: "#727272", marginBottom: "25px", textAlign: "left", width: "100%" }}>
                            {type === "mobile"
                                ? "Please check your SMS and enter OTP for verification"
                                : "Please check your email and enter OTP for verification"}
                        </div>

                    </div>
                    <div style={{ width: "100%", margin: "10px 0px 0px 15px", }}>
                        <OTPInput
                            value={otp}
                            onChange={(value) => {
                                setOtp((value));
                                setError("")
                            }}
                            numInputs={6}
                            renderSeparator={<span> </span>}
                            renderInput={(props, i) => <input id={`input-otp-${i}`} {...props} />}
                            containerStyle={{}}
                            inputStyle={{
                                width: "58px",
                                height: "58px",
                                margin: "0px 25px 0px 0px",
                                fontSize: "20px",
                                fontWeight: 500,
                                border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
                                borderRadius: "7px",
                            }}
                            onPaste={(event) =>
                                setOtp((event.clipboardData.getData("text")
                                ))
                            }
                        />

                    </div>
                    {error && <div style={{ color: "#EA0606", padding: "8px 0px 0px 9px", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL, width: "100%", textAlign: "left" }}>{error} </div>}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                            width: "100%",
                            marginTop: "40px",
                            height: '43px'
                        }}
                    >
                        <Button
                            style={{ width: "92%", fontSize: '16px', height: "auto", padding: "7px 20px" }}
                            text={"Verify"}
                            onClick={handleVerification}
                            isDisabled={otp.length < 6}
                        />
                    </div>
                </div>
            </Modal >
            <div style={{ width: "50px", textAlign: "left" }}>
                <div
                    id="sendOTPDiv"
                    style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        textDecoration: "underline",
                    }}
                    className="form-link"
                    onClick={() => sendOTP()}
                >
                    {btnText}
                </div>
            </div>
        </>
    )
}

export default OTPVerification