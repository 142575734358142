import { getDSPId } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getUserFields = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/user/fields/" + userId ,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data.userFields);
      } else {
        reject(
          data?.message || "Something went wrong while getting fields data."
        );
      }
    } catch (error) {
      console.error(error);
      reject("Something went wrong while getting fields data.");
    }
  });
};

export const getFieldData = (fieldId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "fields/getFieldData/" + fieldId ,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data.userFields);
      } else {
        reject(
          data?.message || "Something went wrong while getting field data."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting field data.");
    }
  });
};
