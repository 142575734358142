import React from 'react'
import { ROUTES } from '../routes'
import pilotIcon from "../assets/sidebaricons/pilotInactive.svg";
import pilotIconActive from "../assets/sidebaricons/pilotActive.svg";

const PilotIcon = (location) => {
    return (
        <img alt='' className={location.location === ROUTES.DRONE_PILOTS ? "sideBarIcon" : "sideBarIcon-not-selected"} src={location.location === ROUTES.DRONE_PILOTS ? pilotIconActive : pilotIcon} />
    )
}

export default PilotIcon