import React, { useEffect, useState } from 'react'
import { getStatusColor } from '../../../../ReusableFunctions/reusableFunctions'

import NotFounds from '../../../../ReusableComponents/NotFounds'
import FilterRequest from '../FilterRequest/FilterRequest'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import noRequest from '../../../../assets/no-request.svg'
import ViewRequestPopup from '../ViewRequest/SubComponents/ViewRequestPopup'
import TableView from '../../../../ReusableComponents/TableView/TableView'
import search from "../../../../assets/search.svg"
import { ROUTES } from '../../../../routes'

const getCurrentScreenSize = () => {
    const width = window.innerWidth;
    if (width >= 1860) {
        return "x-large"
    } else if (width < 1860 && width > 1620) {
        return "large";
    } else if (width <= 1620 && width > 1400) {
        return "medium";
    } else if (width > 1200 && width <= 1400) {
        return "small"
    }
}

const RequestTable = ({ pageNumber, requests, onFilterChange, loading, setLoading, currentPage, totalCount, onCurrentPageChange, pageSize, isFromUserDetails, onSearch, tabelContainerHeight, hidePagination }) => {
    const nevigate = useNavigate();
    const { state } = useLocation();
    const [openFilter, setOpenFilter] = useState(false)
    const [currentWidth, setcurrentWidth] = useState(getCurrentScreenSize());
    const [localRequest, setLocalRequests] = useState(requests)
    const [screenWidth, setScreenWidth] = useState(1366)

    useEffect(() => {
        setLocalRequests(requests)
    }, [requests])

    useEffect(() => {
        const screenWidth = window.innerWidth;
        setScreenWidth(screenWidth)
        const handleResize = () => {
            setcurrentWidth(getCurrentScreenSize())
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.addEventListener("resize", handleResize);
        }
    }, [])

    return (
        <>
            <div style={{ fontWeight: 600, display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", gap: "10px", padding: "0px 0px 15px" }}>
                <div className='request-table-header-title' >
                    <div className='request-table-header-title-div' >
                        All Requests
                    </div>
                    {onSearch &&
                        <div style={{ position: "relative", height: "40px", width: "100%" }}>
                            <img src={search} alt='search icon' style={{ position: 'absolute', left: "20px", top: "10px" }} />
                            <input onChange={(e) => onSearch(e.target.value)}
                                placeholder='Search request by request no...'
                                style={{ width: "100%", height: '100%', borderRadius: "4px", border: "1px solid #BFC8D9", padding: "10px 10px 10px 60px", fontSize: "12px" }} />
                        </div>
                    }
                </div>
                <FilterRequest
                    user={state?.userId}
                    open={openFilter}
                    onClose={() => setOpenFilter(false)}
                    callback={(filters) => {
                        onCurrentPageChange(1);
                        onFilterChange(filters)
                    }}
                    setOpenFilter={setOpenFilter}
                    screenWidth={currentWidth}
                    loading={loading}
                    setLoading={setLoading}
                    isFromUserDetails={isFromUserDetails}
                />
            </div>
            <TableView
                loadingHeight={"300px"}
                isLoading={loading}
                onStateChange={async (changedState) => {
                    const { pageNumber } = changedState;
                    onCurrentPageChange(pageNumber);
                }}
                onClickRow={(request) => {
                    nevigate(`${ROUTES.REQUESTDETAILS}`, { state: { request: request } })
                }}
                columns={[
                    { Header: "Request No.", accessor: "req_number" },
                    { Header: "Location", accessor: "location_name" },
                    { Header: "Brief Address", accessor: "location_address" },
                    { Header: "Service", accessor: "serviceType" },
                    { Header: "Customer Name", accessor: "user_name" },
                    { Header: "Date Received", accessor: "date_created", isDate: true, format: "dd/mm/yyyy" },
                    { Header: "Last update", accessor: "date_created", isDate: true, format: "dd/mm/yyyy" },
                    { Header: "Status", accessor: "status", showColor: true },
                ]}
                count={totalCount}
                pageSize={pageSize}
                pageNumber={pageNumber || undefined}
                data={localRequest?.map((req) => ({ ...req, status: req.status, serviceType: req?.type.split(' ')[0], color: getStatusColor(req.status, true) }))}
                tableContainerHeight={tabelContainerHeight || '500px'}
                hidePagination={hidePagination}
            />
        </>

    )
}

export default RequestTable