import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";

export const getAllDSPs = (pageSize, pageNumber, string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/dsp?searchString=${string == "" ? ' ' : string}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting dsps."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting dsps.");
        }
    });
}
export const getAllDSPsCount = (dspId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/dsp/getDSPCount?dspId=${dspId}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting dsps count."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting dsps count.");
        }
    });
}
export const updateDSP = (dsp) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + "/aggregator/dsp",
                {
                    method: "PUT",
                    body: JSON.stringify({ dsp }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while updating dsp."
                );
            }
        } catch (error) {
            reject("Something went wrong while updating dsp.");
        }
    });
}