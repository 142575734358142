import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { isUserLoggedIn } from "./apis/authentication";
import { ALL_ROUTES_ARRAY, PDRL_ADMIN_ROUTES_COMPONENTS, ROUTES } from "./routes";
import NavBar from "./Components/NavBar";
import Login from "./features/Login/Login";
import SignUp from "./features/SignUp/SignUp";
import F404 from "./features/404/F404";
import HomePage from "./features/HomePage/HomePage";
import ResetPassword from "./features/ResetPassword/ResetPassword";
import ProtectedRoute from "./ReusableComponents/ProtectedRoute";
import Profile from "./features/Profile/Profile";
import ProSidebar from "./Components/ProSidebar";
import { ErrorBoundary } from "react-error-boundary"
const App = () => {
  return (
    <Router>
      <div style={{ display: "flex", height: "100vh" }}>
        <ProSidebar />
        <div className="main-app-wrapper" style={{ transition: "width 0.8 ease-in" }}>
          {isUserLoggedIn() && <NavBar />}
          <Routes>
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            {isUserLoggedIn() ? (
              <>
                <Route exact path="/" element={<ProtectedRoute element={<HomePage />} />} />
                {ALL_ROUTES_ARRAY?.map((route, key) => (
                  <Route key={key} path={route.path} element={<ErrorBoundary fallback={<Navigate to={"/"} />}><ProtectedRoute element={route.element} /></ErrorBoundary>} />
                ))}
                <Route path={ROUTES.PROFILE} element={<Profile />} />
                {PDRL_ADMIN_ROUTES_COMPONENTS.map((route, key) => (
                  <Route key={key} path={route.path} element={<ErrorBoundary fallback={<Navigate to={"/"} />}><ProtectedRoute element={route.element} adminOnly /></ErrorBoundary>} />
                ))}
                <Route path="*" element={<F404 />} />
              </>
            ) : (
              <Route path="*" element={<Navigate to="/login" />} />
            )}
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
