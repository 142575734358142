import React, { useEffect, useState } from "react";
import { getUserFields } from "../apis/fields";
// import { LIST_VIEW_TYPES } from '../appConstants';
import MapView from "../features/LeafletMap/Map";
import { drawPolygon, fitMapBounds } from "../features/LeafletMap/functions";
import { DropDownWithButton } from "./Helper";
import { useParams } from "react-router";

const NoFieldsAvailbale = () => {
  return <div> No Fileds available.</div>;
};

const Fields = () => {
  const [fieldsData, setFieldsData] = useState(null);
  const [field, setField] = useState(null);
  const { userId } = useParams();

  const initData = () => {
    if (!fieldsData)
      getUserFields(userId)
        .then((f) => {
          setFieldsData(f);
        })
        .catch((e) => {
          alert(e);
        });
  };
  useEffect(initData, [userId]);

  const onClick = (field) => {
    setField(field);
  };

  return (
    // type === LIST_VIEW_TYPES.DROPDOWN ? <FieldsDropdown fields={fieldsData} onClick={onClick} /> :
    <div>
      <h2>Fields</h2>
      {fieldsData?.length > 0 ? (
        <>
          <FieldsListView fields={fieldsData} onClick={onClick} />
          {field ? <FieldMap field={field} /> : <></>}
        </>
      ) : (
        <NoFieldsAvailbale />
      )}
    </div>
  );
};

const FieldsDropdown = ({ fields, onClick }) => {
  const [selectedField, setSetselectedField] = useState("No fields available");
  useEffect(() => {
    if (fields?.length > 0) {
      setSetselectedField(fields[0]?.name);
      onClick(fields[0]);
    } else {
      setSetselectedField("No fields available");
    }
  }, [fields]);
  return (
    <>
      <DropDownWithButton
        list={
          fields?.length > 0
            ? fields.map((field) => {
                return {
                  name: field.name,
                  onClick: () => {
                    onClick(field);
                    setSetselectedField(field.name);
                  },
                };
              })
            : [
                {
                  name: "No fields available",
                  onClick: () => {
                    setSetselectedField("No fields available");
                  },
                },
              ]
        }
        selectedItem={selectedField}
        width={"100%"}
        height={"80px"}
      />
    </>
  );
};

const FieldsListView = ({ fields, onClick }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>area</th>
          <th>date_created</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field) => (
          <tr
            key={field.id}
            onClick={() => {
              if (onClick) {
                onClick(field);
              }
            }}
          >
            <td>{field.name}</td>
            <td>{field.area}</td>
            <td>{field.date_created}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const FieldMap = ({ field, style }) => {
  const [map, setMap] = useState(null);
  const [polygon, setPolygon] = useState(null);

  const drawField = async (boundary, map) => {
    if (polygon) {
      polygon.setLatLngs(boundary);
      fitMapBounds(polygon.getBounds(), map);
    } else {
      let drawnPolygon = await drawPolygon(boundary, map);
      setPolygon(drawnPolygon);
      fitMapBounds(drawnPolygon.getBounds(), map);
    }
  };

  const triggerChangefield = () => {
    if (field) drawField(field.boundary, map);
    else if (polygon) {
      polygon.remove();
      setPolygon(null);
    }
  };

  useEffect(() => {
    if (map) triggerChangefield();
  }, [field, map]);

  return (
    <div style={{ width: "100%", height: "100%", ...style }}>
      <MapView mapInitialized={(map) => setMap(map)} />
    </div>
  );
};

export { Fields, FieldsListView, FieldMap };
