import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ChevronDown from "../assets/chevron-down.svg"
import { login, logoutUser } from "../apis/authentication";
import { Link } from "react-router-dom";
import {
  Menu,
  MenuItem,
} from '@mui/material'
import { ROUTES } from "../routes";
import Loading from "../ReusableFunctions/Loader";
import Popup from "../ReusableFunctions/Popup";
import Notifications from "./Notifications";
import LoaderComponent from "../ReusableComponents/LoaderComponent";
import { reduceLongName } from "../ReusableFunctions/reusableFunctions";
const NavBar = () => {
  const userReduxUpdate = useSelector(state => state.user)
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [user, setUser] = useState({ name: "" });
  const [loading, setLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (userReduxUpdate.user) {
      Loading.set(true)
      setLoading(true);
      login().then((user) => {
        setUser(user);
        setLoading(false);
        Loading.set(false)
      }).catch(e => {
        Loading.set(false)
        setLoading(false);
        Popup.alert("Error", e, "ERROR")
      })
    }
  }, [userReduxUpdate])

  return (
    <div className="nav-bar" >
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          gap: "39px",
          alignItems: "center",
        }}
      >
        <div style={{ fontWeight: 500, color: "#1E1E1E", fontSize: "14px" }}>BhuMeet</div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "13px"
        }}
      >
        {!user.is_pdrl_admin &&
          <Notifications />
        }
        {loading ? <LoaderComponent height={"1%"} width={"1%"} />
          : <div
            className="profile-pic"
            style={{
              width: "25px",
              height: "25px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              src={user?.profile_url || `https://ui-avatars.com/api/?background=727272&color=fff&name=${user?.name?.split(" ").join("+")}`}
              alt=""
            />
          </div>
        }
        <div
          style={{
            fontWeight: "700",
            fontSize: '14px',
          }}
        >
          {user?.name ? reduceLongName(user?.name.split(' ')[0], 20) : "User"}
        </div>
        <div
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <img style={{ height: "14px" }} src={ChevronDown} alt='chevron-down' />
        </div>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,0.22))',
              mt: 1.5,
              width: "80px",
              fontSize: "10px",
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 5,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Link to={
            ROUTES.PROFILE
          } style={{ color: "black" }}>
            <MenuItem>
              <span className="profile-toggle-text" style={{ fontSize: '11px', color: "#3b3b3b" }} >Profile</span>
            </MenuItem>
          </Link>
          <Link onClick={() => {
            logoutUser()
          }} style={{ color: "black" }}>
            <MenuItem>
              <span className="profile-toggle-text" style={{ fontSize: '11px', color: "#3b3b3b" }} >Logout</span>
            </MenuItem>
          </Link>
        </Menu>
      </div>
    </div>
  );
};

export default NavBar;
