import React, { useEffect, useState } from "react";
import Input from "../../../../../ReusableComponents/Input";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
    combineDateTime,
    getFormatedDate, getFormattedTimeFromDate
} from "../../../../../ReusableFunctions/reusableFunctions";
import { Button } from "../../../../../Components/Helper";
import Modal from "../../../../../ReusableComponents/Modal";
import { getRequest, updateRequest } from "../../../../../apis/requests";
import { GLOBAL_STYLES, STATUS } from "../../../../../appConstants";
import { getLocations } from "../../../../../apis/locations";
import { getDronePilots } from "../../../../../apis/dronePilots";
import Popup from "../../../../../ReusableFunctions/Popup";
import Loading from "../../../../../ReusableFunctions/Loader";
import MainContentWrapper from "../../../../../Components/MainContentWrapper";
import Select from "../../../../../ReusableComponents/SelectComponent";
import Calender from "../../../../../assets/Calender2.svg"
import Clock from "../../../../../assets/clock.svg"
import { ROUTES } from "../../../../../routes";
import { Link, useNavigate } from "react-router-dom"
import SelectComponent from "../../../../../ReusableComponents/SelectComponent";
import { setHours, setMinutes } from "date-fns";
import dateFormat from 'dateformat';
import { allStatus } from '../SubComponents/ViewSprayingRequest'
import RequestDiscount from "../../../../../ReusableComponents/RequestDiscount";
const ViewTrainingRequest = ({ user, req }) => {
    const navigate = useNavigate()
    const [trainingRequest, setTrainingRequest] = useState({ location_id: null });
    const [dronePilots, setDronePilots] = useState([]);
    const [noValueChanged, setNoValueChanged] = useState(true);
    const [showTimePicker, setShowTimePicker] = useState(false)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [statusList, setstatusList] = useState([]);
    const [isStatusChange, setisStatusChange] = useState(false);
    const [isInputEditable, setisInputEditable] = useState(false);
    const oldStatus = req.status;
    const saveRequestDetails = () => {
        Loading.set(true);
        updateRequest(trainingRequest, req.type)
            .then((res) => {
                Loading.set(false);
                Popup.alert(
                    "Training Request",
                    "Service updated successfully",
                    "SUCCESS",
                    () => {
                        navigate(ROUTES.MYREQUESTS)
                    }
                );
            })
            .catch((err) => {
                Loading.set(false);
                Popup.alert("Error", err.errorMessage, "ERROR", () => { });
                console.log(err);
            });
    };
    useEffect(() => {
        Loading.set(true);
        setTrainingRequest(req);
        getDronePilots()
            .then((res) => {
                setDronePilots(res.dronePilots);
                Loading.set(false);
            })
            .catch((err) => {
                Loading.set(false);
                console.log(err);
                Popup.alert("Error", err.errorMessage, "ERROR", () => { });
            });
    }, [req]);
    useEffect(() => {
        const isEditableStatus = [STATUS.ACCEPTED, STATUS.RESCHEDULED].includes(trainingRequest.status);
        setisInputEditable(isStatusChange && isEditableStatus);

        const currentStatusIndex = allStatus.findIndex(st => st.value === req.status);
        let updatedStatusList = [];

        switch (req.status) {
            case STATUS.PLACED:
                updatedStatusList = [
                    ...allStatus.slice(currentStatusIndex, currentStatusIndex + 2),
                    { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
                ];
                break;
            case STATUS.RESCHEDULED:
                updatedStatusList = [
                    { value: STATUS.RESCHEDULED, label: STATUS.RESCHEDULED },
                    { value: STATUS.OUT_FOR_SERVICE, label: STATUS.OUT_FOR_SERVICE },
                    { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
                ];
                break;
            case STATUS.COMPLETED:
                updatedStatusList = allStatus.slice(currentStatusIndex, currentStatusIndex + 2);
                break;
            case STATUS.ACCEPTED:
            case STATUS.INPROGRESS:
            case STATUS.OUT_FOR_SERVICE:
                updatedStatusList = [
                    ...allStatus.slice(currentStatusIndex, currentStatusIndex + 2),
                    { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
                    { value: STATUS.RESCHEDULED, label: STATUS.RESCHEDULED },
                ];
                break;
            default:
                updatedStatusList = [];
                break;
        }

        setstatusList(updatedStatusList);
    }, [trainingRequest.status, isStatusChange])

    return (
        <MainContentWrapper>
            <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
                {trainingRequest?.id
                    && (
                        <div style={{ display: "flex", flexDirection: "column", gap: "5px", width: "1180px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", padding: "15px 0px" }}>
                                <div style={{ fontSize: "24px", fontWeight: 700 }}>My Requests</div>
                                <div style={{ display: "flex", gap: "1.5rem", marginTop: "8px" }}>
                                    <Link className='underlined-bg-active' style={{ display: "block", color: GLOBAL_STYLES.BG_ACTIVE, fontSize: '16px', fontWeight: 600, position: "relative" }} to={ROUTES.MYREQUESTS}>My requests</Link>
                                    <Link className='underlined-bg-active' style={{ display: "block", color: GLOBAL_STYLES.BG_ACTIVE, fontSize: '16px', fontWeight: 600, position: "relative" }} to={'/'}>Home</Link>
                                </div>
                            </div>

                            <div
                                style={{
                                    // padding: "20px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                    height: "970px",
                                    background: "rgba(244, 244, 244, 1)",
                                    width: "100%",
                                    borderRadius: "10px",
                                    // overflow: "hidden"
                                }}
                            >
                                <div style={{ display: "flex", justifyContent: "space-between", background: "rgba(217, 217, 217, 1)", padding: "25px 40px", borderRadius: "15px 15px 0px 0px " }}>
                                    {/* id */}
                                    <div style={{ color: "#1E1E1E", fontSize: "24px", fontWeight: 600 }}>
                                        <span style={{ marginRight: "5px", }}>Request Number</span>
                                        <span>:</span>

                                        <span style={{ marginLeft: "22px" }}>{"    " + trainingRequest?.req_number}</span>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "right", gap: "15px" }}>
                                        <div
                                            onClick={() => { alert('Get invoice click !') }}
                                            style={{ cursor: "pointer", display: "flex", fontWeight: "500", justifyContent: "center", alignItems: "center", color: "#0F5EFF", borderRadius: "4px", width: "140px", height: "32px", fontSize: "16px", border: "1px solid #0F5EFF" }}>
                                            Get Invoice
                                        </div>

                                        <Button onClick={saveRequestDetails} text={"Update"} style={{ width: "140px", height: "32px", fontSize: "16px" }}
                                            isDisabled={
                                                noValueChanged ||
                                                req.status === trainingRequest.status
                                            } />

                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", gap: "1.8rem", height: "100%", fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, padding: "40px 70px", width: "100%", background: "#FCFCFC", borderRadius: "0px 0px 15px 15px" }}>
                                    {/* customer details */}
                                    <div style={{ display: "flex", flexDirection: "column", rowGap: "30px", width: "100%" }}>
                                        <div style={{ fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR, fontWeight: "600", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <span style={{ fontSize: "22px", letterSpacing: "0.3px", fontWeight: 600 }}>Customer Details</span>
                                            <div style={{ fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, display: "flex", alignItems: "center" }}>
                                                <span style={{ fontSize: "18px", color: "#3B3B3B", fontWeight: 600 }}>Status</span>
                                                <span style={{ marginRight: "10px", marginLeft: "5px" }}>:</span>
                                                <SelectComponent
                                                    controlStyle={{ width: "160px", height: "40px", fontSize: "15px", fontWeight: "600", borderRadius: "4px", background: "#F3F3F3", border: "1px solid #D9D9D9" }}
                                                    list={statusList}
                                                    value={{ value: trainingRequest.status, label: trainingRequest.status === STATUS.PLACED ? "Received" : trainingRequest.status }}
                                                    onChange={(e) => {
                                                        setisStatusChange(() => oldStatus !== e.value);
                                                        setNoValueChanged(false)
                                                        const status = e.value;
                                                        setTrainingRequest((prev) => ({
                                                            ...prev,
                                                            status: status,
                                                            time: req.time
                                                        }))
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "", gap: "20px 194px", width: "100%" }}>
                                            {/*customer name  */}
                                            <div style={{ display: "flex" }}>
                                                <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Customer Name</div>
                                                <span style={{ marginRight: "10px" }}>:</span>
                                                <div style={{ width: "212px", marginLeft: "5px", fontSize: "16px", fontWeight: 400 }}>{trainingRequest.user?.name || "-"}</div>
                                            </div>
                                            {/*contact number */}
                                            <div style={{ display: "flex", justifyContent: "" }}>
                                                <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Contact number</div>
                                                <span style={{ marginRight: "10px" }}>:</span>
                                                <div style={{ width: "212px", marginLeft: "5px", fontSize: "16px", fontWeight: 400 }}>{trainingRequest.user?.mobile_number || "-"}</div>
                                            </div>
                                            {/*Location */}
                                            <div style={{ display: "flex" }}>
                                                <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Location</div>
                                                <span style={{ marginRight: "10px" }}>:</span>
                                                <div style={{ width: "212px", marginLeft: "5px", fontSize: "16px", fontWeight: 400 }}>{trainingRequest?.location_address || "-"}</div>
                                            </div>
                                            {/*Email id */}
                                            <div style={{ display: "flex", justifyContent: "" }}>
                                                <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Email ID</div>
                                                <span style={{ marginRight: "10px" }}>:</span>
                                                <div style={{ width: "200px", wordWrap: 'break-word', fontSize: "16px", fontWeight: 400 }}>{trainingRequest.user?.email || "-"}</div>
                                            </div>
                                        </div>
                                    </div >
                                    {/* Service Details */}
                                    < div style={{ display: "flex", flexDirection: "column", rowGap: "30px", width: "100%" }}>
                                        <div style={{ fontSize: "22px", fontWeight: "600" }}>Service Details</div>
                                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px 94px" }}>
                                            {/*service name  */}
                                            <div style={{ display: "flex" }}>
                                                <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Service</div>
                                                <span style={{ marginRight: "10px" }}>:</span>
                                                <div style={{ width: "212px", marginLeft: "5px", fontSize: "16px", fontWeight: 400 }}>{trainingRequest?.type || "-"}</div>
                                            </div>
                                            {/*Location */}
                                            <div style={{ display: "flex", width: "100%" }}>
                                                <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Service location</div>
                                                <span style={{ marginRight: "10px" }}>:</span>
                                                <div style={{ overflowWrap: "break-word", wordWrap: "break-word", width: "350px", lineHeight: 1.3, marginLeft: "5px", fontSize: "16px", fontWeight: 400, overflowY: "auto", maxHeight: "70px", overflow: "auto" }}>
                                                    {trainingRequest?.field_address || "-"}
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                                                <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "150px", fontSize: "16px", fontWeight: 400 }}>Price</div>
                                                <span style={{ marginRight: "10px" }}>:</span>
                                                <div style={{ overflowWrap: "break-word", wordWrap: "break-word", width: "350px", lineHeight: 1.3, marginLeft: "5px", fontSize: "16px", fontWeight: 400, overflowY: "auto", maxHeight: "70px", overflow: "auto" }}>
                                                    {trainingRequest?.price ? trainingRequest.price + " Rs." : "-"}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", width: "40%", gap: "20px", marginTop: "10px" }}>
                                        <span style={{ fontSize: "22px", fontWeight: "600" }}>
                                            Schedule
                                        </span>
                                        <div style={{ display: "flex", flexDirection: "column", gap: "0.8rem", fontSize: "15px", fontWeight: 400 }}>
                                            <div style={{ display: "flex", alignItems: "center", height: "40px" }}>
                                                <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", fontSize: "16px", fontWeight: 400, minWidth: "85px" }}>Date</div>
                                                <span style={{ marginRight: "10px", marginLeft: "5px" }}>:</span>
                                                {!isInputEditable ? (dateFormat(trainingRequest.date_created, "dd/mm/yyyy") || "-")
                                                    : <div
                                                        className="input-date"
                                                        style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%", width: "180px", position: "relative" }}
                                                        onClick={() => setShowDatePicker(true)}
                                                    >
                                                        <DatePicker
                                                            onClickOutside={() => setShowDatePicker(false)}
                                                            open={showDatePicker}
                                                            className="input-date-hidden"
                                                            minDate={new Date()}
                                                            selected={new Date(trainingRequest.date_created)}
                                                            onChange={date => {
                                                                setNoValueChanged(false)
                                                                setTrainingRequest(prev => ({ ...prev, date_created: combineDateTime((new Date(trainingRequest.date_created)), new Date(date)) }))
                                                            }} />
                                                        <img src={Calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                                                    </div>}
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center", height: "40px" }}>
                                                <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", fontSize: "16px", fontWeight: 400, minWidth: "85px" }}>Time</div>
                                                <span style={{ marginRight: "10px", marginLeft: "5px" }}>:</span>
                                                {!isInputEditable ? dateFormat(trainingRequest.date_created, "hh:MM TT") || "-"
                                                    : <div
                                                        className="input-date"
                                                        style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%", width: "180px", position: "relative" }}
                                                        onClick={() => setShowTimePicker(true)}
                                                    >
                                                        <DatePicker
                                                            onClickOutside={() => setShowTimePicker(false)}
                                                            open={showTimePicker}
                                                            className="input-date-hidden"
                                                            minTime={(trainingRequest.date_created && (new Date(trainingRequest.date_created)).getDate() === (new Date()).getDate())
                                                                ? new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), (new Date()).getHours(), (new Date()).getMinutes())
                                                                : new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0)
                                                            }
                                                            maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={15}
                                                            timeCaption="Time"
                                                            dateFormat="h:mm aa"
                                                            selected={new Date(trainingRequest.date_created)}
                                                            onChange={date => {
                                                                setNoValueChanged(false)
                                                                setTrainingRequest(prev => ({ ...prev, date_created: combineDateTime((new Date(date)), new Date(trainingRequest.date_created)) }))
                                                            }} />
                                                        <img src={Clock} style={{ width: "18px", position: "absolute", right: "15px" }} />
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ width: "30%" }}>
                                        <RequestDiscount request={req} changeValue={(value) => {
                                            // let dspDiscount = req.invoice.applied_discounts.find(ad => ad.discount_type === DISCOUNT_TYPES.DSP_REQUEST_DISCOUNT)
                                            // if (dspDiscount) { setNoValueChanged(dspDiscount.discount == value) }
                                            // else setNoValueChanged(value == 0)
                                        }} />
                                   </div>                                  
                                </div>
                            </div>
                        </div>
                    )}
            </div >
        </MainContentWrapper>
    );
};

export default ViewTrainingRequest;
