import React from 'react'
import { GLOBAL_STYLES } from '../../../../appConstants'
import Pattern from "../../../../assets/pattern.svg"
import cardcover from '../../../../assets/cardCover.png'
const RequestProgressCard = ({ img, status, count }) => {
    return (
        <div style={{ position: "relative", padding: "12px", boxShadow: '0px 0px 20px 1px rgba(117, 117, 117, 0.15)', width: "180px", height: "100%", borderRadius: "8px", display: "flex", flexDirection: "column", justifyContent: "space-between", overflow: 'hidden' }}>
            <img src={cardcover} style={{ position: "absolute", left: 0, top: 0, objectFit: 'cover', width: "100%", height: "100%", scale: '1.2', zIndex: 1 }} />
            <div style={{ position: 'absolute', width: "100%", height: "100%", zIndex: 2, left: 0, top: 0, padding: "12px", display: "flex", flexDirection: "column", justifyContent: "space-between", overflow: 'hidden' }}>
                <div>
                    <img className='request-progress-card-icon' src={img} />
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "end", marginTop: "" }}>
                    <div className='request-progress-card-data' >{count}</div>

                    <div style={{ fontSize: '14px', color: "#3B3B3B", fontWeight: 500 }}>{status}</div>
                </div>
            </div>

        </div>
    )
}

export default RequestProgressCard