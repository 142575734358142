import React from 'react'

const SingleFilter = ({ isChecked, onChange, value, title }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <input checked={isChecked} onChange={onChange} value={value} type={"checkbox"} className="input-checkbox" />
            <span style={{fontSize:"15px",fontWeight:500}}>{title}</span>
        </div>
    )
}

export default SingleFilter