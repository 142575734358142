import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getOrdersGraphAPI } from '../../../apis/dashboard';
import Popup from '../../../ReusableFunctions/Popup';
import { GLOBAL_STYLES } from '../../../appConstants';
import LoaderComponent from '../../../ReusableComponents/LoaderComponent';
import moment from 'moment';
import { addCommasToAmount, getFormatedDate, getFormattedTimeFromDate, getWeekNumber, weeksInYear } from '../../../ReusableFunctions/reusableFunctions';
import { getRevenue } from '../../../apis/revenue';
import SelectComponent from '../../../ReusableComponents/SelectComponent';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const RevenueGraph = ({ inter, onChange, rev, showFilter, dropdownValue, load }) => {
    const yearlabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"]
    const weekLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    const [monthLabels, setMonthLabels] = useState([]);
    const [dropDown, setDropDown] = useState([])
    const [screenWidth, setScreenWidth] = useState(1366)
    const [selectedDropDown, setSelectedDroDown] = useState({ value: (inter === "week" ? getWeekNumber(new Date())[1] : inter === "month" ? new Date(new Date().getFullYear(), new Date().getMonth(), 1) : ""), label: (inter === "week" ? `Week ${getWeekNumber(new Date())[1]}` : inter === "month" ? yearlabels[new Date().getMonth()] : "") })

    useEffect(() => {
        if (inter) {
            if (inter === "week")
                setDropDown([...Array(weeksInYear(new Date().getFullYear()))].map((el, i) => ({ label: `Week ${i + 1}`, value: i + 1 })))
            else if (inter === "month") {
                setDropDown(yearlabels.map((el, i) => ({ label: `${el}`, value: new Date(new Date().getFullYear(), i, 1) })))
            }
        }
    }, [inter])

    useEffect(() => {
        if (dropdownValue) {
            setSelectedDroDown({ value: dropdownValue, label: `${inter === 'week' ? `Week ${dropdownValue}` : inter === "month" ? `${yearlabels[dropdownValue]}` : ""}` })
        }
    }, [dropdownValue])

    useEffect(() => {
        getRevenueGraphFunc(rev)
    }, [rev])

    useEffect(() => {
        const screenWidth = window.innerWidth; setScreenWidth(screenWidth)
        setMonthLabels(getDaysInMonth(new Date().getMonth()))
    }, [])

    function getDaysInMonth(month) {
        // Month is zero-based, so decrement it by 1
        const currYear = new Date().getFullYear()
        const firstDayOfNextMonth = new Date(currYear, month + 1, 1);
        var lastDay = new Date(firstDayOfNextMonth - 1).getDate();
        var daysArray = [];

        for (var day = 1; day <= lastDay; day++) {
            daysArray.push(day);
        }

        return daysArray;
    }

    const [options, setOptions] = useState({
        maintainAspectRatio: false,
        aspectRatio: 1,
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top',
                align: "end",
                labels: {
                    boxWidth: 12,
                },

            },
            title: {
                display: false,
                text: `This ${inter}`,
                position: "top", // Align the title text to the top
                align: "start", // Align the title text to the left within the top position
                padding: {
                    top: 20,
                    bottom: 0
                }
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                ticks: {
                    stepSize: 1000,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
    })

    const labels = inter === "week" ? weekLabels : inter === "month" ? monthLabels : inter === "year" ? yearlabels : []

    const [data, setData] = useState({
        labels,
        datasets: [
            {
                label: 'Revenue',
                data: [1, 1, 1, 1, 1, 1, 1],
                borderColor: '#FFB001',
                backgroundColor: '#FFB001',
                pointRadius: [0, 0, 0, 5, 0, 0, 0],
                pointBackgroundColor: rev?.revenues?.map(r => r === 0 ? "rgba(15, 94, 255, 0)" : "#FFB001"),
                pointBorderColor: rev?.revenues?.map(r => r === 0 ? "rgba(15, 94, 255, 0)" : "#FFB001"),
                pointBorderWidth: 2,
                base: 0
            },

        ],
    })

    const getRevenueGraphFunc = async (graphData) => {
        try {
            const labels = inter === "week" ? weekLabels : inter === "month" ? monthLabels : inter === "year" ? yearlabels : []
            setOptions({

                aspectRatio: 1,
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                    tooltip: {
                        padding: 10,
                        displayColors: false,
                        bodyFont: {
                            size: 17
                        },
                        titleFont: {
                            size: 10,
                            weight: 400
                        },
                        backgroundColor: "#ffffff",
                        bodyColor: "#000",
                        borderColor: "#d9d9d95e",
                        borderWidth: 2,
                        titleColor: "#848484",
                        callbacks: {
                            label: function (context) {
                                let label = context.dataset.label || '';
                                if (label) {
                                    label = '';
                                }
                                if (context.parsed.y !== null) {
                                    label += (addCommasToAmount(context.parsed.y)) + " Rs";
                                }
                                return label;
                            },
                            title: function (context) {
                                let label = context.label || '';
                                const date = graphData.dates[Number(context[0].dataIndex)]
                                if (date !== 0) {
                                    label = "" + (getFormatedDate(date, true) + " " + getFormattedTimeFromDate(date))
                                }
                                return label;
                            },
                        },
                    },
                    legend: {
                        display: false,
                        position: 'top',
                        align: "end",
                        labels: {
                            boxWidth: 12,
                        },

                    },
                    title: {
                        display: false,
                        text: `This ${inter}`,
                        position: "top", // Align the title text to the top
                        align: "start", // Align the title text to the left within the top position
                        padding: {
                            top: 20,
                            bottom: 0
                        }
                    },
                },
                scales: {
                    y: {
                        beginAtZero: true,
                        grid: {
                            display: false,
                        },
                        ticks: {
                            stepSize: Math.ceil(Math.max.apply(null, graphData.revenues) / 10),
                            color: "#b3b1b1",
                            font: {
                                size: 10,
                            }
                        },
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                },

            })
            setData({
                labels,
                datasets: [
                    {
                        label: 'Revenue',
                        data: graphData.revenues,
                        borderColor: '#FFB001',
                        backgroundColor: '#FFB001',
                        pointRadius: 5,
                        pointBackgroundColor: graphData.revenues.map(r => r === 0 ? "rgba(15, 94, 255, 0)" : "white"),
                        pointBorderColor: graphData.revenues.map(r => r === 0 ? "rgba(15, 94, 255, 0)" : "#FFB001"),
                        pointBorderWidth: 2,
                        base: 0
                    },

                ],
            })
        } catch (e) {
            Popup.alert("Error", e, "ERROR");
        }
    }
    return <div className='revenue-graph-wraper'>
        <>
            <div style={{ position: "absolute", display: "flex", justifyContent: "space-between", width: "95%", top: screenWidth == 1920 ? "22px" : "10px", left: screenWidth == 1920 ? "24px" : "10px", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>
                <div style={{ textTransform: "capitalize", color: '#3b3b3b', fontSize: screenWidth == 1920 ? "1rem" : '13px', fontWeight: 500 }}>
                    {inter}ly
                </div>
                <SelectComponent
                    controlStyle={{ height: "20px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "11px" }}
                    valueControlStyle={{ padding: "2px 8px 4px 8px", margin: "0" }}
                    dropdownIndicatorStyle={{ padding: "0", margin: "0" }}
                    indicatorContainerStyle={{ width: "16px" }}
                    optionStyle={{ fontSize: '10px', display: "flex", alignItems: "center", padding: "0px 10px", height: "20px", minHeight: "20px" }}
                    singleValueStyle={{ color: "hsl(0deg 0% 20% / 64%)" }}
                    value={selectedDropDown?.value ? selectedDropDown : ""}
                    onChange={(selected) => {
                        setSelectedDroDown(selected)
                        setMonthLabels(getDaysInMonth(new Date(selected.value).getMonth()))
                        onChange(selected)
                    }}
                    list={dropDown.map(el => ({ value: el.value, label: el.label }))}
                />
            </div>
            {load ? <LoaderComponent /> :
                <div style={{ height: "100%", width: "100%", paddingTop: screenWidth == 1920 ? "26px" : "0px" }}>
                    <Line options={options} data={data} />
                </div>
            }
        </>
    </div>
}

export default RevenueGraph