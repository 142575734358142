import React from 'react'
import { GLOBAL_STYLES } from '../appConstants'

const InputError = ({ inputError }) => {
    return (
        <>
            <div
                style={{
                    color: 'red',
                    fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL,
                    animation: 'fadeIn 0.5s ease-in-out',
                    lineHeight: 1.1,
                    minHeight: "12px"
                }}
            >
                {inputError.error.message}
            </div>

            <style>
                {`
                    @keyframes fadeIn {
                        from {
                            opacity: 0;
                        }
                        to {
                            opacity: 1;
                        }
                    }
                `}
            </style>
        </>
    )
}

export default InputError
