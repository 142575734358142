import React, { useEffect, useState } from "react";
import Input from "../../../../../ReusableComponents/Input";
import { Button } from "../../../../../Components/Helper";
import { getLocations } from "../../../../../apis/locations";
import Training from "../../../../../assets/training.svg";
import {
  addService,
  updateService,
} from "../../../../../apis/services";
import Modal from "../../../../../ReusableComponents/Modal";
import Cross from "../../../../../assets/CrossDark.svg";
import CloseIcon from "../../../../../assets/CrossIcon.svg";
import { GLOBAL_STYLES, LIMITS } from "../../../../../appConstants";
import Toggle from "react-toggle";
import { getFormatedDate, isEmptyString, isSpecialCharExists, isValidGst, reduceLongName } from "../../../../../ReusableFunctions/reusableFunctions";
import Plus from "../../../../../assets/Plus.svg";
import Popup from "../../../../../ReusableFunctions/Popup";
import Loading from "../../../../../ReusableFunctions/Loader";
import AddLocation from "../../../../MyLocations/SubComponents/AddLocation";
import DatePicker from "react-datepicker"
import Calender from "../../../../../assets/Calender2.svg"
import { ROUTES } from "../../../../../routes";
import { useNavigate } from "react-router-dom"
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import MainContentWrapper from "../../../../../Components/MainContentWrapper";
import NotFounds from "../../../../../ReusableComponents/NotFounds";
import noCourses from '../../../../../assets/no-course.svg'
import LoaderComponent from "../../../../../ReusableComponents/LoaderComponent";

const ReadOnlyInput = React.forwardRef(({ value, onClick }, ref) => (
  <input
    value={value}
    onClick={onClick}
    ref={ref}
    readOnly // Make the input read-only
  />
));
const AddTrainingService = ({ aeroMeghService, service }) => {
  const navigate = useNavigate()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [openAddCoursePopup, setOpenAddCoursePopup] = useState(false);
  const [openUpdateCoursePopup, setOpenUpdateCoursePopup] = useState(false);
  const isServiceToBeUpdated = service.aeromegh_service_id; //if service has aeromegh_service_id this means it is to be updated else new service
  const dateFormatOption = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const [openAddLocationPopup, setOpenAddLocationPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [training_service, setTrainingService] = useState(
    isServiceToBeUpdated
      ? service
      : {
        name: aeroMeghService.name,
        description: aeroMeghService.description,
        is_active: true,
        start_date: new Date(),
        aeromegh_service_id: aeroMeghService.id,
        types: [],
        locations: [],
        parameters: {
          sgst: undefined,
          cgst: undefined,
        },
      }
  );
  const [course, setCourse] = useState({
    name: "",
    cost: "",
    duration: undefined,
    description: "",
    is_active: false,
  });
  const [error, setError] = useState({
    name: '',
    duration: '',
    cost: '',
  });
  const [locations, setLocations] = useState([]);

  const handleAddService = () => {
    Loading.set(true);
    addService(training_service)
      .then((res) => {
        Loading.set(false);
        Popup.alert(
          "Training Service",
          "Service added successfully",
          "SUCCESS",
          () => {
            navigate(ROUTES.MYSERVICES)
          }
        );
      })
      .catch((err) => {
        console.log(err);
        Loading.set(false);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };
  const handleUpdateService = () => {
    Loading.set(true);
    updateService(training_service)
      .then((res) => {
        Loading.set(false);
        Popup.alert(
          "Training Service",
          "Service updated successfully",
          "SUCCESS",
          () => {
            navigate(ROUTES.MYSERVICES)
          }
        );
      })
      .catch((err) => {
        Loading.set(false);
        console.log(err);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };
  const handleOnChangeLocation = (e, location_id) => {
    if (e.target.checked) {
      //location is being added
      const addedLocation = locations.find((loc) => loc.id === location_id);
      setTrainingService((prev) => ({
        ...prev,
        locations: [...prev.locations, addedLocation],
      }));
    } else {
      setTrainingService((prev) => ({
        ...prev,
        locations: prev.locations.filter((loc) => loc.id !== location_id),
      }));
    }
  };

  const checkIsValid = (name, value) => {
    setError((prevErrors) => ({ ...prevErrors, [name]: '' }));
    switch (name) {
      case 'name':
        if (isEmptyString(value)) {
          setError((prevErrors) => ({
            ...prevErrors,
            name: 'Please enter valid name.',
          }));
          return false;
        }
        else if (isSpecialCharExists(value)) {
          setError((prevErrors) => ({
            ...prevErrors,
            name: 'Special characters are not allowed.',
          }));
        }
        break;
      case 'duration':
        if (Number(value) === null || Number(value) === 0 || Number(value) === undefined) {
          setError((prevErrors) => ({
            ...prevErrors,
            duration: 'Please enter valid course duration.',
          }));
          return false;
        }
        else if (Number(value) > 365) {
          setError((prevErrors) => ({
            ...prevErrors,
            duration: 'Duration should not be greter than a year',
          }));
        }
        else if (isSpecialCharExists(value)) {
          setError((prevErrors) => ({
            ...prevErrors,
            duration: 'Special characters are not allowed.',
          }));
        }
        break;
      case 'cost':
        if (value.length <= 0 || value === "e") {
          setError((prevErrors) => ({
            ...prevErrors,
            cost: "Please enter valid cost.",
          }));
          return false;
        }
        else if (Number(value) > LIMITS.COURSE_COST) {
          setError((prevErrors) => ({
            ...prevErrors,
            cost: `Cost should not be greater than ${LIMITS.COURSE_COST}`,
          }));
          return false;
        }
        else if (isSpecialCharExists(value)) {
          setError((prevErrors) => ({
            ...prevErrors,
            cost: 'Special characters are not allowed.',
          }));
        }
        break;
      default:
        setError((prevErrors) => ({ ...prevErrors, [name]: '' }));
        break;
    }
    return true;
  }

  const isEveryFieldFilled = () => {
    const excludedFields = ["aeromegh_service_id", "date_created", "description", "dsp_id", "id", "is_active"];
    for (const key in training_service) {
      if (!excludedFields.includes(key)) {
        const value = training_service[key];
        if (typeof (value) === "string") {
          if (value === undefined || value === null || value === '' || isEmptyString(training_service.name)) {
            return false;
          }
        }
        if (key === "locations") {
          if (locations.length == 0) {
            return false;
          }
        }

        if (key === 'parameters') {
          for (const key in training_service.parameters) {
            if (key === 'sgst' || key === 'cgst') {
              if (training_service.parameters[key] === '' || training_service.parameters[key] === undefined || training_service.parameters[key] < 0) {
                return false;
              }
            }

          }
        }
        if (key == "types") {
          let selectedCourse = training_service[key].filter(c => c.is_active === true);
          if (selectedCourse?.length == 0) return false;
        }
      }
    }
    return true;
  }

  const handleOnChangeCourse = (e, course_id) => {
    const courses = training_service.types.map((c) => {
      if (c.id === course_id) {
        return { ...c, is_active: e.target.checked ? true : false };
      } else return c;
    });
    setTrainingService((prev) => ({ ...prev, types: courses }));
  };
  const handleOnChangeAddCourse = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    checkIsValid(name, value)
    setCourse((prev) => ({ ...prev, [name]: value }));
  };
  const isCourseFormFilled = () => {
    return !(
      course.name.length === 0 ||
      isEmptyString(course.name) ||
      course.cost.length <= 0 ||
      course.cost === "e" ||
      course.cost.length <= 0 ||
      course.cost === "e" ||
      !course.duration
    );
  };
  const onAddLocation = (location) => {
    setLocations([location, ...locations]);
  }
  useEffect(() => {
    Loading.set(true);
    setLoading(true);
    getLocations()
      .then((locations) => {
        setLocations(locations);
        setLoading(false);
        Loading.set(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        Loading.set(false);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  }, []);
  useEffect(() => {
    if (service.aeromegh_service_id) setTrainingService(service);
  }, [service]);
  return (
    <>
      {/* Add location popup */}
      {openAddLocationPopup && (
        <AddLocation
          open={openAddLocationPopup}
          onClose={() => setOpenAddLocationPopup(false)}
          onAddLocation={onAddLocation}
        />
      )}
      {/* add course popup */}
      {(openAddCoursePopup || openUpdateCoursePopup) && (
        <Modal
          divider
          header={course.dsp_id ? "Course" : "Add Course"}
          headingStyle={{ fontSize: "18px" }}
          contentStyle={{ height: "auto", width: "700px", boxShadow: '0px 1px 4px 3px #76767626', border: "1px solid #1E1E1E" }}
          open={openAddCoursePopup || openUpdateCoursePopup}
          onClose={() => {
            setOpenAddCoursePopup(false);
            setOpenUpdateCoursePopup(false);
            setCourse({
              name: "",
              cost: "",
              duration: undefined,
              description: "",
              is_active: false,
            });
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (openUpdateCoursePopup) {
                if (isCourseFormFilled()) {
                  const newCourses = training_service.types.map((c) => {
                    if (c.id === course.id) return course;
                    else return c;
                  });
                  setTrainingService((prev) => ({
                    ...prev,
                    types: newCourses,
                  }));
                } else {
                  setTrainingService((prev) => ({
                    ...prev,
                    types: [...prev.types, course],
                  }));
                }
                setOpenAddCoursePopup(false);
                setOpenUpdateCoursePopup(false);
                setCourse({
                  name: "",
                  cost: "",
                  duration: undefined,
                  description: "",
                  is_active: false,
                });
              }
            }}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              padding: "",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "76%",
                padding: "20px 30px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM,
              }}
            >
              <div>
                <label style={{
                  display: "block",
                  fontSize: "15px",
                  fontWeight: 400,
                  color: "#3b3b3b",
                  marginBottom: "5px"
                }}>
                  Course name
                </label>
                <Input
                  style={{ background: "rgba(243, 243, 243, 1)", border: "1px solid #BFC8D9", borderRadius: "7px" }}
                  inputStyle={{
                    background: "rgba(243, 243, 243, 1)",
                    color: "#1E1E1E",
                    fontSize: "14px !important",
                  }}
                  maxLength={LIMITS.NAME}
                  value={course.name}
                  name={"name"}
                  placeholder={"Enter course name"}
                  onChange={handleOnChangeAddCourse}
                />
                <div style={{ height: "15px" }}>
                  {error.name && <div style={{ color: "red", padding: "5px 0px 0px 0px", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>{error.name} </div>}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "20px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <label style={{
                    display: "block",
                    fontSize: "15px",
                    fontWeight: 400,
                    color: "#3b3b3b",
                    marginBottom: "5px"
                  }}>
                    Duration
                  </label>
                  <Input
                    style={{ background: "rgba(243, 243, 243, 1)", border: "1px solid #BFC8D9", borderRadius: "7px" }}
                    inputStyle={{
                      background: "rgba(243, 243, 243, 1)",
                      color: "#1E1E1E",
                      fontSize: "14px !important",
                    }}
                    maxLength={3}
                    value={course.duration}
                    name={"duration"}
                    type={"number"}
                    onChange={handleOnChangeAddCourse}
                    placeholder={"Enter duration in days"}
                  />
                  <div style={{ height: "15px" }}>
                    {error.duration && <div style={{ color: "red", padding: "5px 0px 0px 0px", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>{error.duration} </div>}
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <label style={{
                    display: "block",
                    fontSize: "15px",
                    fontWeight: 400,
                    color: "#3b3b3b",
                    marginBottom: "5px"
                  }}>
                    Cost
                  </label>
                  <Input
                    style={{ background: "rgba(243, 243, 243, 1)", border: "1px solid #BFC8D9", borderRadius: "7px" }}
                    inputStyle={{
                      background: "rgba(243, 243, 243, 1)",
                      color: "#1E1E1E",
                      fontSize: "14px !important",
                    }}
                    maxLength={7}
                    value={course.cost}
                    name={"cost"}
                    type={"number"}
                    onChange={handleOnChangeAddCourse}
                    placeholder={"Enter cost in Rs"}
                  />
                  <div style={{ height: "15px" }}>
                    {error.cost && <div style={{ color: "red", padding: "5px 0px 0px 0px", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>{error.cost} </div>}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "140px",
                }}
              >
                <label style={{
                  display: "block",
                  fontSize: "15px",
                  fontWeight: 400,
                  color: "#3b3b3b",
                  marginBottom: "5px"
                }}>
                  Course details
                </label>
                <textarea
                  value={course.description}
                  name="description"
                  onChange={handleOnChangeAddCourse}
                  placeholder="Enter course details here..."
                  style={{
                    resize: "none",
                    width: "100%",
                    height: "100%",
                    border: `1px solid #BFC8D9`,
                    background: "rgba(243, 243, 243, 1)",
                    borderRadius: "5px",
                    padding: "10px",
                    fontSize: "14px",
                    outline: "none",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                gap: "10px",
                padding: "20px 30px",
              }}
            >
              <Button
                type={"button"}
                style={{
                  width: "100px",
                  border: `1px solid ${openUpdateCoursePopup
                    ? GLOBAL_STYLES.ERROR
                    : GLOBAL_STYLES.BG_ACTIVE
                    }`,
                  color: openUpdateCoursePopup
                    ? GLOBAL_STYLES.ERROR
                    : GLOBAL_STYLES.BG_ACTIVE,
                }}
                text={openUpdateCoursePopup ? "Delete" : "Cancel"}
                onClick={() => {
                  setOpenAddCoursePopup(false);
                  setOpenUpdateCoursePopup(false);
                  if (openUpdateCoursePopup) {
                    //if service is to be updated
                    setTrainingService((prev) => ({
                      ...prev,
                      types: prev.types.filter((c) => c.id !== course.id),
                    }));
                  }
                  setCourse({
                    name: "",
                    cost: "",
                    duration: undefined,
                    description: "",
                    is_active: false,
                  });
                }}
                isBtnNotActive
              />
              <Button
                style={{ width: "100px" }}
                text={openUpdateCoursePopup ? "Save" : "Add"}
                isDisabled={!isCourseFormFilled() || error.name || error.cost || error.duration}
                onClick={() => {
                  if (openUpdateCoursePopup) {
                    const newCourses = training_service.types.map((c) => {
                      if (c.id === course.id) return course;
                      else return c;
                    });
                    setTrainingService((prev) => ({
                      ...prev,
                      types: newCourses,
                    }));
                  } else {
                    setTrainingService((prev) => ({
                      ...prev,
                      types: [...prev.types, course],
                    }));
                  }
                  setOpenAddCoursePopup(false);
                  setOpenUpdateCoursePopup(false);
                  setCourse({
                    name: "",
                    cost: "",
                    duration: undefined,
                    description: "",
                    is_active: false,
                  });
                }}
              />
            </div>
          </form>
        </Modal>
      )}

      <MainContentWrapper containerStyle={{overflow:"hidden"}} header={"My services"}>

        {/* <div style={{ fontSize: "23px", marginBottom: "10px", color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Training Service</div> */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (isEveryFieldFilled()) {
              if (isServiceToBeUpdated) handleUpdateService();
              else handleAddService();
            }
          }}
          style={{
            width: "92%",
            height: "95%",
            display: "flex",
            margin: "auto auto",
            flexDirection: "column",
            border: `1px solid rgb(217 217 217 / 33%)`,
            background: "#FCFCFC",
            boxShadow: " 0px 0px 4px 0px #72727226",
            borderRadius: "4px",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "20px 50px",
              marginBottom: "",
              borderBottom: "1px solid rgba(236, 239, 243, 1)",
              width: "100%",
            }}
          >

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <img src={Training} style={{ width: "55px" }} />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ fontSize: "28px", color: "#1E1E1E", fontWeight: 500 }}>
                  Training
                </span>
                <span style={{ fontSize: "15px", color: "#1E1E1E", fontWeight: 600 }}>
                  <span style={{ fontSize: "12px", color: "rgba(114, 114, 114, 1)", fontWeight: 600 }}>

                  </span>
                </span>
              </div>
            </div>
            <div className="header_toggle flex flex-col items-end gap-[2px]">
              <Toggle
                height={"50px"}
                icons={false}
                checked={training_service?.is_active}
                onChange={(e) => {
                  setTrainingService((prev) => ({
                    ...prev,
                    is_active: e.target?.checked ? true : false,
                  }));
                }}
              />
              <p style={{ fontSize: "14px", color: "#727272", fontWeight: 500, letterSpacing: "0.2px" }}>Service is <span style={{ fontWeight: 600, color: training_service?.is_active ? "#109316" : "#727272" }}>{training_service?.is_active ? "Active" : "Inactive"}</span> </p>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", height: "75%", overflowY: "auto", gap: "10px" }}>
            {/* Ist part */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginTop: "10px",
                gap: "15px",
                padding: "0px 60px"
              }}
            >
              <div
                style={{
                  display: "grid",
                  justifyContent: "space-between",
                  columnGap: "70px",
                  rowGap: "5px",
                  alignItems: "center",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr",
                }}
              >
                {/* service name */}
                <div style={{ width: "100%", minHeight: "100px" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "16px",
                      color: "#3b3b3b",
                      marginBottom: "5px"
                    }}
                  >
                    Name of the service
                  </label>
                  <Input
                    style={{ background: "rgba(243, 243, 243, 1)", height: "45px" }}
                    inputStyle={{
                      background: "rgba(243, 243, 243, 1)",
                      height: "50px"
                    }}
                    maxLength={LIMITS.SERVICE_NAME}
                    type={"text"}
                    value={training_service.name}
                    placeholder={"Service name"}
                    name={"name"}
                    onChange={(e) => {
                      if (isSpecialCharExists(e.target.value)) {
                        return;
                      }
                      setTrainingService((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }));
                    }}
                  />
                  {(training_service.name.length === 0 || isEmptyString(training_service.name)) && (
                    <div style={{ color: GLOBAL_STYLES.ERROR, fontSize: "12px", fontWeight: 500, marginTop: "3px" }}>
                      Please enter service name
                    </div>
                  )}
                </div>


                <div style={{ width: "100%", minHeight: "100px" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "15px",
                      color: "#3b3b3b",
                      marginBottom: "5px"
                    }}
                  >
                    Start Date
                  </label>
                  <Input
                    style={{ background: "rgba(243, 243, 243, 1)", height: "45px" }}
                    inputStyle={{
                      background: "rgba(243, 243, 243, 1)"
                    }}
                    type={"text"}
                    value={new Date(training_service.start_date).toLocaleDateString('en-GB', dateFormatOption)}
                    placeholder={"start_date"}
                    name={"start_date"}
                    isDisabled={true}
                  />
                </div>
                {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}> */}
                <div style={{ width: "100%", minHeight: "100px" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "15px",
                      color: "#3b3b3b",
                      marginBottom: "5px"
                    }}
                  >
                    SGST (%)
                  </label>
                  <Input
                    style={{ background: "rgba(243, 243, 243, 1)", height: "45px" }}
                    inputStyle={{
                      background: "rgba(243, 243, 243, 1)"
                    }}
                    type={"number"}
                    value={training_service?.parameters?.sgst}
                    placeholder={"SGST"}
                    name={"sgst"}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (isValidGst(newValue) || newValue === '') {
                        setTrainingService((prev) => ({
                          ...prev,
                          parameters: { ...prev.parameters, sgst: e.target.value }
                        }))
                      }
                    }}
                  />

                </div>
                <div style={{ width: "100%", minHeight: "100px" }}>
                  <label
                    style={{
                      display: "block",
                      fontSize: "15px",
                      color: "#3b3b3b",
                      marginBottom: "5px"
                    }}
                  >
                    CGST (%)
                  </label>
                  <Input
                    style={{ background: "rgba(243, 243, 243, 1)", height: "45px" }}
                    inputStyle={{
                      background: "rgba(243, 243, 243, 1)"
                    }}
                    type={"number"}
                    value={training_service?.parameters?.cgst}
                    placeholder={"CGST"}
                    name={"cgst"}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      if (isValidGst(newValue) || newValue === '') {
                        setTrainingService((prev) => ({
                          ...prev,
                          parameters: { ...prev.parameters, cgst: e.target.value }
                        }))
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            {/* 2nd part */}
            <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", gap: "50px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "200px",
                  minHeight: "200px",
                  rowGap: "0px",
                  padding: "0px 60px",
                }}

              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <div style={{
                    padding: "0px",
                    fontSize: "16px",
                    marginBottom: "5px",
                    fontWeight: 500,
                    color: "rgba(59, 59, 59, 1)",
                  }}>
                    <span>Locations</span>
                    <span style={{ marginLeft: "5px" }}>{`(${locations.length})`}</span>
                  </div>
                  <Button type={"button"} text={"Add new"} icon={Plus} style={{ marginBottom: "8px", background: "#0F5EFF", padding: "8px, 16px, 8px, 16px", width: "110px", height: "35px", fontSize: "11px" }} onClick={() => setOpenAddLocationPopup(true)} />
                </div>
                <div
                  style={{
                    overflowY: "auto",
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill,155px)",
                    gridTemplateRows: "repeat(auto-fill,28px)",
                    alignItems: "flex-start",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                    rowGap: "23px",
                    height: "90%",
                    minHeight: "90%",
                    border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
                    background: "#FCFCFC",
                    boxShadow: "0px 0px 4px 0px rgba(114, 114, 114, 0.15)",
                    borderRadius: "5px",
                    padding: "20px 5px",
                    fontSize: "12px",
                    fontWeight: 500
                  }}
                  className="locations_container"
                >
                  {locations.map((location, i) => (
                    <Tippy content={location.address}>
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px 10px",
                          gap: "10px",
                          color: "rgba(30, 30, 30, 1)",
                          fontSize: "15px",
                          fontWeight: "500",
                          position: "relative"
                        }}
                      >
                        <input
                          disabled={!location.is_active}
                          className="input-checkbox"
                          type="checkbox"
                          checked={training_service.locations.find(
                            (loc) => loc.id === location.id
                          )}
                          onChange={(e) => handleOnChangeLocation(e, location.id)}
                        />
                        <label
                          style={{ opacity: location.is_active ? 1 : 0.5 }}
                        >
                          {reduceLongName(
                            location.name.split(" ")[0].split(",")[0],
                            8
                          )}
                        </label>
                      </div>
                    </Tippy>
                  ))}
                </div>
                {(locations.length === 0 && !loading) && (
                  <div style={{ color: GLOBAL_STYLES.ERROR, fontSize: "12px", fontWeight: 500, marginTop: "3px" }}>
                    Please select atleast 1 location
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "60%",
                  height: "467px",
                  padding: "0px 60px",
                  gap: "30px",
                }}
                className="courses_wrapper"
              >
                {/* avaialbel courses */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    gap: "",
                  }}
                >
                  <div
                    style={{
                      color: GLOBAL_STYLES.FONT_COLOR_SUB,
                      fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM,
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <div style={{
                      padding: "0px",
                      fontSize: "18px",
                      marginBottom: "5px",
                      fontWeight: 500,
                      color: "#3B3B3B"
                    }}>
                      <span>Available courses</span>
                      <span style={{ marginLeft: "5px" }}>{`(${training_service.types.length})`}</span>
                    </div>
                    <Button
                      style={{ marginBottom: "8px", background: "#0F5EFF", padding: "8px, 16px, 8px, 16px", width: "110px", height: "35px", fontSize: "11px" }}
                      icon={Plus}
                      type={"button"}
                      text={"Add course"}
                      onClick={() => {
                        setOpenAddCoursePopup(true);
                        setOpenUpdateCoursePopup(false);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                      padding: "10px",
                      border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
                      borderRadius: "10px",
                      overflowY: "auto",
                    }}
                  >
                    {training_service.types.length > 0 ? training_service.types.map((course) => (
                      <div
                        key={course.id}
                        style={{
                          display: "flex",
                          width: "100%",
                          gap: "10px",
                          alignItems: "center",
                          padding: "8px 10px",
                          fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM,
                        }}
                      >
                        <input
                          className="input-checkbox"
                          type={"checkbox"}
                          checked={course.is_active}
                          onChange={(e) => handleOnChangeCourse(e, course.id)}
                        />
                        <div style={{ width: "100%", cursor: "pointer", fontSize: "15px", fontWeight: 500, color: "#1E1E1E" }} onClick={() => {
                          setOpenAddCoursePopup(false);
                          setOpenUpdateCoursePopup(true);
                          setCourse(course);
                        }}>{course.name}</div>
                        <img
                          style={{ width: "18px", cursor: "pointer" }}
                          src={Cross}
                          onClick={() =>

                            Popup.alert('Delete Course', 'Are you sure, you want to delete this course?', 'WARNING', () => {
                              setTrainingService((prev) => ({
                                ...prev,
                                types: prev.types.filter((c) => c.id !== course.id),
                              }));
                            })

                          }
                        />
                      </div>
                    )) : <NotFounds imgUrl={noCourses} msg={"You haven't added any course yet. Please click the the above button to add !"} hideBtn imgStyle={{ width: "100px" }} />}
                  </div>
                  {training_service.types.length === 0 && (
                    <div
                      style={{
                        color: GLOBAL_STYLES.ERROR,
                        fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM,
                      }}
                    >

                      Please select atleast one course
                    </div>
                  )}
                </div>
              </div>

            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginTop: "",
              columnGap: "20px",
              marginLeft: "auto",
              paddingRight: "60px"
            }}
          >
            {isServiceToBeUpdated ? (
              <Button
                style={{ width: "157px", height: "38px", fontSize: "16px" }}
                text={"Save"}
                isDisabled={!isEveryFieldFilled()}
              />
            ) : (
              <Button
                style={{ width: "157px", height: "38px", fontSize: "16px" }}
                text={"Save"}
                isDisabled={!isEveryFieldFilled()}
              />
            )}
            <Button
              type={"button"}
              style={{ width: "157px", height: "38px", fontSize: "16px", color: "#606571", border: "1px solid #BFC8D9" }}
              text={"Cancel"}
              isBtnNotActive
              redirectTo={"/manageServices"}
            />
          </div>
        </form>
      </MainContentWrapper>
    </>
  );
};

export default AddTrainingService;
