import React from 'react'
import { ROUTES } from '../routes'
import drone from "../assets/sidebaricons/droneInactive.svg";
import droneActive from "../assets//sidebaricons/droneActive.svg";

const DroneIcon = (location) => {
    return (
        <img alt='' className={location.location === ROUTES.MYDRONE ? "sideBarIcon" : "sideBarIcon-not-selected"} src={location.location === ROUTES.MYDRONE ? droneActive : drone} />
    )
}

export default DroneIcon