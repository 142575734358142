import React, { useState } from 'react'
import { DRONE_NAMES, GLOBAL_STYLES } from '../../../appConstants'
import more from "../../../assets/more.png"
import eVotol from "../../../assets/eVotol.png"
import quadcoptor from '../../../assets/quadcoptor.png'
import hexacopter from '../../../assets/hexacopter.png'
import { reduceLongName } from '../../../ReusableFunctions/reusableFunctions'
import Tippy from '@tippyjs/react'
import { OptionMenu } from '../../../Components/Helper'
import CustomTooltip from '../../../ReusableComponents/CustomTooltip'

const ModelComponent = ({ model, onModelEdit }) => {
    const [activityPopup, setActivityPopup] = useState({
        id: null,
        isOpen: false
    })
    const openActicityPopup = (id) => {
        setActivityPopup((prev) => ({
            id: id,
            isOpen: prev.id !== id || !prev.isOpen, // Close the current popup and open the clicked row
        }));
    }
    return (
        <>
            <div style={{ padding: "10px", borderRadius: "20px", background: "#FCFCFC", height: "285px" }}>
                <div style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center", position: "relative" }}>
                    <Tippy offset={[10, -15]} interactive onShown={(instance) => {
                        document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                            instance.hide();
                        })
                    }} className='tippy' trigger='click' arrow={false} content={
                        <OptionMenu
                            isLeft
                            onClickEdit={() => {
                                onModelEdit(model.id);
                            }}
                        />
                    }>
                        <img src={more} alt="three dot" style={{ width: "22px", color: GLOBAL_STYLES.INACTIVE_GREY_COLOR, cursor: "pointer", position: "absolute", top: "5px", right: "5px" }} onClick={() => { openActicityPopup(model?.id) }} />
                    </Tippy>
                </div>
                <div style={{ display: "flex", gap: "30px", marginLeft: "20px", alignItems: "center", paddingTop: "15px", fontFamily: "Manrope", marginTop: "5px" }}>
                    <div style={{ backgroundColor: "white", borderRadius: "50%", border: "1px solid #BFC8D9", padding: "5px" }}>
                        <img style={{ padding: "15px", width: "85px", height: "85px", borderRadius: model.type === DRONE_NAMES.eVTOLS || model.type === DRONE_NAMES.Hexacopter || model.type === DRONE_NAMES.Quadcopters ? "" : "50%" }} src={model.type === DRONE_NAMES.eVTOLS ? eVotol : model.type === DRONE_NAMES.Hexacopter ? hexacopter : model.type === DRONE_NAMES.Quadcopters ? quadcoptor : `https://ui-avatars.com/api/?background=727272&color=fff&name=${model?.type?.split(" ").join("+")}`} alt="Profile pic" />
                    </div>
                    <div>
                        <Tippy allowHTML={true} content={<CustomTooltip content={model.model_name} />} placement="top-start">
                            <p style={{ fontWeight: "500", fontSize: "19px", lineHeight: "24px", color: "#0845C0" }}>{model.model_name ? reduceLongName(model.model_name, 12) : "-"}</p>
                        </Tippy>
                        <Tippy allowHTML={true} content={<CustomTooltip content={model.type} />} placement="top-start">
                            <p style={{ fontSize: "14px", color: "#3B3B3B", marginTop: "2px" }}>{model.type ? reduceLongName(model.type, 12) : "-"}</p>
                        </Tippy>
                    </div>
                </div>
                <div style={{ padding: "25px 0px 0px 20px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", alignItems: "center", rowGap: "8px", columnGap: "50px", padding: "10px 9px", color: "#3B3B3B" }}>
                        <div>
                            <Tippy allowHTML={true} content={<CustomTooltip content={model.manufacturer} />} placement="top-start">
                                <div style={{ fontSize: "16px", fontWeight: "500" }}>{model.manufacturer ? reduceLongName(model.manufacturer, 12) : "-"}</div>
                            </Tippy>
                            <p style={{ fontSize: "12px", color: GLOBAL_STYLES.FONT_COLOR_SUB, fontWeight: 400 }}>(Manufacturer)</p>
                        </div>
                        <div>
                            <Tippy allowHTML={true} content={<CustomTooltip content={model.battery_capacity} />} placement="top-start">
                                <div style={{ fontSize: "16px", fontWeight: "500" }}>
                                    {model.battery_capacity ? (
                                        <>
                                            {reduceLongName(model.battery_capacity, 12)}
                                            <span style={{ fontSize: "13px", color: "#848484" }}>  (mAh)</span>
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            </Tippy>
                            <p style={{ fontSize: "12px", color: GLOBAL_STYLES.FONT_COLOR_SUB, fontWeight: 400 }}>(Battery_Capacity)</p>
                        </div>
                        <div>
                            <Tippy allowHTML={true} content={<CustomTooltip content={model.endurance} />} placement="top-start">
                                <div style={{ fontSize: "16px", fontWeight: "500" }}>
                                    {model.endurance ? (
                                        <>
                                            {reduceLongName(model.endurance, 12)}
                                            <span style={{ fontSize: "13px", color: "#848484" }}>  (Minutes)</span>
                                        </>
                                    ) : (
                                        "-"
                                    )}
                                </div>
                            </Tippy>
                            <p style={{ fontSize: "12px", color: GLOBAL_STYLES.FONT_COLOR_SUB, fontWeight: 400 }}>(endurance)</p>
                        </div>
                        <div>
                            <div style={{ fontSize: "16px", fontWeight: "500" }}>{model.service_type || '-'}</div>
                            <p style={{ fontSize: "12px", color: GLOBAL_STYLES.FONT_COLOR_SUB, fontWeight: 400 }}>(Services)</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModelComponent;