import React, { useState } from 'react'
import { isEmailValid } from '../../../../../ReusableFunctions/reusableFunctions'
import { updateUserData } from '../../../../../apis/users'
import Loading from '../../../../../ReusableFunctions/Loader'
import Popup from 'reactjs-popup'
import { Button, CrossCloseButton } from '../../../../../Components/Helper'
import { GLOBAL_STYLES } from '../../../../../appConstants'
import Modal from '../../../../../ReusableComponents/Modal'
import Input from '../../../../../ReusableComponents/Input'
import OTPVerification from '../../../../../ReusableComponents/OTPVerification'
import _debounce from 'lodash/debounce';
import { checkDSPExist } from '../../../../../apis/operator'

const EditEmail = ({ user, open, onClose, onUserUpdate }) => {
    let oldEmail = user.email
    const [userInfo, setUserInfo] = useState(user)
    const [error, setError] = useState({
        email: '',
        existError: ''
    });
    const onChangeUserInfo = (e) => {
        const name = e.target.name
        const value = e.target.value
        checkIsValid(name, value)
        setUserInfo((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    const checkDSPAlreadyExist = _debounce(async (email) => {
        try {
            const res = await checkDSPExist(email);
            setError((prevErrors) => ({
                ...prevErrors,
                existError: '',
            }));
        } catch (error) {
            console.error("Error in checkDSPExist:", error);
            setError((prevErrors) => ({
                ...prevErrors,
                existError: "Phone or email address already exists.",
            }));
        }
    }, 500)
    const checkIsValid = (name, value) => {
        setError((prevErrors) => ({ ...prevErrors, [name]: '' }));

        switch (name) {
            case 'email':
                if (!isEmailValid(value)) {
                    setError((prevErrors) => ({
                        ...prevErrors,
                        email: 'Please enter a valid email address',
                    }));
                    return false;
                }
                else {
                    checkDSPAlreadyExist(value)
                }
                break;
            default:
                setError((prevErrors) => ({ ...prevErrors, [name]: '' }));
                break;
        }
        return true;
    }
    const updateProfileInfo = async () => {
        Loading.set(true);
        try {
            await updateUserData(userInfo)
                .then((res) => {
                    onUserUpdate(userInfo)
                    Loading.set(false);
                })
                .catch((e) => {
                    Loading.set(false);
                    console.log(e)
                    Popup.alert(
                        "Profile",
                        e.message,
                        "ERROR",
                        () => { }
                    );
                })
        } catch (e) {
            Loading(false)
            console.log(e)
            Popup.alert(
                "Profile",
                "Something went wrong while updating email",
                "ERROR",
                () => { }
            );
        }
    }
    return (
        <Modal
            header={"Edit Email"}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "534px", height: "auto" }}
            divider={true}
            headingStyle={{ fontSize: "18px" }}
            iconSize={"18px"}
            headerContainerStyle={{ padding: '20px 40px' }}
        >
            <div style={{ padding: "15px 46px" }}>
                <p style={{ paddingBottom: "17px", fontSize: '16px', color: '#1e1e1e', fontWeight: 500 }}>
                    Enter your email address that you want to change
                </p>
                <form>
                    <div>
                        <div style={{ paddingBottom: "7px", fontSize: '16px', color: '#3b3b3b', fontWeight: "400" }}>
                            Email
                        </div>
                        <Input
                            onChange={onChangeUserInfo}
                            name={"email"}
                            placeholder={"user@gmail.com"}
                            value={userInfo?.email}
                            required={true}
                            style={{ height: '51px', borderRadius: "8px", border: '1px solid #BFC8D9', padding: '5px 15px' }}
                            inputStyle={{ fontSize: "16px" }}
                        />
                        {error &&
                            <div style={{ color: "red", padding: "5px 0px 0px 0px", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>
                                {error.email ? error.email : error.existError}
                            </div>
                        }
                    </div>
                </form>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    padding: "0px 138px 20px 122px",
                }}
            >
                <div>
                    <OTPVerification
                        userId={userInfo.id}
                        sendOtpTo={userInfo.email}
                        type={"email"}
                        btnText={
                            <Button
                                text={"Continue"}
                                onClick={() => { }}
                                style={{ width: "141px", height: "47px", fontSize: '16px' }}
                                isDisabled={error.email || oldEmail === userInfo.email || error.existError}
                            />
                        }
                        callback={(verified) => {
                            if (verified)
                                updateProfileInfo()
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default EditEmail