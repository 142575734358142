import React from 'react'
import { ROUTES } from '../routes'
import servicesIcon from '../assets/sidebaricons/serviceInactive.svg'
import servicesIconActive from '../assets/sidebaricons/serviceActive.svg'

const ServiceIcon = (location) => {
    return (
        <img alt='' className={location.location === ROUTES.MYSERVICES ? "sideBarIcon" : "sideBarIcon-not-selected"} src={location.location === ROUTES.MYSERVICES ? servicesIconActive : servicesIcon} />
    )
}

export default ServiceIcon