import React from 'react';
import InputField from '../../ReusableComponents/InputField';
import { Button } from '../../Components/Helper';
import { nameValidation } from '../../utils/inputValidations';
import Modal from '../../ReusableComponents/Modal';
import Form from '../../ReusableComponents/Form';

const AddCrop = ({ open, onClose, crop, onCropAdd }) => {
    return (
        <Modal
            header={crop?.id ? "Update Crop" : "Add Crop"}
            open={open}
            onClose={onClose}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', width: '100%' }}>
                <Form
                    onSubmitForm={(data) => {
                        onCropAdd(data);
                    }}
                    initialValues={crop || { name: '' }}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "11px",
                        gap: "50px"
                    }}
                >
                    {(onsubmit) => (
                        <>
                            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", rowGap: "5px" }}>
                                <InputField
                                    defaultValue={crop?.name}
                                    label={"Crop name"}
                                    type={"text"}
                                    id={"name"}
                                    placeholder={"Crop name..."}
                                    name={"name"}
                                    validation={nameValidation}
                                    containerStyle={{
                                        rowGap: "15px"
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    padding: "10px 0px 15px 15px",
                                    gap: "20px"
                                }}
                            >
                                <Button
                                    text={crop?.id ? "Update" : "Add"}
                                    onClick={onsubmit}
                                    style={{ width: "90px" }}
                                />
                                <Button
                                    text={"Cancel"}
                                    onClick={onClose}
                                    isBtnNotActive={true}
                                    style={{ width: "90px" }}
                                />
                            </div>
                        </>
                    )}
                </Form>
            </div>
        </Modal>
    );
}

export default AddCrop;
