import React from 'react'
import { ROUTES } from '../routes'
import locationIcon from "../assets/sidebaricons/locationInactive.svg";
import locationIconActive from "../assets/sidebaricons/locationActive.svg"

const LocationIcon = (location) => {
    return (
        <img alt='' className={location.location === ROUTES.MYLOCATIONS ? "sideBarIcon" : "sideBarIcon-not-selected"} src={location.location === ROUTES.MYLOCATIONS ? locationIconActive : locationIcon} />
    )
}

export default LocationIcon