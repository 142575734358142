import React, { useState } from 'react'
import { changePassword } from '../../../../../apis/profile'
import { Button } from '../../../../../Components/Helper'
import Popup from '../../../../../ReusableFunctions/Popup'
import Modal from '../../../../../ReusableComponents/Modal'
import InputField from '../../../../../ReusableComponents/InputField'
import Form from '../../../../../ReusableComponents/Form'
import { confirmPasswordValidation, newPasswordValidation, passwordValidation } from '../../../../../utils/inputValidations'
import ShowPassword from "../../../../../assets/Eye.svg";
import HidePassword from "../../../../../assets/EyeSlash.svg";
import Loading from '../../../../../ReusableFunctions/Loader'

const ChangePassword = ({ open, onClose }) => {
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const updatePassword = async (oldPassword, newPassword, confirmPassword) => {
        if ((oldPassword && newPassword && confirmPassword)) {
            try {
                Loading.set(true)
                const res = await changePassword(oldPassword, newPassword)
                Loading.set(false)
                Popup.alert("Change Password", <p>
                    Congratulations ! <br />
                    We are delighted to inform you that your password has been changed successfully!
                </p>, "SUCCESS", onClose)
            } catch (e) {
                Loading.set(false)
                Popup.alert("Error", e || "something went wrong while changing password.", "ERROR");
            }
        }
    }

    return (
        <Modal
            header={'Change password'}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "574px", height: "auto" }}
            divider={true}
            headingStyle={{ fontSize: "18px" }}
            iconSize={"18px"}
            headerContainerStyle={{ padding: '20px 40px' }}
        >
            <Form
                onSubmitForm={(data) => {
                    const { oldPassword, newPassword, confirmPassword } = data
                    updatePassword(oldPassword, newPassword, confirmPassword)
                }}
                style={{ padding: "15px 40px 20px", display: "flex", flexDirection: "column", gap: "1.1rem" }}
            >
                {(onSubmit) =>
                    <>
                        <InputField
                            label={"Current Password"}
                            name='oldPassword'
                            type={showCurrentPassword ? "text" : "password"}
                            style={{ height: "51px", background: "#F3F3F3", borderRadius: "4px" }}
                            inputStyle={{ background: "#F3F3F3", fontSize: "16px", fontWeight: '500' }}
                            labelStyle={{ lineHeight: "30px", fontSize: "16px", fontWeight: 400, color: "#3B3B3B", paddingBottom: "5px" }}
                            validation={passwordValidation}
                            onClickIcon={() => setShowCurrentPassword(!showCurrentPassword)}
                            icon={showCurrentPassword ? ShowPassword : HidePassword}
                            iconStyle={{ width: "20px" }}
                        />
                        <InputField
                            label={"New password"}
                            name='newPassword'
                            type={showNewPassword ? "text" : "password"}
                            style={{ height: "51px", background: "#F3F3F3", borderRadius: "4px" }}
                            inputStyle={{ background: "#F3F3F3", fontSize: "16px", fontWeight: '500' }}
                            labelStyle={{ lineHeight: "30px", fontSize: "16px", fontWeight: 400, color: "#3B3B3B", paddingBottom: "5px" }}
                            validation={newPasswordValidation}
                            onClickIcon={() => setShowNewPassword(!showNewPassword)}
                            icon={showNewPassword ? ShowPassword : HidePassword}
                            iconStyle={{ width: "20px" }}
                        />
                        <InputField
                            label={"Confirm Password"}
                            name='confirmPassword'
                            style={{ height: "51px", background: "#F3F3F3", borderRadius: "4px" }}
                            type={showConfirmPassword ? "text" : "password"}
                            inputStyle={{ background: "#F3F3F3", fontSize: "16px", fontWeight: '500' }}
                            labelStyle={{ lineHeight: "30px", fontSize: "16px", fontWeight: 400, color: "#3B3B3B", paddingBottom: "5px" }}
                            validation={confirmPasswordValidation}
                            onClickIcon={() => setShowConfirmPassword(!showConfirmPassword)}
                            icon={showConfirmPassword ? ShowPassword : HidePassword}
                            iconStyle={{ width: "20px" }}
                        />
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: "15px", paddingTop: "75px" }}>
                            <Button
                                type={"submit"}
                                text="Update"
                                style={{ width: "110px", height: "40px", fontSize: '16px' }}
                                onClick={() => onSubmit()}
                            />
                            <Button
                                type={"button"}
                                onClick={() => { onClose() }}
                                text={"Cancel"}
                                isBtnNotActive={true}
                                style={{ width: "110px", height: "40px", fontSize: '16px' }}
                            />
                        </div>
                    </>
                }
            </Form>
        </Modal>
    )
}

export default ChangePassword