import React, { useEffect, useState } from "react";
import ActivityPopup from "../../../../../ReusableComponents/ActivityPopup";
import { Button } from "../../../../../Components/Helper";
import FileUploader from "../../../../../ReusableComponents/FileUploader";
import {
  updateCompany,
  updateUserData,
  uploadCompanyLogo,
  uploadIdentity,
  uploadProfilePic,
} from "../../../../../apis/users";
import { useDispatch } from "react-redux";
import { setDsp, setUser } from "../../../../../reducers/userReducer";
import Input from "../../../../../ReusableComponents/Input";
import MapPin from "../../../../../assets/MapPinSvg.svg";
import Buildings from "../../../../../assets/Buildings.svg";
import Popup from "../../../../../ReusableFunctions/Popup";
import Loading from "../../../../../ReusableFunctions/Loader";
import { GLOBAL_STYLES, LIMITS } from "../../../../../appConstants";
import { useNavigate } from "react-router-dom";
const CompanyDetails = ({ stepData, prev, next }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [company, setCompany] = useState({
    name: stepData.userDetails.dsp.name,
    address: "",
    logo: "",
  });

  const handleProfileSetupComplete = () => {
    Loading.set(true);
    const userDetails = stepData.userDetails;
    const identityCard = stepData.selctedIdentites;
    updateUserData(userDetails)
      .then((res) => {
        if (res.updatedDspOperator.password)
          delete res.updatedDspOperator.password;
        dispatch(setUser(res.updatedDspOperator));
        var formData = new FormData();
        formData.append("profilePic", userDetails?.profile_url, "[PROXY]");
        uploadProfilePic(formData)
          .then((res) => {
            var formData = new FormData();
            formData.append("identity", identityCard[0].file, "[PROXY]");
            uploadIdentity(identityCard[0].type, formData)
              .then((res) => {
                updateCompany({ ...company, id: userDetails.dsp_id }).then(
                  (res) => {
                    var formData = new FormData();
                    formData.append("image", company.logo, "[PROXY]");
                    uploadCompanyLogo(formData)
                      .then((res) => { })
                      .catch((err) => {
                        console.log(err);
                        Loading.set(false);
                        Popup.alert("Error", err.errorMessage, "ERROR");
                      });
                  }
                );
                localStorage.removeItem("user-details");
                localStorage.removeItem("company-details");
                localStorage.removeItem("phone-verified");
                localStorage.removeItem("email-verified");
                Loading.set(false);
                Popup.alert(
                  "All steps are completed!",
                  "Congrats! you have completed all the steps now you are ready to explore more",
                  "SUCCESS",
                  () => {
                    navigate("/");
                    dispatch(setDsp({ is_verified: true }));
                  }
                );
              })
              .catch((err) => {
                Loading.set(false);
                console.log(err);
                Popup.alert("Error", err, "ERROR");
              });
          })
          .catch((err) => {
            Loading.set(false);
            console.log(err);
            Popup.alert("Error", err, "ERROR");
          });
      })
      .catch((err) => {
        Loading.set(false);
        console.log(err);
        Popup.alert("Error", err, "ERROR");
      });
  };

  const isFormFilled = () => {
    return company.logo?.name && company.name && company.address;
  };

  const handleOnChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'name') {
      value = value.replace(/[^a-zA-Z\s]/g, '');
      value = value.trimStart().replace(/\s{2,}/g, ' ');
  }
    setCompany((prev) => ({ ...prev, [name]: value }));
  };

  const handleUploadCompanyLogo = (file) => {
    let lcompany = { ...company, logo: file };
    setCompany(lcompany);
    if (localStorage.getItem("company-details")) {
      localStorage.removeItem("company-details");
    }
    localStorage.setItem("company-details", JSON.stringify(lcompany));
  };

  useEffect(() => {
    const lcompany = JSON.parse(localStorage.getItem("company-details"));
    setCompany({ ...company, ...lcompany });
  }, []);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        paddingTop: "20px",
      }}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          gap: "20px",
          padding: "10px 40px 70px",
        }}
        onSubmit={(e) => {
          e.preventDefault();
          if (isFormFilled()) {
            handleProfileSetupComplete();
          }
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "left",
            fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM_HEADER,
            fontWeight: "500",
            marginBottom: "0px",
          }}
        >
          Company Details
        </div>

        <Input
          style={{ height: "35px" }}
          maxLength={LIMITS.NAME}
          id={"name"}
          value={company.name}
          name="name"
          placeholder="Company name"
          onChange={handleOnChange}
          icon={Buildings}
        />

        <Input
          style={{ height: "35px" }}
          maxLength={LIMITS.ADDRESS}
          value={company.address}
          name="address"
          placeholder="Company address"
          onChange={handleOnChange}
          icon={MapPin}
        />
        <FileUploader
          placeholder={"Company logo"}
          onFileSelect={handleUploadCompanyLogo}
          accept={"image/jpeg, image/jpg, image/png, application/pdf"}
          onClear={() => setCompany((prev) => ({ ...prev, logo: {} }))}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: "10px",
            marginTop: "30px",
          }}
        >
          <Button
            type="button"
            className={"form-btn not-active"}
            text={"Back"}
            onClick={prev}
          />
          <Button
            className={"form-btn"}
            text={"Next"}
            isDisabled={!isFormFilled()}
          // onClick={handleProfileSetupComplete}
          />
        </div>
      </form>
    </div>
  );
};

export default CompanyDetails;
