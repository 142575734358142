import React from "react";
import NotFounds from "../../ReusableComponents/NotFounds";

const F404 = () => {
  return (
    <NotFounds msg={"OOPS, No Page Found!"} hideBtn />
  );
};

export default F404;
