import React, { useEffect, useState } from 'react'
import { getLoggedInUser } from '../apis/authentication'
import { Navigate, Outlet, Route, useNavigate } from "react-router-dom"
import { PDRL_ADMIN_ROUTES, ROUTES } from '../routes'
import Loading from '../ReusableFunctions/Loader';
import Profile from '../features/Profile/Profile';
import HomePage from '../features/HomePage/HomePage';
import NotFounds from './NotFounds';
import F404 from '../features/404/F404';
import { useSelector } from 'react-redux';
const ProtectedRoute = ({ element, adminOnly }) => {
    const reduxUser= useSelector(state=>state.user)
    const [user, setUser] = useState(null);
    useEffect(() => {
        Loading.set(true)
        const fetchUser = async () => {
            try {
                const user = await getLoggedInUser();
                setUser(user);
            } catch (error) {
                console.error('Error fetching user:', error);
            } finally {
                // Loading.set(false)
            }
        };

        fetchUser();
    }, [reduxUser]);

    if (user) {
        if (adminOnly) {
            if (user.is_pdrl_admin) {
                return element
            } else return <F404 />
        } else {
            if (user.is_pdrl_admin) return <F404 />
            if (user.dsp.is_verified) {
                return element
            } else return <Profile />
        }
    };
}
export default ProtectedRoute;