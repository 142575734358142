import React from 'react';
import droneHalf from "../../assets/drone-half.svg"
import dotLine from "../../assets/dotLine.png"

const UserDetailsWrapper = ({ children }) => {
    return (<div className='userDetailsWrapper' >
        <img className='userDetailsWrapper-drone-img' src={droneHalf} alt="drone half" />
        <img className='userDetailsWrapper-dotline-img' src={dotLine} alt="dot line" style={{ position: 'absolute', right: '0px', top: "85px" }} />
        {children}
    </div>)
}

export default UserDetailsWrapper;