import React from 'react'
import { ROUTES } from '../routes'
import team from "../assets/sidebaricons/teamsInactive.svg";
import teamActive from "../assets/sidebaricons/teamsActive.svg";

const TeamsIcon = (location) => {
    return (
        <img alt='' className={location.location === ROUTES.MANAGE_TEAM ? "sideBarIcon" : "sideBarIcon-not-selected"} src={location.location === ROUTES.MANAGE_TEAM ? teamActive : team} />
    )
}

export default TeamsIcon