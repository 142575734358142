import React from 'react';
import 'tippy.js/dist/tippy.css';

const styles = {
    customTooltipContent: {
      width: 'fit-content', 
      height: 'fit-content',
      maxWidth:"260px", 
      wordWrap: 'break-word',
    },
  };
const CustomTooltip = ({content,customStyle}) => {
    return (
        <div className='custom-tooltip-content' style={{...styles.customTooltipContent,...customStyle}}>
            {content}
        </div>
    );
}

export default CustomTooltip;
