import React, { useEffect, useState } from "react";
import { getAllUsers, getAllCustomersCount } from "../../apis/users";
import { logoutUser } from "../../apis/authentication";
import LogOut from "../../assets/logout.png";
import { GLOBAL_STYLES } from "../../appConstants";
import { useNavigate } from "react-router-dom";
import Popup from "../../ReusableFunctions/Popup";
import Loading from "../../ReusableFunctions/Loader";
import NotFounds from "../../ReusableComponents/NotFounds";
import { ROUTES } from "../../routes";
import Search from "../../ReusableComponents/Search";
import { getFormatedDate } from "../../ReusableFunctions/reusableFunctions";
import MainContentWrapper from "../MainContentWrapper";
import { Table, TableHeader } from "../../ReusableComponents/Table";
import Pagination from "../../ReusableComponents/Pagination";
import LoaderComponent from "../../ReusableComponents/LoaderComponent";
const PAGE_SIZE = 20;

const UsersList = () => {
  const [users, setUsers] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [customerCount, setCustomerCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  let srNo = (currentPage - 1) * PAGE_SIZE;

  const getAllCustomersFunc = async (page) => {
    try {
      const customers = await getAllUsers(PAGE_SIZE, page, searchQuery);
      setUsers(customers)
      Loading.set(false)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      Loading.set(false)
      Popup.alert("Error", err, "ERROR")
    }
  }
  const getAllCustomersCountFunc = async (searchString) => {
    try {
      const usersCount = await getAllCustomersCount(searchString)
      setCustomerCount(Number(usersCount))
      getAllCustomersFunc(currentPage)
    } catch (err) {
      Loading.set(false)
      Popup.alert("Error", err, "ERROR")
    }
  }
  useEffect(() => {
    Loading.set(true);
    document.title = "BhuMeet - My Customers";
    getAllCustomersCountFunc(searchQuery);
  }, []);

  useEffect(() => {
    getAllCustomersCountFunc(searchQuery)
  }, [searchQuery])

  return (
    <>
      <MainContentWrapper header={'Customers'} rightContent={<Search searchValue={searchQuery} onChange={async (value) => {
        setLoading(true);
        setSearchQuery(() => value)
        getAllCustomersCountFunc(value)
      }} />}>
        <UsersListView users={users} srNo={srNo} loading={loading} />
        <Pagination
          className="pagination-bar pagination-right"
          currentPage={currentPage}
          totalCount={customerCount}
          pageSize={PAGE_SIZE}
          onPageChange={(page) => {
            setLoading(true);
            setCurrentPage(page)
            getAllCustomersFunc(page)
          }}
        />
      </MainContentWrapper>
    </>
  );
};

const UsersListView = ({ users, srNo, loading }) => {
  const navigate = useNavigate();
  return (<>
    {
      users && users.length > 0 ?
        <Table>
          <TableHeader>
            <th style={{ textAlign: "center" }}>Sr No.</th>
            <th>Customer name</th>
            <th>Location</th>
            <th style={{ textAlign: "center" }}>Total request</th>
            <th style={{ textAlign: "center" }}>Last Service Date</th>
          </TableHeader>
          {loading ?
            <LoaderComponent
              width={"2%"}
              top={"-10px"}
            /> :
            users.map((user, i) => (
              <tr key={user.id} className="table-row-hover" onClick={(e) => {
                navigate(ROUTES.CUSTOMER_DETAILS, { state: { userId: user.id } })
              }}>
                <td style={{ textAlign: "center" }}>{++srNo}</td>
                <td>{user.name || "-"}</td>
                <td>{user.address || "-"}</td>
                <td style={{ textAlign: "center" }}>{(Number(user.spraying_requests) + Number(user.survey_requests) + Number(user.training_requests))}</td>
                <td style={{ textAlign: "center" }}>{user.last_request_date ? getFormatedDate(user.last_request_date) : "-"}</td>
              </tr>
            ))}
        </Table> :
        users && <NotFounds msg={"OOPS,No Users Found!"} hideBtn />
    }
  </>
  );
};

const LogoutButton = () => {
  return (
    <div
      onClick={logoutUser}
      style={{
        cursor: "pointer",
        background: GLOBAL_STYLES.BG_ACTIVE,
        padding: "10px",
        borderRadius: "50%",
      }}
    >
      <img src={LogOut} style={{ width: "10px" }} />
    </div>
  );
};
export { UsersList, UsersListView, LogoutButton };
