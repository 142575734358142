import React, { useState } from 'react'
import { Button, CrossCloseButton } from '../../../../../Components/Helper'
import Input from '../../../../../ReusableComponents/Input'
import Modal from '../../../../../ReusableComponents/Modal'
import { updateCompany } from '../../../../../apis/users'
import { GLOBAL_STYLES } from '../../../../../appConstants'
import Loading from '../../../../../ReusableFunctions/Loader'
import Popup from '../../../../../ReusableFunctions/Popup'
import { isEmptyString } from '../../../../../ReusableFunctions/reusableFunctions'
import Form from '../../../../../ReusableComponents/Form'
import InputField from '../../../../../ReusableComponents/InputField'
import { nameValidation } from '../../../../../utils/inputValidations'

const EditCompanyName = ({ user, open, onClose, onUserUpdate }) => {
    const [comapanyInfo, setCompanyInfo] = useState(user.dsp)
    const [error, setError] = useState({
        name: "",
    });
    const onChangeCompanyInfo = (e) => {
        const name = e.target.name
        const value = e.target.value
        checkIsValid(name, value);
        setCompanyInfo((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    const checkIsValid = (name, value) => {
        setError((prevErrors) => ({ ...prevErrors, [name]: '' }));

        switch (name) {
            case 'name':
                if (isEmptyString(value)) {
                    setError((prevErrors) => ({
                        ...prevErrors,
                        name: 'Please enter a valid company name',
                    }));
                    return false;
                }
                break;
            default:
                setError((prevErrors) => ({ ...prevErrors, [name]: '' }));
                break;
        }
        return true;
    }
    const updateCompanyInfo = async (name) => {
        Loading.set(true);
        try {
            await updateCompany({ ...user.dsp, name })
                .then((res) => {
                    onUserUpdate({ ...user.dsp, name })
                    Loading.set(false);
                    Popup.alert(
                        "Profile",
                        "Company name updated successfully.",
                        "SUCCESS",
                        () => { }
                    );
                })
                .catch((e) => {
                    Loading.set(false);
                    console.log(e)
                    Popup.alert(
                        "Profile",
                        e,
                        "ERROR",
                        () => { }
                    );
                })
        } catch (e) {
            Loading(false)
            console.log(e)
            Popup.alert(
                "Profile",
                "Something went wrong while updating profile",
                "ERROR",
                () => { }
            );
        }
    }
    return (
        <Modal
            header={'Edit Company Name'}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "534px", height: "auto" }}
            divider={true}
            headingStyle={{ fontSize: "18px" }}
            iconSize={"18px"}
            headerContainerStyle={{ padding: '20px 40px' }}
        >
            <div style={{ padding: "15px 46px" }}>
                <p style={{ paddingBottom: "17px", fontSize: '16px', color: '#1e1e1e', fontWeight: 500 }}>
                    Enter company name and it will appear on your profile
                </p>
                <Form
                    onSubmitForm={(data) => {
                        updateCompanyInfo(data.name)
                    }}
                    initialValues={{ name: user.dsp.name }}
                >
                    {(onSubmit) =>
                        <>
                            <InputField
                                label={"Edit Company Name"}
                                name={"name"}
                                placeholder={"Company Name"}
                                style={{ height: '51px', borderRadius: "8px", border: '1px solid #BFC8D9', padding: '5px 15px' }}
                                inputStyle={{ fontSize: "16px" }}
                                labelStyle={{ paddingBottom: "7px", fontSize: '16px', color: '#3b3b3b', fontWeight: "400" }}
                                validation={nameValidation}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    padding: "28px 0px 20px 122px",
                                }}
                            >
                                <Button
                                    text={"Update"}
                                    onClick={() => {
                                        onSubmit()
                                    }}
                                    style={{ width: "141px", height: "47px", fontSize: '16px' }}
                                />
                            </div>
                        </>
                    }
                </Form>
            </div >
        </Modal >
    )
}

export default EditCompanyName