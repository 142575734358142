import React from 'react';
import dashboardIcon from "../assets/sidebaricons/homeInactive.svg";
import dashboardIconActive from "../assets/sidebaricons/homeActive.svg";

const DashboardIcon = (location) => {
    return (
        <img src={location.location === "/" ? dashboardIconActive : dashboardIcon} className={location.location === "/" ? "sideBarIcon" : "sideBarIcon-not-selected"} alt='' />
    )
}

export default DashboardIcon