import React, { useEffect, useRef, useState } from "react";
import Map from "../../LeafletMap/Map";
import Modal from "../../../ReusableComponents/Modal";
import Autocomplete from "react-google-autocomplete";
import MarkerIcon from "../../../assets/marker.png";
import L from "leaflet";
import { Button, CrossCloseButton } from "../../../Components/Helper";
import { GLOBAL_STYLES, LIMITS } from "../../../appConstants";
import { addLocation, updateLocation } from "../../../apis/locations";
import Input from "../../../ReusableComponents/Input";
import Popup from "../../../ReusableFunctions/Popup";
import Loading from "../../../ReusableFunctions/Loader";
import searchIcon from "../../../assets/searchIcon.png";
import { LocationCropsManager } from "../../MyServices/SubComponents/AddService/SubComponents/LocationCropsManager";
import { addLocationCropCost, deleteLocationCrop, updateLocationCropCost } from "../../../apis/crops";
import useWindowSize from "../../../hooks/useWindowSize";

const AddLocation = ({
  location,
  open,
  onClose,
  onAddLocation,
  onUpdateLocation,
  onAllCropsDelete,
  isSprayingRequest,
}) => {
  const locationCropsManagerRef = useRef(null)
  let mapInState = undefined;
  let mapMarker = undefined;
  let markerRadius = undefined
  const [locationId, setLocationId] = useState(location?.id)
  const [markerR, setmarkerRadius] = useState()
  const [map, setMap] = useState()
  const [existingCropList, setexistingCropList] = useState([]);
  const [center, setCenter] = useState(
    location
      ? { lat: location.lat, lng: location.lng }
      : { lat: 19.993982, lng: 73.790416 }
  );
  const [locationName, setLocationName] = useState({
    name: location?.name || "Nashik",
    address: location?.address ||
      "Maulana Abdul Kalam Azad Rd, Renuka Nagar, Nashik, Maharashtra"
  });
  const [radius, setRadius] = useState(location?.radius || 1);
  const { currentHeight } = useWindowSize();
  console.log("currentHeight", currentHeight);

  const changeMarkerPosition = (mark) => {
    mapMarker.setLatLng(mark);
    markerRadius.setLatLng(mark);

  };

  const onchangePlace = (e) => {
    e.stopPropagation();
  };

  const handleKeyDown = (e) => { };

  const initMap = (map) => {
    setMap(map)
    var markerOptions = {
      title: "MyLocation",
      clickable: true,
      draggable: false,
      icon: new L.Icon({
        iconUrl: MarkerIcon,
        iconSize: [20, 28],
        iconAnchor: [10, 28],
      }),
    };
    let marker1 = new L.Marker(center, markerOptions)
      .addTo(map)

    let circle = new L.circle(center, {
      color: GLOBAL_STYLES.BG_ACTIVE,
      fillColor: GLOBAL_STYLES.BG_ACTIVE,
      fillOpacity: 0.3,
      radius: radius * 1000
    }).addTo(map);

    setmarkerRadius(circle)
    markerRadius = circle
    mapMarker = marker1;
    map.fitBounds(circle.getBounds())
  };

  const addLocationAPI = () => {
    Loading.set(true);
    const location = {
      name: locationName.name,
      address: locationName.address,
      lat: center.lat,
      lng: center.lng,
      radius,
      is_active: true
    };
    addLocation(location)
      .then((res) => {
        Loading.set(false);
        Popup.alert("LOCATION ADDED !", <p>Congratulations ! <br />
          Your location added in the system</p>, "SUCCESS", () => { onClose(); });
        setLocationId(location.id)
        onAddLocation(res.location, existingCropList);
        if (isSprayingRequest) {
          const crops = locationCropsManagerRef.current.getCrops()
          if (crops.length > 0) {
            addLocationCropCost(crops, res.location.id).then(res => {
            })
          }

        }
      })
      .catch((err) => {
        Loading.set(false);
        console.log(err);
        onClose();
        Popup.alert(
          "Location",
          err,
          "ERROR",
          () => { }
        );
      });
  };

  const handleDeleteAllCrops = async (toBeDeletedCrops) => {
    return new Promise((resolve) => {
      Popup.alert(
        "Warning",
        "Are you sure you want to delete all crops? On delete, the location will be disabled.",
        "WARNING",
        async () => {
          try {
            Loading.set(true);
            if (toBeDeletedCrops.length > 0) {
              await deleteLocationCrop(toBeDeletedCrops);
            }
            resolve();
          } catch (err) {
            Popup.alert("ERROR", err || "Something went wrong while deleting crops ", "ERROR")
            resolve();
          } finally {
            Loading.set(false);
          }
        }
      );
    });
  };

  const handleDeleteCrops = async (toBeDeletedCrops) => {
    try {
      Loading.set(true);
      await deleteLocationCrop(toBeDeletedCrops);
    } catch (err) {
      Popup.alert("ERROR", err || "Something went wrong while deleting crops", "ERROR")
    } finally {
      Loading.set(false);
    }
  };

  const handleUpdateCrops = async (updatedCrops) => {
    try {
      Loading.set(true);
      await updateLocationCropCost(updatedCrops, location?.id);
    } catch (err) {
      Popup.alert("ERROR", err || "Something went wrong while updating crops", "ERROR")
      throw err;
    } finally {
      Loading.set(false);
    }
  };

  const handleUpdateLocation = async () => {
    const loc = {
      ...location,
      name: locationName.name,
      address: locationName.address,
      lat: center.lat,
      lng: center.lng,
      radius,
    };
    setLocationId(location.id);

    try {
      Loading.set(true);
      await updateLocation(loc);
      Popup.alert(
        "Location",
        "Location updated successfully",
        "SUCCESS",
        onClose
      );
      onUpdateLocation(loc);
    } catch (err) {
      Popup.alert("ERROR", err || "Something went wrong while updating location ", "ERROR")
      throw err;
    } finally {
      Loading.set(false);
    }
  };

  const updateLocationAPI = async () => {
    try {
      if (isSprayingRequest) {
        const crops = locationCropsManagerRef.current.getCrops();
        const toBeDeletedCrops = locationCropsManagerRef.current.getToBeDeletedCrops();

        const updatedCrops = crops.filter((crop) => {
          if (crop.id) {
            const existingCrop = existingCropList.find(existing => existing.id === crop.id);
            return existingCrop?.cost !== crop.cost;
          }
          return !crop.id;
        });

        if (crops.length === 0 && toBeDeletedCrops.length > 0) {
          await handleDeleteAllCrops(toBeDeletedCrops);
          onAllCropsDelete(locationId)
        } else if (toBeDeletedCrops.length > 0) {
          await handleDeleteCrops(toBeDeletedCrops);
        }

        if (updatedCrops.length > 0) {
          await handleUpdateCrops(updatedCrops);
        }
      }

      await handleUpdateLocation();
    } catch (err) {
      Popup.alert("ERROR", err || "Something went wrong while updating location ", "ERROR")
    }
  };

  useEffect(() => {
    if (location?.id) {
      setLocationId(location?.id)
    }
  }, [location?.id])

  return (
    <>
      <Modal
        header={location?.id ? "Update Location" : "Add Location"}
        open={open}
        headerContainerStyle={{ padding: "20px 30px" }}
        onClose={onClose}
        closeOnDocumentClick={false}
        contentStyle={{ height: "65%", width: isSprayingRequest ? "65%" : "55%", boxShadow: " 0px 1px 4px 3px #76767626", border: "1px solid #BFC8D9", borderRadius: "8px", position: "relative" }}
      >
        <>
          <form style={{ width: "100%", display: 'flex', height: 'calc(100% - 65px)' }}>
            <div style={{ width: isSprayingRequest ? "50%" : "100%" }}>
              <div
                style={{
                  display: "flex",
                  zIndex: 1000,
                  padding: "0 30px",
                  justifyContent: "space-between",
                  position: "absolute",
                  width: isSprayingRequest ? "50%" : "100%",
                  top: "80px",
                }}
              >
                <div
                  className={isSprayingRequest ? "spraying_request" : "other_request"}
                  style={{
                    display: "flex",
                    width: "65%",
                    height: "38px",
                    alignItems: "center",
                    background: "white",
                    border: "1px solid rgb(217, 217, 217)",
                    borderRadius: "5px",
                    position: "relative"
                  }}
                >
                  <img
                    src={searchIcon}
                    style={{
                      height: "13px",
                      background: "white",
                      padding: "0 10px",
                    }}
                  />
                  <Autocomplete
                    id="autocomplete"
                    defaultValue={locationName.address}
                    style={{
                      border: "none",
                      outline: "none",
                      fontSize: "12px",
                      background: "white",
                      padding: "0px 10px",
                      borderRadius: "0px 5px 5px 0px ",
                      outline: "none",
                      width: "100%",
                    }}
                    className="input"
                    placeholder="Search location"
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                    onChange={(e) => onchangePlace(e)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    options={{
                      types: [],
                    }}
                    onPlaceSelected={(place) => {
                      try {
                        let lat = place.geometry.location.lat();
                        let lng = place.geometry.location.lng();
                        let cntr = {};
                        cntr.lat = parseFloat(lat);
                        cntr.lng = parseFloat(lng);
                        setCenter(cntr);
                        setLocationName({ name: place.address_components.find(loc => loc.types[0] === "locality")?.long_name || place.formatted_address.split(",")[0], address: place.formatted_address });
                        changeMarkerPosition(cntr);
                        mapInState.setView(cntr);
                      } catch { }
                    }}
                    types={[]}
                  />

                </div>
                <div style={{
                  width: "30%",
                  position: "relative",
                }}>
                  <Input
                    maxLength={3}
                    name={"radius"}
                    type={"number"}
                    style={{
                      width: "100%",
                      padding: "5px 0px 5px 10px",
                      height: "38px",
                      borderRadius: '5px',
                      background: "white",
                    }}
                    inputStyle={{ background: "white" }}
                    placeholder={"Radius"}
                    value={radius}
                    onChange={(e) => {
                      const enteredValue = e.target.value.trim();
                      const truncatedValue = enteredValue.slice(0, 3);

                      if (/^\d{1,3}$/.test(truncatedValue) && truncatedValue <= LIMITS.LOCATION_RADIUS) {
                        setRadius(truncatedValue);

                        if (markerR) {
                          markerR.setRadius((Number(truncatedValue) || 0) * 1000);
                          map.fitBounds(markerR.getBounds());
                        }
                      } else {
                        setRadius("");
                      }
                    }}
                  />
                  <div style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    fontSize: "14px",
                    width: "45px",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderLeft: "1px solid #BFC8D9",
                    color: "#1E1E1E",
                  }}>
                    <p>Km</p>
                  </div>
                </div>
              </div>

              <Map
                initCenter={[center.lat, center.lng]}
                initZoom={13}
                handleMapClick={() => { }}
                maxZoom={23}
                style={{
                  height: "100%",
                  borderRadius: "0 0 5px 5px",
                }}
                mapInitialized={(map) => {
                  mapInState = map;
                  initMap(map);
                }}
              />
            </div>
            {isSprayingRequest && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '50%' }}>
                <LocationCropsManager
                  ref={locationCropsManagerRef}
                  setexistingCropList={setexistingCropList}
                  locationId={locationId}
                />
              </div>
            )}
            <Button
              type="button"
              text={location ? "Update" : "Add"}
              style={{
                textAlign: "center",
                position: "absolute",
                zIndex: "1000",
                bottom: "20px",
                right: "30px",
                padding: "4px, 24px, 4px, 24px",
                height: currentHeight < 661 ? "30px" : "32px",
                width: "110px",
                fontSize: "14px",
                fontWeight: 500
              }}
              isDisabled={!(locationName.name && radius > 0)}
              onClick={() => location ? updateLocationAPI() : addLocationAPI()}
            />
          </form>
        </>
      </Modal>
    </>
  );
};

export default AddLocation;
