import React from "react";
import ReactLoading from "react-loading";
import { GLOBAL_STYLES } from "../appConstants";

const Loader = ({ open }) => {
  return (
    open && (
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          zIndex: 100,
          background: "rgba(255, 255, 255, 0.2)",
          backdropFilter: "blur(5px)",
        }}
      >
        <ReactLoading
          className="loading"
          type={"spin"}
          color={GLOBAL_STYLES.BG_ACTIVE}
          height={"2%"}
          width={"2%"}
        />
      </div>
    )
  );
};

export default Loader;
