import React, { useEffect, useState } from "react";
import { AEROMEGH_SERVICES } from "../../../../appConstants";
import ViewSprayingRequest from "./SubComponents/ViewSprayingRequest";
import ViewSurveyRequest from "./SubComponents/ViewSurveyRequest";
import ViewTrainingRequest from "./SubComponents/ViewTrainingRequest";
import { getRequest } from "../../../../apis/requests";
import Popup from "../../../../ReusableFunctions/Popup";
import Loading from "../../../../ReusableFunctions/Loader";
import { useLocation } from "react-router-dom";

const ViewRequest = () => {
  const { state } = useLocation()
  const { requestId, requestType, assingedPilot, assignedDrone } = useLocation().state;

  const [request, setRequest] = useState({ type: requestType })

  const getRequestFunc = async () => {
    try {
      getRequest({ id: requestId, type: requestType }).then(res => {
        setRequest({ ...request, ...res.request })
        Loading.set(false)
      })
    } catch (err) {
      Loading.set(false)
      Popup.alert("Error", err, "ERROR")
    }
  }

  useEffect(() => {
    Loading.set(true)
    getRequestFunc()
  }, [])

  return ((request && requestId) && <>
    {(requestType === AEROMEGH_SERVICES.SPRAYING) && request?.id && (
      <ViewSprayingRequest
        user={state}
        req={request}
        assingedPilot={assingedPilot}
        assignedDrone={assignedDrone}
      />)}
    {(requestType === AEROMEGH_SERVICES.SURVEY) && request?.id && (
      <ViewSurveyRequest
        user={state}
        req={request}
        assingedPilot={assingedPilot}
        assignedDrone={assignedDrone}
      />
    )}
    {requestType === AEROMEGH_SERVICES.TRAINING && (
      <ViewTrainingRequest
        user={state}
        req={request}
      />
    )}
  </>
  );
};

export default ViewRequest;
