import { getDSPId } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const changePassword = (oldPassword, newPassword) => {
    const changePassword = SERVER_URLS.BACKEND_SERVER + "/dsp/operator/profile/changePassword" ;
    return new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(changePassword, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                },
                body: JSON.stringify({ oldPassword, newPassword }),
            });
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while changing password.");
            }
        } catch (error) {
            reject(
                error.errorMessage || "Something went wrong while changing password.");
        }
    });
};
