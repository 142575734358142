import React from 'react'
import { ROUTES } from '../routes'
import boxIcon from "../assets/sidebaricons/requestInactive.svg";
import boxIconActive from "../assets/sidebaricons/requestActive.svg";

const RequestIcon = (location) => {
    return (
        <img alt='' className={(location.location === ROUTES.MYREQUESTS || location.location === ROUTES.REQUESTDETAILS || location.pathname === ROUTES.VIEWREQUEST) ? "sideBarIcon" : "sideBarIcon-not-selected"} src={(location.location === ROUTES.MYREQUESTS || location.location === ROUTES.REQUESTDETAILS || location.location === ROUTES.VIEWREQUEST) ? boxIconActive : boxIcon} />
    )
}

export default RequestIcon