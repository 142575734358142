import React from 'react'

const SingleInfo = ({ icon, title, info, iconStyle, titleStyle, infoStyle }) => {
    return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <img src={icon} alt='icon' style={iconStyle} />
            <div style={{}}>
                <div style={{ fontSize: "14px", fontWeight: "400", ...titleStyle }}>
                    {title}
                </div>
                <div style={{ fontSize: "14px", fontWeight: "500", ...infoStyle }}>
                    {info}
                </div>
            </div>
        </div>
    )
}

export default SingleInfo