import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import Popup from '../../ReusableFunctions/Popup'
import Loading from '../../ReusableFunctions/Loader'
import ViewDSP from './SubComponents/ViewDSP'
import { getAllDSPs } from '../../apis/adminAPIs/dsp'
import Search from '../../ReusableComponents/Search'
import TableView from '../../ReusableComponents/TableView/TableView'
import _debounce from 'lodash/debounce';
const PAGE_SIZE = 20

const DroneServiceProvider = () => {
    const [loading, setLoading] = useState()
    const [selectedDSP, setSelectedDSP] = useState()
    const [serviceProviders, setServiceProviders] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [searchString, setSearchString] = useState("")
    const [dspsCount, setDspsCount] = useState(0)

    const getAllDSPsFunc = async () => {
        try {
            const dsps = await getAllDSPs(PAGE_SIZE, currentPage, searchString || '')
            setServiceProviders(dsps.dsps)
            setDspsCount(dsps.count)
            setLoading(false)
            Loading.set(false)
        } catch (err) {
            setLoading(false)
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(() => {
        Loading.set(true)
        setLoading(true)
        getAllDSPsFunc()
    }, [])

    useEffect(() => {
        const debouncedGetAllDSPs = _debounce(() => {
            getAllDSPsFunc();
        }, 3000);

        debouncedGetAllDSPs();

        return () => {
            debouncedGetAllDSPs.cancel();
        };
    }, [searchString, currentPage]);

    return (
        <MainContentWrapper
            header={`Drone service providers (${dspsCount})`}
            rightContent={<Search
                searchValue={searchString}
                onChange={(value) => {
                    setLoading(true);
                    setSearchString(value)
                    setCurrentPage(1)
                }} />}
        >
            {selectedDSP && <ViewDSP open={selectedDSP} onClose={() => {
                setSelectedDSP()
                localStorage.setItem('dsp_id', "admin")
            }}
                dsp={selectedDSP}
                callback={(dsp) => {
                    setServiceProviders(prev => prev.map(d => {
                        if (d.dsp_id === dsp.dsp_id) return dsp;
                        else return d
                    }))
                    localStorage.setItem('dsp_id', dsp.dsp_id)
                    setSelectedDSP(dsp)
                }}
            />}

            <TableView
                isLoading={loading}
                tableContainerHeight={dspsCount < 15 ? "calc(100% - 40px)" : "calc(100% - 65px)"}
                showSrNo
                onStateChange={(changedState) => {
                    setLoading(true);
                    setCurrentPage(changedState.pageNumber)
                }}
                onClickRow={(dsp) => {
                    localStorage.setItem("dsp_id", dsp.dsp_id)
                    setSelectedDSP(dsp)
                }}
                onClickLink={(request) => { }}
                columns={[
                    { Header: "Company Name", accessor: "dsp_name", width: "10%" },
                    { Header: "Company Address", accessor: "dsp_address", width: "10%" },
                    { Header: "Owner Name", accessor: "operator_name", width: "10%" },
                    { Header: "Owner Email", accessor: "operator_email", width: "10%" },
                    { Header: "Date Of Added", accessor: "dsp_date_created", width: "10%", isDate: "true" },
                    { Header: "Status", accessor: "dsp_is_active_label", width: "5%", showColor: true },
                ]}
                data={serviceProviders.map(sp => {
                    return {
                        ...sp,
                        dsp_is_active_label: sp.dsp_is_active ? "Active" : "Inactive",
                        color: sp.dsp_is_active ? 'green' : 'red'
                    }
                })}
                count={dspsCount}
                pageNumber={currentPage}
                pageSize={PAGE_SIZE}
            />
        </MainContentWrapper >
    )
}

export default DroneServiceProvider