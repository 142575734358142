import { getDSPId } from "../ReusableFunctions/reusableFunctions";
import { store } from './../store'; // Adjust the import path as necessary
import { setUser } from './../reducers/userReducer'; // Adjust the import path as necessary

import { SERVER_URLS } from "../appConstants";

let token = localStorage.getItem("userToken"),
  loggedInUser = null;

const signUp = (user) => {
  const signUp = SERVER_URLS.BACKEND_SERVER + "/dsp/signUp";
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(signUp, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          dspDetails: {
            name: user.firstname + " " + user.lastname,
            email: user.email.toLowerCase(),
            company_name: user.companyName,
            phone: user.phoneNo,
            address: user.address,
            aadhar_number: user.adhaarCardNo,
            pan_number: user.panCardNo,
            password: user.password,
          },
        }),
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage: data?.message || "Something went wrong while logging.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage || "Something went wrong while logging.",
      });
    }
  });
};
const login = (username, password) => {
  const loginUrl = SERVER_URLS.BACKEND_SERVER + "/dsp/login";
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(loginUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({ username, password }),
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data.user);
      } else {
        if (window.location.pathname === '/login') reject(data?.message || "Something went wrong while logging.")
        else logoutUser()
      }
    } catch (error) {
      if (window.location.pathname === '/login') reject(error?.message || "Something went wrong while logging.");
      else logoutUser()
    }
  });
};

const getLoggedInUser = async () => {
  try {
    if (loggedInUser) {
      return loggedInUser;
    } else {
      if (getToken()) {
        const loggedInUser = await login();
        return loggedInUser;
      } else
        throw {
          message: "user not logged in.",
        };
    }
  } catch (e) {
    console.log(e);
    logoutUser();
  }
};

const isUserLoggedIn = () => {
  const token = getToken();
  if (!token) {
    store.dispatch(setUser(null));
  }
  return token
};

const logoutUser = () => {
  localStorage.removeItem("userToken");
  localStorage.removeItem("dsp_id");
  token = null;
  window.location.href = "/login";
};

const setLogin = async (user) => {
  token = user.token;
  loggedInUser = user;
  localStorage.setItem("userToken", user.token);
  return;
};

const getToken = () => {
  return token || localStorage.getItem("userToken");
};

const resetPasswordAPI = (email, otp, password) => {
  return new Promise(async (resolve, reject) => {
    const resetPasswordURL = `${SERVER_URLS.BACKEND_SERVER}/dsp/changePasswordWithOTP`
    try {
      const response = await fetch(resetPasswordURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, otp, password })
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            response.status === 500
              ? "Something went wrong while resetting password."
              : data?.message || "Something went wrong while resetting password.",
        });
      }
    } catch (error) {
      reject({
        errorMessage: "Something went wrong while resetting password.",
      });
    }
  });
}

const forgetPasswordAPI = (email) => {
  return new Promise(async (resolve, reject) => {
    const forgetPasswordURL = `${SERVER_URLS.BACKEND_SERVER}/dsp/forgetPassword`
    try {
      const response = await fetch(forgetPasswordURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email })
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            response.status === 500
              ? "Something went wrong while sending verification OTP."
              : data?.message || "Something went wrong while sending verification OTP.",
        });
      }
    } catch (error) {
      reject({
        errorMessage: "Something went wrong while sending verification OTP.",
      });
    }
  });
}


export {
  signUp,
  login,
  getLoggedInUser,
  isUserLoggedIn,
  logoutUser,
  setLogin,
  getToken,
  resetPasswordAPI,
  forgetPasswordAPI
};
