import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { GLOBAL_STYLES } from '../../../../appConstants';
import { getResourceUtilGraphAPI } from '../../../../apis/dashboard';
import Popup from '../../../../ReusableFunctions/Popup';
import LoaderComponent from '../../../../ReusableComponents/LoaderComponent';
import NotFounds from '../../../../ReusableComponents/NotFounds';
import noRequest from '../../../../assets/no-request.svg'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
const ResourceUtilization = ({ width }) => {

    const options = {
        maintainAspectRatio: false,
        aspectRatio: 1,
        responsive: true,
        plugins: {
            tooltip: {
                backgroundColor: "#ffffff",
                bodyColor: "#000",
                borderColor: "#d9d9d9d9",
                borderWidth: 2,
                titleColor: "#000"
            },
            legend: {
                display: false,
                position: 'top',
                align: "end",
                labels: { boxWidth: 12 },
            },
            title: {
                display: false,
                text: 'Resource Utilization',
                position: "top", // Align the title text to the top
                align: "start", // Align the title text to the left within the top position
                padding: {
                    top: 20,
                    bottom: 0
                }
            },
            centerText: false, // Enable the center text plugin
        },
        scales: {
            y: {
                beginAtZero: true,
                grid: { display: false },
                ticks: {
                    stepSize: 10,
                    color: "#b3b1b1",
                    font: { size: 10 }
                },
            },
            x: {
                grid: { display: false },
                ticks: {
                    color: "#727272",
                    font: { size: 1 }
                }
            },
        },
    };

    const labels = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

    const [loading, setLoading] = useState(true)
    const [hasData, setHasData] = useState(false)

    const [data, setData] = useState({
        labels,
        datasets: [
            {
                label: 'Drones',
                data: [0, 0, 0, 0, 0, 0, 0],
                borderColor: 'rgb(24 170 57)',
                backgroundColor: 'rgb(24 170 57)',
                pointRadius: 0,
                base: 0
            },
            {
                label: 'Pilots',
                data: [0, 0, 0, 0, 0, 0, 0],
                borderColor: 'rgb(242 190 56)',
                backgroundColor: 'rgb(242 190 56)',
                pointRadius: 0,
                base: 0,
            },
        ],
    })
    useEffect(() => {
        getResourceUtilGraphAPI().then(res => {

            let dronesData = res.drones.reduce((acc, curr) => acc + curr, 0);
            let pilotsData = res.pilots.reduce((acc, curr) => acc + curr, 0);
            if (dronesData == 0 && pilotsData == 0) setHasData(false)
            else setHasData(true)

            setData({
                labels,
                datasets: [
                    {
                        label: 'Drones',
                        data: res.drones,
                        borderColor: '#18AA39',
                        backgroundColor: '#18AA39',
                        pointRadius: 0,
                        base: 0
                    },
                    {
                        label: 'Pilots',
                        data: res.pilots,
                        borderColor: '#F2BE38',
                        backgroundColor: '#F2BE38',
                        pointRadius: 0,
                        base: 0,
                    },
                ],
            })
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            Popup.alert("Error", err, "ERROR")
        })
    }, [])
    return <div style={{
        width: width || "49.1%", height: "100%", boxShadow: "rgb(98 115 43 / 16%) 0px 0px 8px 0px", borderRadius: "7px", padding: "40px 20px 20px 20px", position: "relative", display: "flex", flexDirection: "column",
    }}>
        {loading ? <LoaderComponent />
            : <>
                <div style={{ position: "absolute", display: "flex", justifyContent: "space-between", width: "95%", top: "10px", left: "10px", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>
                    <div className="resource-visualization-cards-heading dashboard-cards-heading" >Resource Visualization</div>
                    {hasData ? <div style={{ display: "flex", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL, gap: "20px" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <div style={{ width: "16px", height: "16px", background: '#18AA39' }}></div>
                            <div style={{ fontSize: "9px", color: "#817f7f" }}>Drones</div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <div style={{ width: "16px", height: "16px", background: '#F2BE38' }}></div>
                            <div style={{ fontSize: "9px", color: "#817f7f" }}>Pilots</div>
                        </div>
                    </div> : <div />}
                </div>
                {hasData ? <div style={{ padding: "10px 10px 0px 15px", height: "100%" }}>
                    <Line options={options} data={data} />
                </div> : <NotFounds imgUrl={noRequest} msg={"Unfortunately , we don't have any data to show you !"} hideBtn imgStyle={{ width: "100px" }} />}
            </>


        }
    </div>
}

export default ResourceUtilization