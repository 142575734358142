import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Modal from "../../../../ReusableComponents/Modal";
import AddSprayingService from "./SubComponents/AddSprayingService";
import AddSurveyService from "./SubComponents/AddSurveyService";
import AddTrainingService from "./SubComponents/AddTrainingService";
import { AEROMEGH_SERVICES } from "../../../../appConstants";
import AddInsuranceService from "./SubComponents/AddInsuranceService";
import Loader from "../../../../ReusableComponents/Loader";
import { getAeroMeghServices, getService } from "../../../../apis/services";
import Popup from "../../../../ReusableFunctions/Popup";
import Loading from "../../../../ReusableFunctions/Loader";

const AddService = () => {
  const { aeroMeghServiceId, serviceId } = useLocation().state;
  const [aeroMeghService, setAeroMeghService] = useState({ name: "" });
  const [service, setService] = useState({});
  useEffect(() => {
    Loading.set(true);
    getAeroMeghServices()
      .then((res) => {
        const service = res.aeroMeghServices.find(
          (s) => s.id === aeroMeghServiceId
        );
        setAeroMeghService(service);
        if (!serviceId) Loading.set(false);
        if (serviceId) {
          getService(serviceId)
            .then((res) => {
              setService(res.service);
              Loading.set(false);
            })
            .catch((err) => {
              Loading.set(false);
              console.log(err);
              Popup.alert("Error", err.errorMessage, "ERROR");
            });
        }
      })
      .catch((err) => {
        Loading.set(false);
        console.log(err);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  }, []);

  return (
    <>
      {
        <>
          {aeroMeghService.name === AEROMEGH_SERVICES.SPRAYING && (
            <AddSprayingService
              aeroMeghService={aeroMeghService}
              service={service}
            />
          )}
          {aeroMeghService.name === AEROMEGH_SERVICES.SURVEY && (
            <AddSurveyService
              aeroMeghService={aeroMeghService}
              service={service}
            />
          )}
          {aeroMeghService.name === AEROMEGH_SERVICES.TRAINING && (
            <AddTrainingService
              aeroMeghService={aeroMeghService}
              service={service}
            />
          )}
          {aeroMeghService.name === AEROMEGH_SERVICES.INSURANCE && (
            <AddInsuranceService
              aeroMeghService={aeroMeghService}
              service={service}
            />
          )}
        </>
      }
    </>
  );
};

export default AddService;
