import React from "react";
import ReactDOM from "react-dom";
import ActivityPopup from "../ReusableComponents/ActivityPopup";

const Popup = {
  alert: (header, content, type, okCb, position, okBtnText, onClose) => {
    ReactDOM.render(
      <ActivityPopup
        header={header}
        content={content?.errorMessage || content?.message || content}
        position={position}
        type={type}
        okBtnText={okBtnText}
        onClickOk={() => {
          ReactDOM.unmountComponentAtNode(
            document.getElementById("modalHolder")
          );
          if (okCb) {
            okCb();
          }
        }}
        onClose={() => {
          ReactDOM.unmountComponentAtNode(
            document.getElementById("modalHolder")
          );
          if (onClose) {
            onClose();
          }
        }}
      />,
      document.getElementById("modalHolder")
    );
  },
};

export default Popup;
