import React, { useEffect, useState } from 'react';
import { GLOBAL_STYLES } from '../../../appConstants';

const RevenueStatisticCard = ({ title, rupee }) => {
    const [screenWidth, setScreenWidth] = useState(1366);

    useEffect(() => { const screenWidth = window.innerWidth; setScreenWidth(screenWidth) }, [])

    return (<div className='revenue-statistic-card' style={{ boxShadow: GLOBAL_STYLES.BOX_SHADOW }}>
        <div className='revenue-statistic-card-title' >{title}</div>
        <div style={{
            height: "100%", width: "100%", display: "flex",
            justifyContent: "left",
            alignItems: "flex-end"
        }}><span style={{ fontSize: screenWidth == 1920 ? "1.2rem" : "0.9rem" }}>Rs. </span> <span style={{ color: '#0F5EFF', fontSize: screenWidth == 1920 ? "1.9rem" : "1.3rem", fontWeight: "600", margin: screenWidth == 1920 ? "-5px 5px" : "-2px 5px" }}>{rupee}</span></div>
    </div>)
}

export default RevenueStatisticCard;