import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, user: action.payload };
    },
    setDsp: (state, action) => {
      return {
        ...state,
        user: { ...state.user, dsp: { ...state.user.dsp, ...action.payload } },
      };
    },
  },
});
export const { setUser, setDsp } = userSlice.actions;
export default userSlice.reducer;
