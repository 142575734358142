import React, { useEffect, useState } from 'react';
import Calender from 'react-calendar'
import '../../../index.css';
import { getRequests, getRequestsCountPerDate } from '../../../apis/requests';
import Loading from '../../../ReusableFunctions/Loader';
import Popup from '../../../ReusableFunctions/Popup';
import { Link } from 'react-router-dom';
import { GLOBAL_STYLES } from '../../../appConstants';
import { ROUTES } from '../../../routes';
import { getYYYMMDDFormatDateFromTime, reduceLongName } from '../../../ReusableFunctions/reusableFunctions';
import Tippy from '@tippyjs/react';
import CustomTooltip from '../../../ReusableComponents/CustomTooltip';

const CalenderComponent = ({ user, onDateChange }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [requestPerDate, setRequestPerDate] = useState([])

    const getFilterRequestsFunc = async (e) => {
        Loading.set(true);
        const forDate = new Date(e)
        forDate.setHours(0, 0, 0, 0)
        const forDateEndTime = new Date(new Date(e).setHours(23, 59, 59, 999))
        getRequests(null, null, null, null, null, forDate.toISOString(), forDateEndTime.toISOString(), user?.pilotId ? user?.pilotId : null, true)
            .then((res) => {
                onDateChange(res.requests, forDate, res.requests.length)
                Loading.set(false);
            })
            .catch((err) => {
                console.log(err);
                Loading.set(false);
                Popup.alert("Error", err, "ERROR");
            });
    }

    const getRequestCounteForEachDate = async (firstDateOfMonth, lastDateOfMonth) => {
        try {
            const res = await getRequestsCountPerDate(getYYYMMDDFormatDateFromTime(firstDateOfMonth), getYYYMMDDFormatDateFromTime(lastDateOfMonth), user?.pilotId)
            setRequestPerDate(res.requestPerDate);
        }
        catch (e) {
            throw Error(e)
        }
    }

    // Function to get the last date of the month
    const getLastDateOfMonth = (year, month) => {
        return new Date(year, month + 1, 0)
    };

    const getFirstDayOfMonth = (year, month) => {
        return new Date(year, month, 1)
    }

    const hasRequestsForDate = (date) => {
        const requestEntry = requestPerDate.find(entry => getYYYMMDDFormatDateFromTime(entry.request_date) === getYYYMMDDFormatDateFromTime(date));
        return requestEntry;
    };

    const handleMonthChange = (startDate) => {
        if (startDate) {
            const lastDateOfMonth = getLastDateOfMonth(startDate.getFullYear(), startDate.getMonth());
            getRequestCounteForEachDate(startDate, lastDateOfMonth);
        }
    };

    useEffect(() => {
        const lastDateOfMonth = getLastDateOfMonth(selectedDate.getFullYear(), selectedDate.getMonth());
        const firstDateofMonth = getFirstDayOfMonth(selectedDate.getFullYear(), selectedDate.getMonth())
        getRequestCounteForEachDate(firstDateofMonth, lastDateOfMonth)
            .then((res) => {
                // The promise has resolved, now call the next function
                getFilterRequestsFunc(new Date());
            })
            .catch((error) => {
                // Handle errors if needed
                console.error(error);
            });
    }, [])
    return (<>
        {user?.pilotName && <div style={{
            fontSize: "17px",
            fontWeight: 700,
            display: "flex",
            justifyContent: "space-between",
            color: '#3B3B3B', alignItems: "baseline"
        }}>
            <Tippy allowHTML={true} content={<CustomTooltip content={user.pilotName} customStyle={{ maxWidth: "230px" }} />} placement="top-start">
                <span style={{ textTransform: 'capitalize' }}> {`${user.pilotName.length > 25 ? reduceLongName(user.pilotName, 25) : user.pilotName}'s calender`}</span>
            </Tippy>
            <div style={{ position: "relative" }}>
                <Link className='underlined-bg-active' style={{ display: "block", color: GLOBAL_STYLES.BG_ACTIVE, fontSize: '13px', fontWeight: 600, position: "relative" }} to={ROUTES.DRONE_PILOTS}>All Pilots</Link>
            </div>
        </div>}
        <Calender
            className={"request_calendar"}
            onClickDay={(e) => {
                setSelectedDate(new Date(e))
                getFilterRequestsFunc(e)
            }}
            onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
                handleMonthChange(activeStartDate)
            }}
            value={selectedDate}
            defaultValue={selectedDate}
            tileContent={({ date, view }) => {
                if (view === 'month' && hasRequestsForDate(date)) {
                    return <div style={{ color: '#0F5EFF', position: 'absolute', top: "7px", left: "45px", fontSize: "25px" }}>•</div>;
                }
                return null;
            }}
        />
    </>
    )
}

export default CalenderComponent