import React from "react";
import { GLOBAL_STYLES } from "../appConstants";
import { Button } from "./Helper";
import Plus from "../assets/Plus.svg"

const MainContentWrapper = ({ header, subheader, btnText, onBtnClick, showBtn, isBtnDisabled, leftContent, rightContent, children, containerStyle }) => {
    return <div className="main-wraper-container" style={{ ...containerStyle }}>
        {(header || leftContent) && <div style={{ marginBottom: subheader ? "0px" : "10px" }}>
            <div className="main-wraper-header">
                <div style={{ display: "flex", alignItems: "center", gap: "20px" }}> <span>{header}</span>{leftContent}</div>
                {rightContent}
            </div>
            {subheader && <div
                style={{
                    fontSize: '11px',
                    color: GLOBAL_STYLES.FONT_COLOR_SUB,
                    marginBottom: "10px"
                }}
            > {subheader}</div>}
        </div>}
        {children}
    </div>
}

export default MainContentWrapper;