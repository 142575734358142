import React, { useEffect } from 'react'
import Popup from '../../ReusableFunctions/Popup'
import { resetPasswordAPI } from '../../apis/authentication';
import { useLocation} from 'react-router';
import { useNavigate } from 'react-router-dom';
import Loading from '../../ReusableFunctions/Loader';
import { ROUTES } from '../../routes';

const ResetPassword = () => {
    const navigate=useNavigate()
    const location = useLocation()
    const resetPassword = async () => {
        try {
            const searchParams = new URLSearchParams(location.search);
            const email = searchParams.get('email');
            const otp = searchParams.get('otp');
            await resetPasswordAPI(email, otp)
            Loading.set(false)
            Popup.alert("Reset Password", "New password has been sent on your register email.", "SUCCESS", () => {
                navigate("/login")
            }, undefined, "Login")
        } catch (e) {
            console.log(e)
            Loading.set(false)
            Popup.alert("Error", e, "ERROR", () => {
                navigate("/login")
            }, undefined, "Login")
        }
    }

    useEffect(() => {
        Loading.set(true)
        resetPassword()
        return () => {
            Loading.set(false)
        }
    }, [])

    return (
        <>
        </>
    )
}

export default ResetPassword