import React, { useState } from "react";
import { GLOBAL_STYLES } from "../../../../../appConstants";
import Check from "../../../../../assets/check.svg";
import Location from "../../../../../assets/location.svg";
import Calender from "../../../../../assets/calender.svg";
import Insurance from "../../../../../assets/insurance.svg";
import { getFormatedDate, reduceLongName } from "../../../../../ReusableFunctions/reusableFunctions";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../../routes";
const InsuranceServiceCard = ({ service, isEditDisabled }) => {
  return (
    <>
      <div className="service-card-style" >
        <div className="service-card-heading-style" style={{ background: "rgba(13, 153, 172, 0.14)" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM_HEADER,
            }}
          >
            <img className="service-card-header-icon" src={Insurance} />{" "}
            <div className="service-card-heading-text-style">{reduceLongName(service.name.split(" ")[0], 10)}</div>
          </div>
          {/* <Button text={"Edit"} onClick={() => setOpenUpdateServicePopup(true)} /> */}
          {isEditDisabled ? <></> : <Link
            to={ROUTES.ADD_SERVICE}
            state={{
              aeroMeghServiceId: service.aeromegh_service_id,
              serviceId: service.id,
            }}
          >
            <div style={{ position: "relative" }}>
              <span className='underlined-bg-active'
                style={{
                  cursor: "pointer",
                  color: GLOBAL_STYLES.BG_ACTIVE,
                  textDecoration: "none",
                  fontWeight: "600",
                }}
              >
                Edit
              </span>
            </div>
          </Link>}
        </div>
        <div
          className="service-card-containt-wrapper"
          style={{ fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "" }}>
            <img className="service-card-containt-icon" src={Location} />
            <div
              style={{
                fontWeight: 500,
                width: "75px",
                display: "flex", margin: "0px 15px 0px 15px",
                justifyContent: "space-between",
                fontSize: "15px",
                minWidth: "100px",
              }}
            >
              <span>Locations</span>
              <div>:</div>
            </div>
            <span className="service-card-containt-labels" >{service.locations.length}</span>
          </div>
          {/* <div>
                        <ul>
                            Types
                            {service.types.map(type => <li key={type.id}>
                                <div>{type.is_active ? "Active" : "Inactive"}</div>
                                <div>Name: {type.name}</div>
                                <div>Cost: {type.cost}</div>
                                <div>Insurance company name: {type.insurance_company_name}</div>
                            </li>)}
                        </ul>
                    </div> */}
          <div style={{ display: "flex", alignItems: "center", gap: "" }}>
            <img className="service-card-containt-icon" src={Calender} />
            <div
              style={{
                fontWeight: 500,
                width: "75px",
                display: "flex", margin: "0px 15px 0px 15px",
                justifyContent: "space-between",
                fontSize: "15px",
                minWidth: "100px",
              }}
            >
              <span className="service-card-containt-labels">Started on</span>
              <div>:</div>
            </div>
            <span className="service-card-containt-labels" style={{ width: "60px" }}>
              {getFormatedDate(service.start_date)}
            </span>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "" }}>
            <img className="service-card-containt-icon" src={Check} />
            <div
              style={{
                fontWeight: 500,
                width: "75px",
                display: "flex", margin: "0px 15px 0px 15px",
                justifyContent: "space-between",
                fontSize: "15px",
                minWidth: "100px",
              }}
            >
              <span className="service-card-containt-labels">Status</span>
              <div>:</div>
            </div>
            <span
              className="service-card-containt-labels"
              style={{
                fontWeight: 700,
                color: service.is_active
                  ? GLOBAL_STYLES.ACTIVE
                  : GLOBAL_STYLES.ERROR,
              }}
            >
              {service.is_active ? "Active" : "Inactive"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InsuranceServiceCard;
