import React from 'react'
import Modal from '../../../../../ReusableComponents/Modal'
import { updateCompany } from '../../../../../apis/users'
import Loading from '../../../../../ReusableFunctions/Loader'
import Popup from '../../../../../ReusableFunctions/Popup'
import Form from '../../../../../ReusableComponents/Form'
import InputField from '../../../../../ReusableComponents/InputField'
import { Button } from '../../../../../Components/Helper'
import { addressValidation } from '../../../../../utils/inputValidations'

const EditCompanyAdd = ({ user, open, onClose, onUserUpdate }) => {
    const updateCompanyInfo = async (address) => {
        Loading.set(true);
        try {
            await updateCompany({ ...user.dsp, address })
                .then((res) => {
                    onUserUpdate({ ...user.dsp, address })
                    Loading.set(false);
                    Popup.alert(
                        "SUCCESS",
                        <p>Congratulations !<br />
                            We are delighted to inform you that your address has been changed successfully!</p>,
                        "SUCCESS",
                        () => { }
                    );
                })
                .catch((e) => {
                    Loading.set(false);
                    console.log(e)
                    Popup.alert(
                        "Profile",
                        e,
                        "ERROR",
                        () => { }
                    );
                })
        } catch (e) {
            Loading(false)
            console.log(e)
            Popup.alert(
                "Profile",
                "Something went wrong while updating profile",
                "ERROR",
                () => { }
            );
        }
    }

    return (
        <Modal
            header={'Edit Address'}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "534px", height: "auto" }}
            divider={true}
            headingStyle={{ fontSize: "18px" }}
            iconSize={"18px"}
            headerContainerStyle={{ padding: '20px 40px' }}
        >
            <div style={{ padding: "15px 46px" }}>
                <p style={{ paddingBottom: "17px", fontSize: '16px', color: '#1e1e1e', fontWeight: 500 }}>
                    Enter company address and it will appear on your profile
                </p>
                <Form
                    onSubmitForm={(data) => {
                        updateCompanyInfo(data.address)
                    }}
                    initialValues={{ address: user.dsp.address }}
                >
                    {(onSubmit) =>
                        <>
                            <InputField
                                label={"Edit Company Address"}
                                name={"address"}
                                placeholder={"Company Name"}
                                style={{ height: '51px', borderRadius: "8px", border: '1px solid #BFC8D9', padding: '5px 15px' }}
                                inputStyle={{ fontSize: "16px" }}
                                labelStyle={{ paddingBottom: "7px", fontSize: '16px', color: '#3b3b3b', fontWeight: "400" }}
                                validation={addressValidation}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    padding: "28px 0px 22px 122px"
                                }}
                            >
                                <Button
                                    text={"Update"}
                                    onClick={() => {
                                        onSubmit()
                                    }}
                                    style={{ width: "141px", height: "47px", fontSize: '16px' }}
                                />
                            </div>
                        </>
                    }
                </Form>
            </div>
        </Modal>
    )
}

export default EditCompanyAdd