import React, { useEffect, useState } from 'react'
import Modal from '../../../ReusableComponents/Modal'
import { AEROMEGH_SERVICES, GLOBAL_STYLES } from '../../../appConstants'
import { Button, CrossCloseButton } from '../../../Components/Helper'
import { useNavigate } from "react-router-dom"
import { getFormatedDate, getFormattedTimeFromDate } from '../../../ReusableFunctions/reusableFunctions'
import { getLocation, getLocations } from '../../../apis/locations'
import Popup from '../../../ReusableFunctions/Popup'
import Loading from '../../../ReusableFunctions/Loader'
const ViewRequest = ({ open, onClose, request, callback }) => {
    const navigate = useNavigate()
    const [location, setLocation] = useState([])
    const getLocationFunc = async () => {
        try {

            const res = await getLocation(request.location_id);
            setLocation(res.location)
            Loading.set(false)
        } catch (err) {
            Loading.set(false)
            Popup.alert("ERROR", err, "ERROR")
        }
    }
    useEffect(() => {
        Loading.set(true)
        if (request.location_id)
            getLocationFunc()
        else Loading.set(false)
    }, [])
    return (
        <Modal header={"Request Details"} open={open} onClose={onClose} contentStyle={{ height: "500px", width: "700px", borderRadius: '10px', overflow: "hidden" }}>
            <div style={{ height: "100%", padding: "0px 0px 20px 0px", background: "rgb(244, 244, 244)" }}>
                <div style={{ overflowY: "auto", height: "calc(100% - 50px)", fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, padding: "20px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Request Id</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{request.id || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>DSP Id</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{request.dsp_id || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Type</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{request.type || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Request Number</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{request.req_number || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Status</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{request.status || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Created On</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{`${getFormatedDate(request.date_created)} ${getFormattedTimeFromDate(request.date_created)}` || "-"}</div>
                        </div>
                        {(request.type === AEROMEGH_SERVICES.SPRAYING || request.type === AEROMEGH_SERVICES.SURVEY) &&
                            <div style={{ display: "flex" }}
                            >
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Time</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{`${getFormatedDate(request.time)} ${getFormattedTimeFromDate(request.time)}` || "-"}</div>
                            </div>}
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Time Difference</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{request.time_difference || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Last Updated</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{`${getFormatedDate(request.last_updated)} ${getFormattedTimeFromDate(request.last_updated)}` || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Location</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{location.name || "-"}</div>
                        </div>
                        {(request.type === AEROMEGH_SERVICES.SPRAYING || request.type === AEROMEGH_SERVICES.SURVEY) &&
                            <div style={{ display: "flex" }}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Amount</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.amount + " Rs" || "-"}</div>
                            </div>}
                    </div>
                    <br />





                    <h1 style={{ margin: "10px 0px",fontWeight:600  }}>User Details</h1>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>

                        <div style={{ display: "flex", }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>User Id</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{request.user_id || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{request.user_name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Phone</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{request.user_phone || "-"}</div>
                        </div>
                        {request.type === AEROMEGH_SERVICES.TRAINING &&
                            <div style={{ display: "flex" }}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Qualification</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.qualification || "-"}</div>
                            </div>}
                    </div>



                    {request.type === AEROMEGH_SERVICES.SPRAYING && <>
                        <br />
                        <h1 style={{ margin: "10px 0px",fontWeight:600  }}>Drone Details</h1>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Drone Id</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.drone_id || "-"}</div>
                            </div>

                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Drone Pilot</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.drone_pilot || "-"}</div>
                            </div>

                        </div>
                    </>
                    }

                    {(request.type === AEROMEGH_SERVICES.SPRAYING) && <> <br />
                        <h1 style={{ margin: "10px 0px",fontWeight:600 }}>Field Details</h1>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Field Id</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.field_id || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Name</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.field_name || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Address</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.field_address || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Area</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.field_area || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Crop Name</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.crop_name || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Crop Stage</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.crop_stage || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Agro Chemicals</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.pesticides || "-"}</div>
                            </div>

                        </div>
                    </>
                    }

                    {request.type === AEROMEGH_SERVICES.SURVEY && <> <br />
                        <h1 style={{ margin: "10px 0px",fontWeight:600 }}>Field Details</h1>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Field Id</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.field_id || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Name</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.field_name || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Address</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.field_address || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Area</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.field_area || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Front overlap</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.front_overlap || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Side overlap</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.side_overlap || "-"}</div>
                            </div>
                            <div style={{display:"flex"}}>
                                <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>GSD</div>
                                <div style={{ margin: "0px 10px" }}>:</div>
                                <div style={{}}>{request.gsd || "-"}</div>
                            </div>
                        </div>
                    </>
                    }
                    {request.type === AEROMEGH_SERVICES.TRAINING &&
                        <> <br />
                            <h1 style={{ margin: "10px 0px" , fontWeight:600}}>Course Details</h1>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>


                                <div style={{display:"flex"}}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Course Id</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{request.course_id || "-"}</div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Course Name</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{request.course_name || "-"}</div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Course Description</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{request.course_discription || "-"}</div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Course Duration</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{request.duration + " days" || "-"}</div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Course Amount</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{request.price + " Rs" || "-"}</div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Learner Name</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{request.learner_name || "-"}</div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Learner Age</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{request.age || "-"}</div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Comment</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{request.comment || "-"}</div>
                                </div>

                            </div>
                        </>
                    }

                </div>
            </div>
        </Modal>
    )
}

export default ViewRequest