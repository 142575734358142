import React from 'react'
import DroneBG from '../assets/drone-fly-bg.svg'
import Drone from "../assets/drone-svg.svg"
import DroneLine from "../assets/drone-line.svg"
import { Button } from '../Components/Helper'
import Plus from "../assets/Plus.svg"
import { GLOBAL_STYLES } from '../appConstants'
const NotFounds = ({ imgUrl, msg, onBtnClick, hideBtn, redirectTo, imgStyle }) => {
    return (
        <div style={{
            width: "100%",
            height: "99.99%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            overflow: "hidden",
            // backgroundImage: `url(${DroneBG})`
        }}>
            <div className={imgUrl ? 'illustration-container' : 'drone-animation'}>
                <img src={imgUrl || Drone} className={imgUrl && 'drone-msg'} style={imgUrl && { width: "160px", ...imgStyle }} />
                <img src={!imgUrl && DroneLine} className={!imgUrl && 'drone-line-animation'} />
                <div className='drone-msg'>
                    <div style={{ fontSize: "12px", color: GLOBAL_STYLES.FONT_COLOR_SUB, width: "350px", textAlign: "center", fontWeight: 500 }}>{msg}</div>
                    {!hideBtn && <div style={{ display: "flex", justifyContent: "center" }}>
                        <Button icon={Plus} text={"Add new"} onClick={onBtnClick} redirectTo={redirectTo} />
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default NotFounds