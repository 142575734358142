import MyLocations from "./features/MyLocations/MyLocations"
import MyServices from "./features/MyServices/MyServices"
import Profile from "./features/Profile/Profile"
import MyRequests from "./features/MyRequests/MyRequests"
import AddSprayingService from "./features/MyServices/SubComponents/AddService/SubComponents/AddSprayingService"
import AddSurveyService from "./features/MyServices/SubComponents/AddService/SubComponents/AddSurveyService"
import AddTrainingService from "./features/MyServices/SubComponents/AddService/SubComponents/AddTrainingService"
import AddInsuranceService from "./features/MyServices/SubComponents/AddService/SubComponents/AddInsuranceService"
import AddService from "./features/MyServices/SubComponents/AddService/AddService"
import { UsersList } from "./Components/UserDetails/Users"
import { UserDetails } from "./Components/UserDetails/UserDetails"
import { Fields } from "./Components/Fields"
import AddDronePilot from "./features/DronePilots/SubComponents/AddDronePilot"
import DronePilots from "./features/DronePilots/DronePilots"
import ManageTeam from "./features/Operators/ManageTeam"
import MyDrones from "./features/MyDrones/MyDrones"
import ViewRequest from "./features/MyRequests/SubComponents/ViewRequest/ViewRequest"
import AdminDashBoard from "./AdminComponents/AdminDashBoard/AdminDashBoard"
import DroneServiceProvider from "./AdminComponents/DroneServiceProviders/DroneServiceProviders"
import Users from "./AdminComponents/Users/Users"
import Requests from "./AdminComponents/Requests/Requests"
import Locations from "./AdminComponents/Locations/Locations"
import Revenue from "./features/Revenue/Revenue"
import Manufacturers from "./AdminComponents/Manufacturers/Manufacturers"
import RequestDetails from "./features/MyRequests/RequestDetails"
import { elements } from "chart.js"
import Crops from "./AdminComponents/Crops/Crops"

const ROUTES = {
    PROFILE: "/profile",
    MYSERVICES: "/manageServices",
    MYLOCATIONS: "/manageLocations",
    MYREQUESTS: "/manageRequests",
    REQUESTDETAILS: "/requestDetails",
    ADD_SERVICE: "/service",
    CUSTOMERS: "/customers",
    CUSTOMER_DETAILS: "/customer",
    CUSTOMER_FIELDS: "/customer/:userId/fields",
    ADD_DRONE_PILOT: "/addDronePilot",
    DRONE_PILOTS: "/dronePilots",
    MANAGE_TEAM: "/manageTeams",
    MYREVENUE: "/revenue",
    MYDRONE: "/manageDrones",
    VIEWREQUEST: "/viewRequest",
    REVENUE: "/revenue",

}
const PDRL_ADMIN_ROUTES = {
    ADMIN_DASHBOARD: "/adminDashboard",
    DRONE_SERVICE_PROVIDERS: "/droneServiceProviders",
    USERS: "/users",
    REQUESTS: "/requests",
    LOCATIONS: "/locations",
    MANUFACTURES: '/manufacturers',
    CROPS: '/crops'
}
const PDRL_ADMIN_ROUTES_COMPONENTS = [

    {
        path: PDRL_ADMIN_ROUTES.ADMIN_DASHBOARD,
        element: <AdminDashBoard />
    },
    {
        path: PDRL_ADMIN_ROUTES.DRONE_SERVICE_PROVIDERS,
        element: <DroneServiceProvider />
    },
    {
        path: PDRL_ADMIN_ROUTES.USERS,
        element: <Users />
    },
    {
        path: PDRL_ADMIN_ROUTES.REQUESTS,
        element: <Requests />
    },
    {
        path: PDRL_ADMIN_ROUTES.LOCATIONS,
        element: <Locations />
    },
    {
        path: PDRL_ADMIN_ROUTES.MANUFACTURES,
        element: <Manufacturers />
    },
    {
        path: PDRL_ADMIN_ROUTES.CROPS,
        element: <Crops />
    }
]

const ALL_ROUTES_ARRAY = [
    {
        path: ROUTES.MYSERVICES,
        element: <MyServices />
    }, {
        path: ROUTES.MYLOCATIONS,
        element: <MyLocations />
    }, {
        path: ROUTES.MYREQUESTS,
        element: <MyRequests />
    }, {
        path: ROUTES.REQUESTDETAILS,
        element: <RequestDetails />
    }, {
        path: ROUTES.ADD_SERVICE,
        element: <AddService />
    },
    {
        path: ROUTES.CUSTOMERS,
        element: <UsersList />
    },
    {
        path: ROUTES.CUSTOMER_DETAILS,
        element: <UserDetails />
    },
    {
        path: ROUTES.CUSTOMER_FIELDS,
        element: <Fields />
    },
    {
        path: ROUTES.DRONE_PILOTS,
        element: <DronePilots />
    },
    {
        path: ROUTES.ADD_DRONE_PILOT,
        element: <AddDronePilot />
    },
    {
        path: ROUTES.MANAGE_TEAM,
        element: <ManageTeam />
    },
    {
        path: ROUTES.MYDRONE,
        element: <MyDrones />
    },
    {
        path: ROUTES.VIEWREQUEST,
        element: <ViewRequest />
    },
    {
        path: ROUTES.REVENUE,
        element: <Revenue />
    }
]

export {
    ROUTES,
    ALL_ROUTES_ARRAY,
    PDRL_ADMIN_ROUTES,
    PDRL_ADMIN_ROUTES_COMPONENTS
}