import React, { useEffect, useState } from "react";
import { DISCOUNT_TYPES, STATUS } from "../appConstants";
import Toggle from "react-toggle";

const RequestDiscount = ({ request, changeValue, hideToggle }) => {
    const [toggle, setToggle] = useState(false)
    const [invoice, setInvoice] = useState({})
    const [dspDiscount, setDspDiscount] = useState({})
    const [discount, setDiscount] = useState(0)

    const applyDiscount = (discountValue) => {
        setInvoice({
            ...invoice,
            ...(getInvoiceInDetail(invoice, dspDiscount?.discount, discountValue))
        })
        setDspDiscount({ ...dspDiscount, discount: discountValue })
    }

    const getInvoiceInDetail = ({ service_amount, total_amount, cgst, sgst }, oldDiscount = 0, newDiscount = 0) => {
        const serviceAmountWithNewDiscount = service_amount * (1 - newDiscount / 100);
        const totalAmountWithoutOldDiscount = total_amount / (1 - oldDiscount / 100);
        const totalAmountWithNewDiscount = totalAmountWithoutOldDiscount * (1 - newDiscount / 100);
        const cgst_amount = (serviceAmountWithNewDiscount * cgst) / 100;
        const sgst_amount = (serviceAmountWithNewDiscount * sgst) / 100;
        return {
            service_amount,
            cgst_amount,
            sgst_amount,
            discount_amount: Math.abs(serviceAmountWithNewDiscount - service_amount),
            total_amount: totalAmountWithNewDiscount,
        };
    };

    useEffect(() => {
        if (dspDiscount?.discount) {
            setDiscount(dspDiscount.discount);
            setToggle(!(request.status == STATUS.PAID || request.status == STATUS.CANCELLED))
        }
        if (toggle) {
            document.getElementById('discount-input')?.addEventListener('wheel', function (event) {
                event.preventDefault();
            });
        }
    }, [dspDiscount?.discount, toggle])

    useEffect(() => {
        const dspDiscount = request.invoice.applied_discounts.find(ad => ad.discount_type === DISCOUNT_TYPES.DSP_REQUEST_DISCOUNT)
        setDspDiscount(dspDiscount)
        setInvoice({
            ...request.invoice,
            ...(getInvoiceInDetail(request?.invoice, dspDiscount?.discount || 0, dspDiscount?.discount || 0))
        })
    }, [request.invoice])

    return (<>
        <div style={{ height: "100%", width: "100%" }}>
            {!hideToggle && <div style={{ display: "flex", width: "100%", gap: "10px", justifyContent: "left", alignItems: "center", marginBottom: "15px" }}>
                <div style={{ display: "flex", gap: "10px", width: "50%" }}>
                    <div style={{ width: "50%", fontSize: "13px", color: "#3B3B3B" }}>Discount (%) </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Toggle
                            height={"px"}
                            icons={false}
                            checked={(request.status == STATUS.PAID || request.status == STATUS.CANCELLED) ? false : toggle}
                            onChange={(e) => {
                                if (!(request.status == STATUS.PAID || request.status == STATUS.CANCELLED)) {
                                    setDiscount(0);
                                    applyDiscount(0)
                                    changeValue(0)
                                    setToggle(e.target.checked)
                                }
                            }}
                        />
                    </div>
                </div>
                <div style={{ width: "60%", height: "25px" }}>
                    {toggle &&
                        <input
                            id="discount-input"
                            type="number"
                            step="0.01"
                            max={99.99}
                            min={0}
                            onChange={(e) => {
                                if (e.target.value <= 99.99 && e.target.value >= 0 && e.target.value.length <= 5) {
                                    setDiscount(e.target.value);
                                    applyDiscount(e.target.value)
                                    changeValue(e.target.value)
                                }
                            }}
                            value={discount}
                            style={{
                                padding: "0 10px",
                                background: "#F3F3F3",
                                border: "1px solid #D9D9D9",
                                outline: "none",
                                borderRadius: "5px",
                                width: "100%",
                                fontSize: "13px",
                                color: "#1E1E1E",
                                height: "100%"
                            }}
                        />}
                </div>
            </div>}

            <div style={{ padding: "0 20px", border: "1px solid #BFC8D9", borderRadius: "7px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 0", fontSize: "15px", fontWeight: "500" }}>Payment Summary</div>
                <div style={{ borderBottom: "1px solid #BFC8D9", display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "5px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ fontSize: "13px", color: "#3B3B3B" }}>Service charges</div>
                        <div style={{ fontSize: "13px", color: "#3B3B3B" }}>₹ {invoice.service_amount ? (invoice.service_amount).toFixed(2) : '00'} Rs.</div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ fontSize: "13px", color: "#3B3B3B" }}>Discount</div>
                        <div style={{ fontSize: "13px", color: "#32B338" }}>- ₹{invoice.discount_amount ? (invoice.discount_amount).toFixed(2) : "00"} Rs.  </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ fontSize: "13px", color: "#3B3B3B" }}>CGST</div>
                        <div style={{ fontSize: "13px", color: "#3B3B3B" }}>₹ {invoice.cgst_amount ? (invoice.cgst_amount).toFixed(2) : '00'} Rs.</div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ fontSize: "13px", color: "#3B3B3B" }}>SGST</div>
                        <div style={{ fontSize: "13px", color: "#3B3B3B" }}>₹ {invoice.sgst_amount ? (invoice.sgst_amount).toFixed(2) : '00'} Rs.</div>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 0", fontSize: "14px", fontWeight: "600" }}>
                    <div>{(request.status == STATUS.PAID || request.status == STATUS.COMPLETED) ? 'Total Amount' : 'Estimated Amount'}</div>
                    <div> ₹ {invoice.total_amount ? (invoice.total_amount).toFixed(2) : "00"}</div>
                </div>
            </div>
        </div>
    </>);
};

export default RequestDiscount;
