import React from 'react'
import { GLOBAL_STYLES } from '../appConstants'
import Select from "react-select"

const SelectComponent = (props) => {
    const defaultStyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            background: "#F3F3F3",
            borderRadius: "5px",
            borderColor: GLOBAL_STYLES.BORDER_COLOR,
            outline: "none",
            fontWeight: 600,
            color: "black",
            height: "35px",
            ...props.controlStyle
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            boxShdow: GLOBAL_STYLES.BOX_SHADOW,
            color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : state.isSelected && state.isFocused ? "white" : "black",
            backgroundColor: state.isFocused && !state.isSelected ? "rgba(0, 0, 0, 0.1)" : state.isSelected ? GLOBAL_STYLES.BG_ACTIVE : GLOBAL_STYLES.BG_INACTIVE,
            minHeight: "35px",
            ...props.optionStyle
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            ...props.valueContainerStyle
        }),
        menu: (provided, state) => ({
            ...provided,
            ...props.menuStyles,
        }),
        menuList: (provided, state) => ({
            ...provided,
            ...props.menuListStyles,
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            ...props.indicatorsContainerStyle
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            ...props.indicatorContainerStyle
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            ...props.dropdownIndicatorStyle
        }),
        singleValue: (provided, state) => ({
            ...provided,
            ...props.singleValueStyle
        }),
        loadingIndicator: (provided, state) => ({
            ...provided,
            color: "#a1bffd",
            fontSize: "5px",
            ...props.loadingIndicatorStyle,
        }),
    };

    return (
        <Select
            menuPlacement='auto'
            className={props.className || ""}
            classNamePrefix="react-select"
            value={props.value}
            onChange={props.onChange}
            options={props?.list}
            styles={defaultStyles}
            isLoading={props.isLoading}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary25: GLOBAL_STYLES.BG_ACTIVE,
                    primary: GLOBAL_STYLES.BG_ACTIVE
                }
            })}
            isSearchable={props.isSearchable ? props.isSearchable : false}
            isDisabled={props.isDisabled}
        />
    )
}

export default SelectComponent