import React from 'react'
import { ROUTES } from '../routes'
import revenue from "../assets/sidebaricons/revenueInactive.svg";
import revenueActive from "../assets/sidebaricons/revenueActive.svg";

const RevenueIcon = (location) => {
    return (
        <img alt='' className={location.location === ROUTES.MYREVENUE ? "sideBarIcon" : "sideBarIcon-not-selected"} src={location.location === ROUTES.MYREVENUE ? revenueActive : revenue} />
    )
}

export default RevenueIcon