import React from "react";

const Input = ({
  min,
  max,
  onChange,
  icon,
  value,
  autoFocus,
  required,
  placeholder,
  name,
  type,
  id,
  onClickIcon,
  iconStyle,
  style,
  disabled,
  maxLength,
  onKeyDown,
  className,
  inputStyle,
  register = {}
}) => {
  return (
    <div
      className={`input-default ${disabled ? "input-default-disabled" : ""} ${className || ""}`}
      style={{ ...style, outline: "none" }}
    >
      <input
        {...register}
        min={min}
        max={max}
        maxLength={maxLength}
        className="input"
        autoFocus={autoFocus}
        type={type}
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        disabled={disabled}
        onKeyDown={onKeyDown}
        style={{
          outline: "none",
          width: "100%",
          cursor: disabled && "not-allowed",
          ...inputStyle,
        }}
      />
      <img
        src={icon}
        onClick={onClickIcon}
        style={{ width: "20px", ...iconStyle }}
      />
    </div>
  );
};

export default Input;
