import React from 'react';

const VerticalStepper = ({ steps }) => {

  return (
    <div className="vertical-stepper">
      <div className="steps" >
        {steps.map((step, index) => (

          <div
            key={index}
            className={`step`}
            style={{ borderLeft: steps.length == index + 1 ? `2px solid #e2744400` : `2px solid ${step.statusColor || "#23c329"}` }}
          >
            <div className="step-dot" style={{
              backgroundColor: step.statusColor || "#23c329",
              borderColor: step.statusColor || "#23c329",
              border: step.highlightStatus ? steps.length == index + 1 ? `4px solid #ffbda0` : `2px solid ${step.statusColor || "#23c329"}` : `2px solid ${step.statusColor || "#23c329"}`,
              height: step.highlightStatus ? steps.length == index + 1 ? "18px" : "14px" : "14px",
              width: step.highlightStatus ? steps.length == index + 1 ? "18px" : "14px" : "14px",
              left: step.highlightStatus ? steps.length == index + 1 ? "-10px" : "-8px" : "-8px"
            }}></div>
            <div className="step-content">
              <div className="step-label" title={step.label || ""}>{step.label}</div>
              <div className="step-description">{step.content}</div>
            </div>
          </div>
        ))}
      </div>
    </div >
  );
};

export default VerticalStepper;
