import React, { useState } from "react";
import ReactLoading from "react-loading";
import { GLOBAL_STYLES } from "../../../../appConstants";
import { useNavigate } from "react-router-dom";
const DashBoardCard = ({ backGround, loading, title, data, icon, hoverIcon, content, view, handleImageLoading, isDisabled }) => {
  const navigate = useNavigate();
  const [imgIcon, setImgIcon] = useState(icon)
  return (
    <div className="dashboard-card-parent"
      onClick={(e) => {
        if (!isDisabled) {
          e.stopPropagation()
          if (view) {
            navigate(view)
          }
        }
      }}
      onMouseOver={() => setImgIcon(hoverIcon)}
      onMouseOut={() => setImgIcon(icon)}
      style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
    >
      {
        loading ?
          <div style={{ position: "relative", height: "100%", width: "100%" }}>
            <ReactLoading
              className="loading"
              type={"spin"}
              color={GLOBAL_STYLES.BG_ACTIVE}
              height={"13%"}
              width={"10%"}
            />
          </div> : <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "11px", height: "90%" }}>
              <div className="dashboard-card-parent-inner1 dashboard-cards-heading" >{title}</div>
              <div className="dashboard-card-parent-inner2 dashboard-cards-main-data"> {data ? data : ''}</div>
            </div>
            <img className="dashboard-card-parent-inner3" src={imgIcon} />
          </div>
      }
    </div>
  );
};

export default DashBoardCard;
