import L from "leaflet";

// TO draw a single polygon
const drawPolygon = async (coords, onMap) => {
  return new L.Polygon(coords).addTo(onMap);
};

//To set map center using bounds
const fitMapBounds = async (bounds, toMap) => {
  toMap.fitBounds(bounds);
  return;
};

export { drawPolygon, fitMapBounds };
