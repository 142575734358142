import React, { useEffect, useState } from "react";
import { Button } from "../../../../Components/Helper";
import { AEROMEGH_SERVICES } from "../../../../appConstants";
import {
  getAeroMeghServices
} from "../../../../apis/services";
import { useSelector, useDispatch } from "react-redux";
import AddService from "../AddService/AddService";
import SprayingServiceCard from "./SubComponents/SprayingServiceCard";
import SurveyServiceCard from "./SubComponents/SurveyServiceCard";
import TrainingServiceCard from "./SubComponents/TrainingServiceCard";
import InsuranceServiceCard from "./SubComponents/InsuranceServiceCard";
import Loader from "../../../../ReusableComponents/Loader";
import Loading from "../../../../ReusableFunctions/Loader";
import Popup from "../../../../ReusableFunctions/Popup";
const ServiceCard = ({ service, isEditDisabled, callback }) => {
  const [aeroMeghService, setAeroMeghService] = useState({ name: "" });
  useEffect(() => {
    getAeroMeghServices()
      .then((res) => {
        const ser = res.aeroMeghServices.find(
          (s) => s.id === service.aeromegh_service_id
        );
        setAeroMeghService(ser);

        Loading.set(false);
      })
      .catch((err) => {
        console.log(err);
        Loading.set(false);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  }, []);
  return aeroMeghService.name === AEROMEGH_SERVICES.SPRAYING ? (
    <SprayingServiceCard isEditDisabled={isEditDisabled} service={service} callback={callback} />
  ) : aeroMeghService.name === AEROMEGH_SERVICES.SURVEY ? (
    <SurveyServiceCard isEditDisabled={isEditDisabled} service={service} callback={callback} />
  ) : aeroMeghService.name === AEROMEGH_SERVICES.TRAINING ? (
    <TrainingServiceCard isEditDisabled={isEditDisabled} service={service} callback={callback} />
  ) : aeroMeghService.name === AEROMEGH_SERVICES.INSURANCE ? (
    <InsuranceServiceCard isEditDisabled={isEditDisabled} service={service} callback={callback} />
  ) : (
    <></>
  );
};

export default ServiceCard;
