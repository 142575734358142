import React, { useEffect, useState } from "react";
import Modal from "../../../../ReusableComponents/Modal";
import { Button } from "../../../../Components/Helper";
import { getAeroMeghServices, getServices } from "../../../../apis/services";
import { AEROMEGH_SERVICES } from "../../../../appConstants";
import { Link, useNavigate } from "react-router-dom";
import Popup from "../../../../ReusableFunctions/Popup";
import Loading from "../../../../ReusableFunctions/Loader";
import { ROUTES } from "../../../../routes";
import sprayingcard from "../../../../assets/sprayingcard.svg";
import surveycard from "../../../../assets/surveycard.svg";
import trainingcard from "../../../../assets/trainingcard.svg";
import insurancecard from "../../../../assets/insurancecard.svg";

const SelectServices = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [selectedService, setSelectedService] = useState("");
  const [aeroMeghServices, setAeroMeghServices] = useState([]);
  const [userServices, setUserServices] = useState([]);

  const isServiceUsed = (service) => {
    return userServices.find((ser) => ser.aeromegh_service_id === service.id);
  };

  const initData = async () => {
    Loading.set(true);
    try {
      const res1 = await getAeroMeghServices();
      const services = await getServices();
      setUserServices(services);
      setAeroMeghServices(res1.aeroMeghServices);
      Loading.set(false);
    } catch (e) {
      Loading.set(false);
      Popup.alert("Error", e, "ERROR", () => {
        onClose();
      });
    }
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <>
      {aeroMeghServices.length > 0 && (
        <Modal
          header={"Select service you want to add"}
          contentStyle={{
            height: "fit-content",
            width: "950px",
            padding: "0",
            boxShadow: "0px 0px 8px 2px #75757526",
          }}
          divider={false}
          open={open}
          onClose={onClose}
          headingStyle={{
            fontSize: "18px",
            fontWeight: 500,
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="w-full px-6">
              <hr style={{ width: "100%", padding: "0px 20px" }} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "35px 40px 60px",
                height: "100%",
                width: "100%",
                columnGap: "20px",
              }}
            >
              {aeroMeghServices.map((service) => {
                const isUsed = isServiceUsed(service);
                const isInsurance =
                  service.name === AEROMEGH_SERVICES.INSURANCE;
                const isTraining =
                  service.name === AEROMEGH_SERVICES.TRAINING;
                const isSelected =
                  selectedService.name === service.name && !isInsurance && !isTraining;

                // Select appropriate service card image
                const serviceCardImage =
                  service.name === AEROMEGH_SERVICES.SPRAYING
                    ? sprayingcard
                    : service.name === AEROMEGH_SERVICES.SURVEY
                      ? surveycard
                      : service.name === AEROMEGH_SERVICES.TRAINING
                        ? trainingcard
                        : insurancecard;

                // Define specific box shadows for each service type when selected
                const boxShadowStyle = {
                  [AEROMEGH_SERVICES.SPRAYING]: "0px 0px 15px 5px #238E2840",
                  [AEROMEGH_SERVICES.SURVEY]: " 0px 0px 15px 5px #C2690040",
                  [AEROMEGH_SERVICES.TRAINING]: "0px 0px 15px 5px #5648B640",
                  [AEROMEGH_SERVICES.INSURANCE]: "0px 0px 15px 5px #0D99AC40",
                };

                const boxShadow = isSelected
                  ? boxShadowStyle[service.name]
                  : "none";

                return (
                  <div
                    key={service.id}
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                        boxShadow: boxShadow,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        opacity: (isInsurance || isTraining )|| isUsed ? "0.5" : "1",
                        cursor: (isInsurance || isTraining) || isUsed ? "not-allowed" : "pointer",
                      }}
                      src={serviceCardImage}
                      onClick={() =>
                        !isUsed && (!isInsurance && !isTraining) && setSelectedService(service)
                      }
                      alt={`${service.name} card`}
                    />
                    {(isInsurance || isTraining) && (
                      <div
                        style={{
                          width: "85px",
                          height: "88px",
                          overflow: "hidden",
                          position: "absolute",
                          top: 0,
                          left: 0
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            transform: "rotate(-45deg)",
                            position: "relative",
                            padding: "7px 0px",
                            top: "15px",
                            left: "-30px",
                            width: "120px",
                            fontSize: "11px",
                            background: "white",
                          }}
                        >
                          Coming Soon...
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                padding: "0px 75px",
                gap: "25px",
                marginBottom: "30px",
              }}
            >
              <Button
                style={{
                  width: "160px",
                  height: "35px",
                  border: "1px solid #0F5EFF",
                  fontSize: "15px",
                  color: "#0F5EFF",
                  fontWeight: 600,
                }}
                isBtnNotActive
                text={"Cancel"}
                onClick={onClose}
              />
              {selectedService ? (
                <Link
                  to={
                    selectedService.name === AEROMEGH_SERVICES.INSURANCE
                      ? "/"
                      : ROUTES.ADD_SERVICE
                  }
                  style={{ outline: "none" }}
                  state={{ aeroMeghServiceId: selectedService.id }}
                >
                  <Button
                    style={{
                      width: "160px",
                      height: "35px",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                    text={"Continue"}
                  />
                </Link>
              ) : (
                <Button
                  style={{ width: "160px", height: "35px", fontSize: "15px", fontWeight: 600, }}
                  isDisabled={true}
                  text={"Continue"}
                />
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SelectServices;
