import DownArrow from "../assets/downIconGreen.png";
import SelectedIcon from "../assets/check.png";
import { GLOBAL_STYLES } from "../appConstants";
import Cross from "../assets/CrossIcon.svg";
import pencil from "../assets/edit.svg"
import dustbin from "../assets/Trash.svg"
import Rename from '../assets/edit.png';
import Duplicate from '../assets/copy.png';
import Manage from '../assets/manage.png';
import Export from '../assets/Export.png';
import Download from '../assets/Download.png'

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const Button = ({
  onClick,
  icon,
  text,
  style,
  isDisabled,
  redirectTo,
  state,
  className,
  isBtnNotActive,
  type,
  iconStyle,
  innerClassName,
  textClassName
}) => {
  return redirectTo ? (
    <Link to={redirectTo} state={{ ...state }}>
      <div
        className={`button transition ${className} ${isBtnNotActive ? "btn-not-active" : ""
          }`}
        style={{ ...style, display: "flex", justifyContent: "center", alignItems: "center", opacity: isDisabled ? 0.5 : 1, cursor: isDisabled ? "not-allowed" : "pointer" }}
        onClick={isDisabled || redirectTo ? () => { } : onClick}
      >
        <div
          className={innerClassName}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: icon && text ? "row-reverse" : "row",
            gap: "8px",
            justifyContent: icon && text ? "space-between" : "center",
          }}
        >
          <div style={{ marginTop: '1px' }} type={isDisabled ? "button" : type}>{text}</div>
          {icon && (
            <img style={{ width: "14px", ...iconStyle }} src={icon}></img>
          )}
        </div>
      </div>
    </Link >
  ) : (
    <button
      type={type = isDisabled ? "button" : type}
      className={`button transition ${className} ${isBtnNotActive ? "btn-not-active" : ""
        }`}
      style={{ ...style, display: "flex", justifyContent: "center", alignItems: "center", opacity: isDisabled ? 0.5 : 1, cursor: isDisabled ? "not-allowed" : "pointer" }}
      onClick={isDisabled || redirectTo ? (e) => { e.preventDefault() } : onClick}
      disabled={isDisabled}
    >
      <div
        className={innerClassName}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: icon && text ? "row-reverse" : "row",
          gap: "10px",
          justifyContent: icon && text ? "space-between" : "center",
        }}
      >
        <div className={textClassName} style={{ marginTop: '' }} type={isDisabled ? "button" : type}>{text}</div>
        {icon && <img style={{ width: "15px", ...iconStyle }} src={icon}></img>}
      </div>
    </button>
  );
};
const Dropdown = ({
  top,
  left,
  width,
  listLeft,
  listWidth,
  listHeight,
  maxHeight,
  show,
  list,
  closeDropdown,
  selectedItem,
  isDisabled,
  textAlign,
}) => {
  let myDropdownStyle = {
    position: "absolute",
    textTransform: "",
    border: "1px solid #ccc",
    width: listWidth ? listWidth : width || "max-content",
    padding: "0px",
    lineHeight: "14px",
    backgroundColor: GLOBAL_STYLES.BG_INACTIVE,

    overflow: "auto",
    zIndex: "1001",
    top: top || "20px",
    left: listLeft ? listLeft : left,
    height: listHeight,
    maxHeight,
    textAlign: textAlign,
    borderRadius: "15px",
  };

  return show && !isDisabled ? (
    <>
      <div
        id="dropDownOverlay"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 2,
          left: 0,
          top: 0,
        }}
        onClick={closeDropdown}
      ></div>
      <div
        style={{
          position: "relative",
          backgroundColor: GLOBAL_STYLES.BG_INACTIVE,
        }}
      >
        <div style={myDropdownStyle} className="dropdown-list scrollbar-div">
          {list && list.length ? (
            list.map((item, key) => {
              return (
                <div
                  className="dropdownItem"
                  onClick={() => {
                    closeDropdown();
                    if (item.onClick) item.onClick();
                  }}
                  key={key}
                  style={{
                    backgroundColor:
                      item.name === selectedItem ? GLOBAL_STYLES.BG_ACTIVE : "",
                    color: item.name === selectedItem ? "white" : "#535353",
                    fontSize: GLOBAL_STYLES.FONT_SIZE_SUB_HEADER,
                    padding: "19px 40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                >
                  <span>{item.name}</span>
                  {item.name === selectedItem ? (
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={SelectedIcon}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    ></div>
                  )}{" "}
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
const DropDownWithButton = ({
  isBorder,
  blueDropDownIcon,
  listHeight,
  textAlign,
  margin,
  textFontWeight,
  textFontSize,
  listLeft,
  listWidth,
  list,
  isButton,
  top,
  left,
  width,
  height,
  selectedItem,
  isDisabled,
  maxHeight,
  textColor,
  fontSize,
}) => {
  let [showDropDown, setShowDropDown] = useState(false);
  return (
    <div>
      <div
        style={{
          backgroundColor: GLOBAL_STYLES.BG_INACTIVE,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: GLOBAL_STYLES.FONT_SIZE_SUB_SUB_HEADER,
          padding: "14px 40px",
          borderRadius: "15px",
          color: "#535353",
          border: "1px solid #CECECE",
        }}
        onClick={() => setShowDropDown((prev) => !prev)}
      >
        <span>{selectedItem}</span>{" "}
        <div
          style={{
            width: "25px",
            height: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transform: showDropDown ? "rotate(180deg)" : "",
            transition: "0.15s all",
          }}
        >
          <img src={DownArrow} width={"100%"} height={"100%"} />
        </div>
      </div>
      <Dropdown
        textAlign={textAlign}
        show={showDropDown}
        isButton={isButton}
        selectedItem={selectedItem}
        isDisabled={isDisabled}
        list={list}
        maxHeight={maxHeight}
        width={width}
        top="20px"
        listHeight={listHeight}
        listLeft={listLeft}
        listWidth={listWidth}
        left={isButton ? "5px" : "0"}
        closeDropdown={() => setShowDropDown(false)}
      />
    </div>
  );
};

const CrossCloseButton = ({ style, width, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        cursor: "pointer",
        padding: "5px",
        ...style,
      }}
    >
      <img width={width || "13px"} src={Cross}></img>
    </div>
  );
};

// position - left, right
const OptionMenu = ({ onClickDelete, onClickEdit, isLeft, isRight, onClickManage, onClickDuplicate, onClickDownload, onClickRename,optionsStyles }) => {
  const [opened, setOpened] = useState(false)
  useEffect(() => {
    setOpened(true)
  }, [])

  const menuStyle = {
    position: 'absolute',
    display: 'inline-block',
    margin: '10px',

    fontSize: '11px',
    color: "#1E1E1E",
    opacity: opened ? 1 : 0,
    transition: 'opacity 0.3s ease'
  };

  const optionsStyle = {
    position: 'absolute',
    right: isLeft ? "10px" : undefined,
    left: !isLeft ? "10px" : undefined,
    top: "8px",
    width: '80px',
    background: '#fff',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    zIndex: 1,
    ...optionsStyles,
  };

  const optionStyle = {
    padding: '5px 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  };

  return <div style={menuStyle} className="optin-menu">
    <div style={optionsStyle} className="menu-options">
      {onClickEdit && <div
        className="menu-option"
        style={{
          ...optionStyle,
          borderBottom: onClickDelete ? "1px solid #D9D9D9" : undefined,
        }}
        onClick={onClickEdit}>
        <div>Edit</div>
        <img src={pencil} alt='pencil' style={{ width: "12px" }} />
      </div>}
      {onClickDelete && <div
        className="menu-option"
        style={{
          ...optionStyle
        }}
        onClick={onClickDelete}>
        <div>Delete</div>
        <img src={dustbin} alt='dustbin' style={{ width: "12px" }} />
      </div>}
      {onClickManage && <div
        className="menu-option"
        style={{
          ...optionStyle
        }}
        onClick={onClickManage}>
        <div>Manage</div>
        <img src={Manage} alt='manage' style={{ width: "12px" }} />
      </div>}
      {onClickDuplicate && <div
        className="menu-option"
        style={{
          ...optionStyle
        }}
        onClick={onClickDuplicate}>
        <div>Duplicate</div>
        <img src={Duplicate} alt='duplicate' style={{ width: "12px" }} />
      </div>}
      {onClickDownload && <div
        className="menu-option"
        style={{
          ...optionStyle
        }}
        onClick={onClickDuplicate}>
        <div>Download</div>
        <img src={Download} alt='Duplicate' style={{ width: "12px" }} />
      </div>}
      {onClickRename && <div
        className="menu-option"
        style={{
          ...optionStyle
        }}
        onClick={onClickRename}>
        <div>Rename</div>
        <img src={Rename} alt='Rename' style={{ width: "12px" }} />
      </div>}
    </div>
  </div>
}

export { Button, DropDownWithButton, Dropdown, CrossCloseButton, OptionMenu };
