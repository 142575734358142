import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";

export const getAllOrdersGraph = (serviceType,interval) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/graph/orders?service_type=${serviceType}&interval=${interval}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data.orders);
            } else {
                reject(
                    data?.message || "Something went wrong while getting overview."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting overview.");
        }
    });
} 