import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getAllCrops = async () => {
  try {
    const token = getToken();
    const response = await fetch(
      SERVER_URLS.BACKEND_SERVER + "/dsp/locationcropcost/all/crops",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );

    const res = await response.json();
    if (!response.ok) {
      throw (res.message || "Failed to fetch crops data.");
    }
    return res;

  } catch (err) {
    throw (err || "somthing went wrong while getting crops");
  }
}

export const addLocationCropCost = async (crops, location_id) => {
  try {
    const token = getToken();
    const response = await fetch(
      SERVER_URLS.BACKEND_SERVER + "/dsp/locationcropcost",
      {
        method: "POST",
        body: JSON.stringify({ crops, location_id }),
        headers: {
          Authorization: `Bearer ${token} `,
          "Content-Type": "application/json"
        },
      }
    );

    const res = await response.json();
    if (!response.ok) {
      throw (res.message || "Failed to fetch crops data.");
    }
    return res;

  } catch (err) {
    throw (err || "somthing went wrong while getting crops");
  }
}

export const getCrop = async (cropId, location_id) => {
  try {
    const token = getToken();
    const response = await fetch(
      SERVER_URLS.BACKEND_SERVER + `/dsp/locationcropcost/crop`,
      {
        method: "POST",
        body: JSON.stringify({ cropId, location_id }),
        headers: {
          Authorization: `Bearer ${token} `,
          "Content-Type": "application/json"
        },
      }
    );

    const res = await response.json();
    if (!response.ok) {
      throw (res.message || "Failed to fetch crops data.");
    }
    return res;

  } catch (err) {
    throw (err || "somthing went wrong while getting crops");
  }
}


export const updateLocationCropCost = async (crops, location_id) => {
  try {
    const token = getToken();
    const response = await fetch(
      SERVER_URLS.BACKEND_SERVER + `/dsp/locationcropcost`,
      {
        method: "PUT",
        body: JSON.stringify({ crops, location_id }),
        headers: {
          Authorization: `Bearer ${token} `,
          "Content-Type": "application/json"

        },
      }
    );

    const res = await response.json();
    if (!response.ok) {
      throw (res.message || "Failed to fetch crops data.");
    }
    return res;

  } catch (err) {
    throw (err || "somthing went wrong while getting crops");
  }
}


export const deleteLocationCrop = async (crops) => {
  try {
    const token = getToken();
    const response = await fetch(
      SERVER_URLS.BACKEND_SERVER + `/dsp/locationcropcost`,
      {
        method: "DELETE",
        body: JSON.stringify({ crops }),
        headers: {
          Authorization: `Bearer ${token} `,
          "Content-Type": "application/json"
        },
      }
    );

    const res = await response.json();
    if (!response.ok) {
      throw (res.message || "Failed to delete crop .");
    }
    return res;

  } catch (err) {
    throw (err || "somthing went wrong while deleting crops");
  }
}


export const getCropsByLocation = async (locationId) => {
  try {
    const token = getToken();
    const response = await fetch(
      SERVER_URLS.BACKEND_SERVER + "/dsp/locationcropcost/" + locationId,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token} `,
        },
      }
    );

    const res = await response.json();
    if (!response.ok) {
      throw (res.message || "Failed to fetch crops data.");
    }
    return res;

  } catch (err) {
    throw (err || "somthing went wrong while getting crops");
  }
}


