import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { GLOBAL_STYLES, STATUS } from "../appConstants";
import ReactLoading from "react-loading";
import NotFounds from '../ReusableComponents/NotFounds';
import noRequest from '../assets/no-request.svg'
import { addCommasToAmount } from '../ReusableFunctions/reusableFunctions';

const TotalRequestStatus = ({ isLoading, requestStatusData, totalRequestCount }) => {
    const [mainData, setMainData] = useState([])
    const [hasData, setHasData] = useState(false)
    let requestsStats = { ...requestStatusData }

    const doughnutCenterLabel = {
        id: 'doughnutCenterLabel',
        afterDatasetsDraw(chart, args, plugins) {
            const { ctx, data } = chart;
            const centerX = chart.getDatasetMeta(0).data[0].x;
            const centerY = chart.getDatasetMeta(0).data[0].y;
            ctx.save();
            ctx.font = '16px sans-serif';
            ctx.fillStyle = '#3B3B3B';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText('Total Request', centerX, centerY - 10);
            ctx.font = 'bold 18px sans-serif';
            ctx.fillStyle = 'black';
            ctx.fillText(addCommasToAmount(totalRequestCount), centerX, centerY + 20);
            ctx.restore();
        }
    }

    const [options, setOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        cutout: '72%',
        animation: {
            animateScale: true,
            animateRotate: true,
        },
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    color: '#727272',
                    font: {
                        size: 10,
                        weight: '400',
                    },
                    cursor: "pointer",
                    padding: 25,
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
            },
            tooltip: {
                enabled: true,
                mode: 'point',
                intersect: false,
                callbacks: {
                    title: (tooltipItems) => {
                        return ` ${tooltipItems[0].label.split("%")[1]} (${tooltipItems[0].raw}) `;
                    },
                    label: (tooltipItem) => { return `` },
                },
            },
        },
        elements: {
            arc: {
                borderWidth: 0.5,
            },
        },
        onHover: (event, chartElement) => {
            if (chartElement.length) {
                event.native.target.style.cursor = 'pointer';
            } else {
                event.native.target.style.cursor = 'default';
            }
        },
    }
    );

    const saveData = () => {
        try {
            requestsStats.total = 0
            for (const status of Object.keys(requestStatusData)) {
                if (status !== "total") {
                    requestsStats[status] = Number(requestsStats[status])
                    requestsStats.total += requestsStats[status]
                }
            }
            const labels = [
                ` ${((requestsStats[STATUS.PLACED] / requestsStats.total) * 100).toFixed(2)}% ${STATUS.PLACED}`,
                ` ${((requestsStats[STATUS.ACCEPTED] / requestsStats.total) * 100).toFixed(2)}% ${STATUS.ACCEPTED}`,
                ` ${((requestsStats[STATUS.RESCHEDULED] / requestsStats.total) * 100).toFixed(2)}% ${STATUS.RESCHEDULED}`,
                ` ${((requestsStats[STATUS.OUT_FOR_SERVICE] / requestsStats.total) * 100).toFixed(2)}% ${STATUS.OUT_FOR_SERVICE}`,
                ` ${((requestsStats[STATUS.INPROGRESS] / requestsStats.total) * 100).toFixed(2)}% In Progress`,
                ` ${((requestsStats[STATUS.COMPLETED] / requestsStats.total) * 100).toFixed(2)}% ${STATUS.COMPLETED}`,
                ` ${((requestsStats[STATUS.PAID] / requestsStats.total) * 100).toFixed(2)}% ${STATUS.PAID}`,
                ` ${((requestsStats[STATUS.CANCELLED] / requestsStats.total) * 100).toFixed(2)}% ${STATUS.CANCELLED}`
            ]

            const datasets = [
                {
                    data: [
                        requestsStats[STATUS.PLACED],
                        requestsStats[STATUS.ACCEPTED],
                        requestsStats[STATUS.RESCHEDULED],
                        requestsStats[STATUS.OUT_FOR_SERVICE],
                        requestsStats[STATUS.INPROGRESS],
                        requestsStats[STATUS.COMPLETED],
                        requestsStats[STATUS.PAID],
                        requestsStats[STATUS.CANCELLED]
                    ],
                    backgroundColor: [
                        '#FFC533',
                        '#BBA69F',
                        '#8BB1FF',
                        '#BC95DF',
                        '#62B58F',
                        '#5D62B5',
                        '#F8B271',
                        '#F2726F',
                    ],
                    borderWidth: 0,
                },
            ]
            setMainData({
                labels,
                datasets,
            })

            if (requestsStats.total === 0) setHasData(false)
            else setHasData(true)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        const screenWidth = window.innerWidth;
        if (screenWidth === 1920) {
            setOptions({
                ...options,
                plugins: {
                    legend: {
                        display: true,
                        position: 'right',
                        labels: {
                            color: '#727272',
                            font: {
                                size: 15,
                                weight: '400',
                            },
                            padding: 25,
                            usePointStyle: true,
                            pointStyle: 'circle',
                        },
                    },
                    tooltip: {
                        enabled: true,
                        mode: 'point',
                        intersect: false,
                        callbacks: {
                            title: (tooltipItems) => {
                                return ` ${tooltipItems[0].label.split("%")[1]} (${tooltipItems[0].raw}) `;
                            },
                            label: (tooltipItem) => { return `` },
                        },
                    },
                },
            })
        }
        if (!isLoading) saveData()
    }, [isLoading])

    return (
        isLoading ?
            <ReactLoading
                className="loading"
                type={"spin"}
                color={GLOBAL_STYLES.BG_ACTIVE}
                height={"none"}
                width={"6%"}
            /> :
            hasData ?
                <div style={{ position: 'relative', width: "100%", height: "90%", }}>
                    <Doughnut id='chartjs-doughnut-graph' data={mainData}
                        plugins={[doughnutCenterLabel]}
                        options={options} />
                </div>
                : <NotFounds
                    imgUrl={noRequest}
                    msg={"You haven't received any request yet !"}
                    hideBtn
                    imgStyle={{ width: "100px" }}
                />
    );
};

export default TotalRequestStatus;
