import React, { useEffect, useState } from "react";
import MainContentWrapper from "../../Components/MainContentWrapper";
import { deletedManufacturer, getAllManufacturers } from "../../apis/adminAPIs/manufacturers";
import Loading from "../../ReusableFunctions/Loader";
import Popup from "../../ReusableFunctions/Popup";
import LoaderComponent from "../../ReusableComponents/LoaderComponent";
import { Table, TableHeader } from "../../ReusableComponents/Table";
import more from "../../assets/more.png"
import Tippy from "@tippyjs/react";
import { Button, OptionMenu } from "../../Components/Helper";
import AddManufactuer from "../../Components/AddManufacturer";
import Plus from "../../assets/Plus.svg";

const Manufacturers = () => {
    const [loading, setLoading] = useState(false);
    const [selectedManufacturer, setSelectedManufacturer] = useState();
    const [manufacturers, setManufacturers] = useState([]);
    const [openManufacturerPopup, setOpenManufacturerPopup] = useState(false);
    const [activityPopup, setActivityPopup] = useState(false);

    let srNo = 0;

    const getManufacturersFunc = async (Page) => {
        try {
            const manufacturers = await getAllManufacturers();
            setManufacturers(manufacturers);
            setLoading(false);
            Loading.set(false);
        } catch (err) {
            setLoading(false);
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(() => {
        Loading.set(true);
        getManufacturersFunc();
    }, [])

    const handleOnManufacturerAdd = (manufacturer) => {
        setOpenManufacturerPopup(false);
        if (selectedManufacturer) {
            Popup.alert("Drone Manufacturer", "Drone manufacturer updated successfully", "SUCCESS")
            setManufacturers(() => manufacturers.map((m) => {
                return (m.id === manufacturer.id) ? manufacturer : m
            }))
        } else {
            Popup.alert("Drone Manufacturer", "Drone manufacturer added successfully", "SUCCESS")
            setManufacturers(
                [
                    manufacturer,
                    ...manufacturers
                ])

        }
    }

    const handleDeleteManufacturer = async (id) => {
        try {
            Loading.set(true);
            await deletedManufacturer(id);
            const afterDeleteManufacturers = manufacturers.filter(m => m.id !== id)
            setManufacturers(afterDeleteManufacturers);
            Loading.set(false);
            Popup.alert("Drone manufacturer", "Drone manufacturer successfully deleted.", "SUCCESS");
        } catch (err) {
            Loading.set(false);
            Popup.alert("Drone manufacturer", err, "ERROR");
        }
    }

    const openActicityPopup = (id) => {
        setActivityPopup((prev) => ({
            id: id,
            isOpen: prev.id !== id || !prev.isOpen,
        }));
    }
    return (
        <>
            {
                openManufacturerPopup &&
                <AddManufactuer
                    open={openManufacturerPopup}
                    onClose={() => {
                        setOpenManufacturerPopup(false)
                        setSelectedManufacturer(null)
                    }}
                    onManufacturerAdd={handleOnManufacturerAdd}
                    manufacturerId={selectedManufacturer || null}
                />
            }
            <MainContentWrapper
                header={"Drone Manufacturers"}
                rightContent={
                    <Button
                        type="submit"
                        icon={Plus}
                        text={"Add new"}
                        onClick={() => {
                            setSelectedManufacturer(null)
                            setOpenManufacturerPopup(true)
                        }}
                    />
                }
            >
                {loading ?
                    <LoaderComponent height={'2%'} width={'2%'} /> :
                    <>
                        <Table>
                            <col style={{ width: "15%", }} />
                            <col style={{ width: "70%", }} />
                            <col style={{ width: "3%", }} />

                            <TableHeader>
                                <th style={{ textAlign: "center" }}>Sr No.</th>
                                <th>Manufacturer Name</th>
                                <th>Activity</th>
                            </TableHeader>
                            {(manufacturers && manufacturers.length > 0) && manufacturers.map((manufacturer, i) => (
                                <tr
                                    className='table-row-hover'
                                    key={manufacturer.id} onClick={(e) => {
                                    }}
                                >
                                    <td style={{ textAlign: "center" }}>{++srNo}</td>
                                    <td>{manufacturer.name}</td>
                                    <td style={{ textAlign: "right" }}>
                                        <Tippy interactive onShown={(instance) => {
                                            document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                                                instance.hide();
                                            })
                                        }} className='tippy' trigger='click' arrow={false} content={
                                            <OptionMenu
                                                isLeft
                                                onClickDelete={() => {
                                                    Popup.alert("Drone Manufacturer", "Are you sure, you want to delete this manufacturer ?", "DELETE", () => {
                                                        handleDeleteManufacturer(manufacturer.id)
                                                    });
                                                }}
                                                onClickEdit={() => {
                                                    setSelectedManufacturer(manufacturer.id)
                                                    setOpenManufacturerPopup(true)
                                                }}
                                            />
                                        } >
                                            <img id='menu' src={more} alt='more' style={{ width: "20px", cursor: "pointer" }} onClick={(e) => {
                                                e.preventDefault()
                                                openActicityPopup(manufacturer?.id)

                                            }} />
                                        </Tippy>
                                    </td>
                                </tr>
                            ))}

                        </Table>
                    </>
                }
            </MainContentWrapper>
        </>
    )

}

export default Manufacturers