import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { GLOBAL_STYLES } from "../appConstants";

const DashboardMainCard = ({ height, animatedElement, loading, view, title, mainData, subData, isDisabled }) => {
    const navigate = useNavigate();
    const [showAnimation, setShowAnimation] = useState(false)

    return (
        <div
            className="dashboard-main-card"
            style={{ height: height || '290px' }}
            onClick={(e) => {
                if (!isDisabled) {
                    e.stopPropagation()
                    if (view) {
                        navigate(view)
                    }
                }
            }}
            onMouseOver={() => setShowAnimation(true)}
        >
            {loading ? <div style={{ position: "relative", height: "100%", width: "100%" }}>
                <ReactLoading
                    className="loading"
                    type={"spin"}
                    color={GLOBAL_STYLES.BG_ACTIVE}
                    height={"13%"}
                    width={"10%"}
                />
            </div> : <>
                <div className="dashboard-card-padding">
                    <div className="dashboard-cards-heading" >{title}</div>
                    <div style={{ height: "30%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", marginTop: "9px" }}>
                        <div className="dashboard-cards-main-data">{mainData}</div>
                        <div className="dashboard-cards-sub-data">{subData}</div>
                    </div>
                </div>
                <div
                    className="dashboard-main-card-animation"
                    style={{ top: showAnimation ? "-2px" : "14px", opacity: showAnimation ? 1 : 0 }}
                >
                    {animatedElement}
                </div>
            </>
            }
        </div>
    );
};

export default DashboardMainCard;
