import { validateAdhaarCardNo } from "../ReusableFunctions/reusableFunctions"

export const nameValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 30,
    message: 'Max 30 characters.',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  pattern: {
    value: /^[A-Za-z\s]+$/,
    message: 'Only letters allowed.',
  },
  format: {
    value: /^[A-Za-z\s]+$/,
    message: 'Only letters allowed.',
  },
  validate: (value) => {
    if (value.trim() === '') {
      return "Name can't be empty.";
    }
    return true;
  }
}

export const agroChemicleValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 20,
    message: 'Max 20 characters.',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  validate: (value) => {
    if (value.trim() === '') {
      return "Name can't be empty.";
    }
    return true;
  }
}

export const addressValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  maxLength: {
    value: 50,
    message: "Max 50 characters.",
  },
  pattern: {
    value: /^[A-Za-z.'-:\s]+$/,
    message: 'Only letters allowed.',
  },
  format: {
    value: /^[A-Za-z.'-:\s]+$/,
    message: 'Only letters allowed.',
  },
};

export const descValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  minLength: {
    value: 5,
    message: "At least 5 characters.",
  },
  maxLength: {
    value: 200,
    message: 'Max 200 characters.',
  }
}

export const passwordValidation = {
  required: {
    value: true,
    message: 'Required.',
  },
  minLength: {
    value: 8,
    message: 'At least 8 characters.',
  },
  maxLength: {
    value: 30,
    message: 'Max 30 characters.',
  },
  pattern: {
    value: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/,
    message: 'Must include lowercase, uppercase, digit & special char.',
  }
}

export const newPasswordValidation = {
  required: {
    value: true,
    message: 'Required.',
  },
  minLength: {
    value: 8,
    message: 'At least 8 characters.',
  },
  maxLength: {
    value: 30,
    message: 'Max 30 characters.',
  },
  pattern: {
    value: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/,
    message: 'Must include lowercase, uppercase, digit & special char.',
  },
  validate: (value, context) => {
    if (context.oldPassword && value === context.oldPassword) {
      return "New password must differ from the old one.";
    }
    if (context.confirmPassword && value !== context.confirmPassword) {
      return "Passwords do not match.";
    }
    if (context.verifyPassword && value !== context.verifyPassword) {
      return "Passwords do not match.";
    }
    return true;
  },
}

export const confirmPasswordValidation = {
  required: {
    value: true,
    message: "Required",
  },
  minLength: {
    value: 8,
    message: 'At least 8 characters.',
  },
  maxLength: {
    value: 30,
    message: 'Max 30 characters.',
  },
  validate: (value, context) => {
    if (context.newPassword && value !== context.newPassword) {
      return "Passwords do not match.";
    }
    if (context.password && value !== context.password) {
      return "Passwords do not match.";
    }
    return true;
  },
};

export const phoneNumValidation = {
  required: {
    value: true,
    message: 'Required.',
  },
  minLength: {
    value: 10,
    message: 'Must be 10 digits.',
  },
  maxLength: {
    value: 10,
    message: 'Must be 10 digits.',
  },
  pattern: {
    value: /^\d{10}$/,
    message: 'Enter a valid 10-digit number.',
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Only digits are allowed.',
  }
}

export const emailValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 50,
    message: 'Max 50 characters.',
  },
  pattern: {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Enter a valid email.',
  },
  format: {
    value: /^[a-zA-Z0-9.@]+$/,
    message: 'Character are not allowed.',
  }
}

export const selectFieldValidation = {
  required: {
    value: true,
    message: 'Required',
  }
}

export const DroneUINValidation = {
  required: {
    value: true,
    message: "Required",
  },
  minLength: {
    value: 6,
    message: 'At least 6 characters.',
  },
  maxLength: {
    value: 16,
    message: 'Max 16 characters.',
  },
  pattern: {
    value: /^[^!@#$%^&*()_+{}\[\]:;<>,.?~\\\/\-='`\s]+$/,
    message: 'No spaces or special characters.',
  },
  format: {
    value: /^[a-zA-Z0-9\p{S}\p{P}\p{Zs}]+$/,
    message: 'Character are not allowed.',
  }
}

export const BatteryCapacityValidation = {
  required: 'Required',
  max: {
    value: 100000,
    message: 'Max 100000 mAh.',
  },
  validate: (value) => {
    if (isNaN(value)) {
      return 'Must be a number.';
    }
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  }
}

export const enduranceValidation = {
  required: 'Required',
  max: {
    value: 10000,
    message: 'Max 10000 minutes.',
  },
  validate: (value) => {
    if (isNaN(value)) {
      return 'Must be a number.';
    }
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  }
}

export const pincodeValidation = {
  required: 'Required',
  pattern: {
    value: /^[1-9][0-9]{5}$/,
    message: 'Invalid format.',
  },
  minLength: {
    value: 6,
    message: 'Must be 6 digits.',
  },
  maxLength: {
    value: 6,
    message: 'Must be 6 digits.',
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  }
}

export const aadharCardValidation = {
  required: {
    value: true,
    message: "Required",
  },
  minLength: {
    value: 12,
    message: 'Must be 12 digits.',
  },
  maxLength: {
    value: 12,
    message: 'Must be 12 digits.',
  },
  pattern: {
    value: /^\d{12}$/,
    message: "Must be 12 digits.",
  },
  validate: (value) => {
    const error = validateAdhaarCardNo(value);
    return error === "" || error;
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  }
};

export const panValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  pattern: {
    value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    message: 'Invalid format.',
  },
  maxLength: {
    value: 10,
    message: 'Must be 10 characters.',
  },
  minLength: {
    value: 10,
    message: 'Must be 10 characters.',
  },
  isUpperCase: true,
  format: {
    value: /^[A-Z0-9]+$/,
    message: 'Invalid input.',
  }
};

export const licenceValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 20,
    message: "Max 20 characters.",
  },
  pattern: {
    value: /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/,
    message: 'Invalid format.',
  },
  format: {
    value: /^[A-Za-z0-9-\s]+$/,
    message: 'Only alphanumeric and spaces allowed.',
  },
}

export const droneNameValidation = {
  required: {
    value: true,
    message: "Required",
  },
  pattern: {
    value: /^[a-zA-Z0-9\s]+$/,
    message: "Only alphanumeric and spaces allowed.",
  },
  validate: {
    noLeadingOrTrailingSpaces: (value) =>
      value.trim() === value || "No leading or trailing spaces.",
    noMultipleSpaces: (value) =>
      !/\s{2,}/.test(value) || "No consecutive spaces.",
  },
  maxLength: {
    value: 30,
    message: "Max 30 characters.",
  },
  format: {
    value: /^[A-Za-z0-9\s]+$/,
    message: 'Only alphanumeric and spaces allowed.',
  }
};

export const firstLastNameValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 20,
    message: 'Max 20 characters.',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  pattern: {
    value: /^[A-Za-z\s]+$/,
    message: 'Only letters allowed.',
  },
  validate: (value) => {
    if (value.trim() === '') {
      return "Name can't be empty.";
    }
    if (/^\s|\s$/.test(value)) {
      return "No spaces allowed.";
    }
    return true;
  },
  format: {
    value: /^[A-Za-z\s]+$/,
    message: 'Only letters allowed.',
  },
}

export const companyName = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 50,
    message: 'Max 50 characters.',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  pattern: {
    value: /^[A-Za-z\s]+$/,
    message: 'Only letters allowed.',
  },
  validate: (value) => {
    if (value.trim() === '') {
      return "Name can't be empty.";
    }
    return true;
  },
  format: {
    value: /^[A-Za-z\s]+$/,
    message: 'Only letters allowed.',
  },
}