import React, { useEffect, useState } from 'react'
import { Button } from './Helper';
import Input from '../ReusableComponents/Input';
import Modal from '../ReusableComponents/Modal';
import { addManufacturer, getManufacturer, updateManufacturer } from '../apis/adminAPIs/manufacturers';
import Loading from '../ReusableFunctions/Loader';
import Popup from '../ReusableFunctions/Popup';
import { LIMITS } from '../appConstants';


const AddManufactuer = ({ open, onClose, manufacturerId, onManufacturerAdd }) => {
    const [oldManufacturer, setoldManufacturer] = useState({})
    const [manufacturerInfo, setManufacturerInfo] = useState({
        name: "",
    });

    const onChangeManufacturerInfo = (e) => {
        const name = e.target.name
        let value = e.target.value
        if (name === "name" ) {
            value = value.replace(/^\s+/, '');
            if (!/\S/.test(value)) {
                value = value.replace(/\s+$/, '');
            }
            value = value.replace(/\s{2,}/g, ' ');
        }
        
        setManufacturerInfo({ ...manufacturerInfo, [name]: value })
    }


     const isEveryThingOk = () => {
        if (manufacturerId) return (
            manufacturerInfo.name!==oldManufacturer.name && manufacturerInfo.name
        )
        else return (manufacturerInfo.name)
    }

   
    const createManufacturer = () => {
        Loading.set(true);
        addManufacturer(manufacturerInfo)
            .then((manufacturer) => {
                Loading.set(false);
                onManufacturerAdd(manufacturer)
            })
            .catch((err) => {
                Loading.set(false);
                console.log(err);
                Popup.alert(
                    "Manufacturer",
                    err,
                    "ERROR",
                    () => { }
                );
            });
    };
    const handleUpdateManufacturer = (manufacturerId,manufacturerInfo) => {
        Loading.set(true);
        updateManufacturer(manufacturerId,manufacturerInfo)
            .then((manufacturer) => {
                Loading.set(false);
                onManufacturerAdd(manufacturer)
                Popup.alert("Drone Manufacturer", "Drone manufacturer member updated successfully", "SUCCESS", () => {
                });
            })
            .catch((err) => {
                Loading.set(false);
                console.log(err);
                Popup.alert(
                    "Drone Manufacturer",
                    "Something went wrong while updating team member",
                    "ERROR",
                    () => { }
                );
            });
    }
    useEffect(() => {
        if (manufacturerId) {
            Loading.set(true);
            getManufacturer(manufacturerId)
                .then((manufacturer) => {
                    setManufacturerInfo(()=>manufacturer);
                    setoldManufacturer(()=>manufacturer);
                     Loading.set(false) 
                })
                .catch((e) => {
                    console.log(e);
                    Loading.set(false)
                    Popup.alert("Error", e, "ERROR");
                })
        }
    }, [])

    return (
        <Modal
            header={ manufacturerId? "Update manufacturer" : "Add manufacturer"}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "575px", height: 'auto' }}
        >
            <div style={{
                display: "flex",
                flexDirection:"column",
                fontSize: "11px",
                padding: "20px 30px 10px"
            }}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", rowGap: "5px" }}>
                    <div style={{ fontSize: "13px", color: "#3B3B3B" }}>Manufacturer name</div>
                    <Input
                        onChange={onChangeManufacturerInfo}
                        name={"name"}
                        placeholder={"Name"}
                        value={manufacturerInfo.name}
                        required={true}
                        style={{ fontSize: "13px", height: '35px' }}
                        maxLength={LIMITS.NAME}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    padding: "10px 30px 15px",
                    gap: "20px"
                }}
            >
                <Button
                    text={(manufacturerId) ? "Update" : "Add"}
                    isDisabled={!isEveryThingOk()}
                    onClick={() => {
                        if (manufacturerId){
                         handleUpdateManufacturer(manufacturerId,manufacturerInfo)
                        }
                        else createManufacturer()
                    }}
                    style={{ width: "90px" }}
                />
                <Button
                    text={"Cancel"}
                    onClick={() => {
                        onClose()
                    }}
                    isBtnNotActive={true}
                    style={{ width: "90px" }}
                />
            </div>
        </Modal>
    )
}

export default AddManufactuer;