import React, { useEffect, useState } from 'react'
import Modal from '../../../ReusableComponents/Modal'
import { GLOBAL_STYLES } from '../../../appConstants'
import { Button, CrossCloseButton } from '../../../Components/Helper'
import { useNavigate } from "react-router-dom"
import { getFormatedDate } from '../../../ReusableFunctions/reusableFunctions'
import { getLocation, getLocations } from '../../../apis/locations'
import Popup from '../../../ReusableFunctions/Popup'
const ViewUser = ({ open, onClose, user, callback }) => {
    const navigate = useNavigate()
    const [location, setLocation] = useState([])
    const getLocationFunc = async () => {
        try {

            const location = await getLocation(user.operator_location_id);
            setLocation(location)
        } catch (err) {
            Popup.alert("ERROR", err, "ERROR")
        }
    }
    useEffect(() => {
        if (user.location_id)
            getLocationFunc()
    }, [])
    return (
        <Modal header={'User Details'} open={open} onClose={onClose} contentStyle={{ height: "500px", width: "700px", borderRadius: '10px', overflow: "hidden" }}>
            <div style={{ height: "100%", padding: "0px 0px 20px 0px",background:"rgb(244, 244, 244)" }}>
                <div style={{ overflowY: "auto", height: "calc(100% - 50px)", fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, padding: "20px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr",gap:"20px" }}>
                        <div>
                            <span style={{color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Id</span>
                            <span style={{ margin: "0px 10px" }}>:</span>
                            <span style={{}}>{user.id || "-"}</span>
                        </div>
                        <div>
                            <span style={{color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Name</span>
                            <span style={{ margin: "0px 10px" }}>:</span>
                            <span style={{}}>{user.name || "-"}</span>
                        </div>
                        <div>
                            <span style={{color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Address</span>
                            <span style={{ margin: "0px 10px" }}>:</span>
                            <span style={{}}>{user.address || "-"}</span>
                        </div>
                        <div>
                            <span style={{color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Created On</span>
                            <span style={{ margin: "0px 10px" }}>:</span>
                            <span style={{}}>{getFormatedDate(user.date_created) || "-"}</span>
                        </div>
                        <div>
                            <span style={{color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Date Of Birth</span>
                            <span style={{ margin: "0px 10px" }}>:</span>
                            <span style={{}}>{getFormatedDate(user.date_of_birth) || "-"}</span>
                        </div>
                        <div>
                            <span style={{color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Phone number</span>
                            <span style={{ margin: "0px 10px" }}>:</span>
                            <span style={{}}>{user.mobile_number || "-"}</span>
                        </div>
                        <div>
                            <span style={{color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Email</span>
                            <span style={{ margin: "0px 10px" }}>:</span>
                            <span style={{}}>{user.email || "-"}</span>
                        </div>
                        <div>
                            <span style={{color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Occupation</span>
                            <span style={{ margin: "0px 10px" }}>:</span>
                            <span style={{}}>{user.occupation || "-"}</span>
                        </div>
                    </div>
                </div>
              
            </div>
        </Modal>
    )
}

export default ViewUser