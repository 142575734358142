import React from 'react'
import { GLOBAL_STYLES } from '../../../../../appConstants'

const EditButton = ({ onClickHandler, style }) => {
    return (
        <div style={{ color: GLOBAL_STYLES.FONT_COLOR_HEADER, textDecoration: "none", cursor: "pointer", fontWeight: 600, fontSize: '16px', position: 'relative', ...style }} className="underlined-bg-active" onClick={() => { onClickHandler() }}>
            Edit
        </div>
    )
}

export default EditButton