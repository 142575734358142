import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";

export const getAllLocationsCount = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/location/getLocationsCount`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data.locationsCount);
            } else {
                reject(
                    data?.message || "Something went wrong while getting locations count ."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting locations count.");
        }
    });
}

export const getAllLocations = (pageSize, pageNumber) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/location?pageSize=${pageSize}&pageNumber=${pageNumber}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data.locations);
            } else {
                reject(
                    data?.message || "Something went wrong while getting locations."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting locations.");
        }
    });
}