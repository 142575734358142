import React, { useEffect, useState } from "react";
import Modal from "../../../ReusableComponents/Modal";
import Loading from "../../../ReusableFunctions/Loader";
import Popup from "../../../ReusableFunctions/Popup";
import {
  Button,
} from "../../../Components/Helper";
import { getLocation, getLocations } from "../../../apis/locations";
import {
  addDSPDrone,
  addDSPDroneUAS,
  checkUINAlreadyExist,
  getAllDSPDroneUAS,
  getAllManufacturers,
  getDSPDrone,
  updateDSPDrone,
} from "../../../apis/drones";
import {
  DRONE_CAPACITIES,
  DRONE_TYPES,
  SERVICE_TYPES,
} from "../../../appConstants";
import Form from "../../../ReusableComponents/Form";
import SelectField from "../../../ReusableComponents/SelectField";
import { BatteryCapacityValidation, DroneUINValidation, droneNameValidation, enduranceValidation, selectFieldValidation } from "../../../utils/inputValidations";
import InputField from "../../../ReusableComponents/InputField";
import CreatableSelectField from "../../../ReusableComponents/CreatableSelectField";
import useWindowSize from "../../../hooks/useWindowSize";

const AddDSPDrone = ({ open, onClose, droneId, onDroneAdd }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerHeight <= 600);
  const [selectedModel, setselectedModel] = useState(null);
  const [selectedField, setSelectedField] = useState(
    "Please select drone location..."
  );
  const [droneInfo, setDroneInfo] = useState({
    name: "",
    type: "",
    location_id: null,
    model_name: null,
    drone_uin: "",
    manufacturer: null,
    certified: true,
    tank_capacity: "",
    battery_capacity: "",
    service_type: "",
    endurance: "",
  });
  const [locations, setLocations] = useState();
  const [modelOptions, setModelOptions] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [droneUas, setDroneUas] = useState([]);
  // const [droneUinVal, setDroneUinVal] = useState(DroneUINValidation)
  const { currentHeight } = useWindowSize();
  const handleNewModelOption = (newOption) => {
    setModelOptions((prev) => ([
      ...prev,
      {
        label: newOption,
        value: newOption,
      }
    ]))
    setDroneInfo((prev) => ({
      ...prev,
      model_name: {
        label: newOption,
        value: newOption
      }
    }))
  }

  const handleNewManufacturerOption = (newOption) => {
    setManufacturerOptions((prev) => ([
      ...prev,
      {
        label: newOption,
        value: newOption,
      }
    ]))
    setDroneInfo((prev) => ({
      ...prev,
      manufacturer: {
        label: newOption,
        value: newOption
      }
    }))
  }

  const addDSPDroneFun = async (droneInfo, uasId) => {
    try {
      const drone = {
        name: droneInfo.name,
        location_id: droneInfo?.location_id?.value,
        drone_uin: droneInfo.drone_uin,
        drone_uas_id: uasId || droneInfo?.model_name?.value,
      };
      let addedDrone = await addDSPDrone(drone);
      addedDrone = addedDrone.drone[0];
      onDroneAdd({
        ...addedDrone,
        location_name: locations.find((l) => l.id === addedDrone?.location_id)?.name,
      });
      Popup.alert(
        "Success",
        "Drone added successfully",
        "SUCCESS",
        () => { }
      );
    } catch (err) {
      throw err;
    }
  };

  const addDSPDroneUASFunc = async (droneInfo) => {
    try {
      const droneUas = {
        ...droneInfo,
        model_name: droneInfo?.model_name?.label,
        manufacturer: droneInfo?.manufacturer?.value,
        service_type: droneInfo?.service_type?.value,
        tank_capacity: droneInfo?.tank_capacity?.value,
        certified: droneInfo?.certified?.value,
        location_id: droneInfo?.location_id?.value,
        type: droneInfo?.type?.value,
      };
      const addedUas = await addDSPDroneUAS(droneUas);
      return addedUas;
    } catch (err) {
      throw err;
    }
  };

  const createDSPDrone = async (droneInfo) => {
    try {
      Loading.set(true);

      var uin = droneInfo?.drone_uin ? await checkUINAlreadyExist(droneInfo.drone_uin) : { status: 404 };
      if (uin.status == 200) {
        throw ("The drone with this UIN is already added. Please add a drone with a unique UIN.")
      }
      if (uin?.status === 404) {
        if (droneInfo.model_name && droneUas.some((d) => d.id === droneInfo?.model_name?.value)) {
          await addDSPDroneFun(droneInfo);
        } else {
          const addedUas = await addDSPDroneUASFunc(droneInfo);
          await addDSPDroneFun(droneInfo, addedUas?.id);
        }
      }
    } catch (error) {
      Popup.alert(
        "Drone",
        error || "Something went wrong while adding drone",
        "ERROR",
        () => { }
      );
    } finally {
      Loading.set(false);
    }
  };


  const updateDSPDroneInfo = (droneInfo) => {
    Loading.set(true);
    updateDSPDrone({
      ...droneInfo,
      model_name: droneInfo?.model_name?.label,
      manufacturer: droneInfo?.manufacturer?.value,
      service_type: droneInfo?.service_type?.value,
      tank_capacity: droneInfo?.tank_capacity?.value,
      certified: droneInfo?.certified?.value,
      location_id: droneInfo?.location_id?.value,
    })
      .then((res) => {
        Loading.set(false);
        let updateDrone = res.updatedDrone[0];
        onDroneAdd({ ...updateDrone, location_name: locations.find((l) => l.id === updateDrone?.location_id)?.name })
        Popup.alert("Drone", "Drone updated successfully", "SUCCESS", () => { });
      })
      .catch((err) => {
        Loading.set(false);
        console.log(err);
        Popup.alert(
          "Drone",
          "Something went wrong while updating DSP drone",
          "ERROR",
          () => { }
        );
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerHeight <= 600);
    };
    window.addEventListener('resize', handleResize);

    Loading.set(true);

    const fetchData = async () => {
      try {
        const locations = await getLocations();
        setLocations(locations);

        const droneUAS = await getAllDSPDroneUAS();
        setDroneUas(droneUAS);

        const modelOptions = droneUAS.map((model) => ({
          label: model.model_name,
          value: model?.id,
        }));
        setModelOptions(modelOptions);

        const manufacturers = await getAllManufacturers();
        const manufacturerOptions = manufacturers?.map((manufacturer) => ({
          label: manufacturer.name,
          value: manufacturer.name,
        }));
        setManufacturerOptions(manufacturerOptions);

        if (droneId) {
          const droneData = await getDSPDrone(droneId);
          const drone = droneData[0];
          const {
            id,
            name,
            type,
            drone_uin,
            manufacturer,
            certified,
            tank_capacity,
            battery_capacity,
            service_type,
            model_name,
            drone_uas_id,
            endurance,
          } = drone;

          setDroneInfo((prev) => ({
            ...prev,
            id,
            model_name: {
              label: model_name,
              value: drone_uas_id,
            },
            name,
            type,
            drone_uin,
            manufacturer: {
              label: manufacturer,
              value: manufacturer,
            },
            certified: {
              label: certified ? "Yes" : "No",
              value: certified,
            },
            tank_capacity: {
              label: tank_capacity,
              value: tank_capacity,
            },
            battery_capacity,
            service_type: {
              label: service_type,
              value: service_type,
            },
            endurance,
          }));

          if (drone.location_id) {
            const locationData = await getLocation(drone.location_id);
            const droneLocation = locationData.location;
            setSelectedField(droneLocation.id);
            setDroneInfo((prev) => ({
              ...prev,
              location_id: {
                label: droneLocation.name,
                value: droneLocation.id,
              },
            }));
          }
        }

      } catch (err) {
        console.error("Error fetching data:", err);
        Popup.alert("Drone", err, "ERROR", () => { });
      } finally {
        Loading.set(false);
      }
    };

    fetchData();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [droneId]);

  useEffect(() => {
    if (selectedModel && !droneId) {
      setDroneInfo((prev) => ({
        ...prev,
        name: selectedModel.name,
        model_name: {
          label: selectedModel.model_name,
          value: selectedModel.id,
        },
        battery_capacity: selectedModel.battery_capacity,
        certified: {
          label: selectedModel.certified ? "Yes" : "No",
          value: selectedModel.certified
        },
        endurance: selectedModel.endurance,
        manufacturer: {
          label: selectedModel.manufacturer,
          value: selectedModel.manufacturer,
        },
        service_type: {
          label: selectedModel.service_type,
          value: selectedModel.service_type,
        },
        tank_capacity: {
          label: selectedModel.tank_capacity,
          value: selectedModel.tank_capacity,
        },
        type: { label: selectedModel.type, value: selectedModel.type },
      }))
    } else if (!droneId) {
      setDroneInfo((prev) => ({
        ...prev,
        type: "",
        location_id: null,
        drone_uin: "",
        manufacturer: null,
        certified: {
          label: "Yes",
          value: true,
        },
        tank_capacity: "",
        battery_capacity: "",
        service_type: "",
        endurance: "",
        model_name: "",
      }))
    }
  }, [selectedModel])

  return (
    <Modal
      header={droneId ? "Edit Drone" : "Add Drone"}
      open={open}
      onClose={onClose}
      contentStyle={{ width: "640px", height: "auto", borderRadius: "10px" }}
      headerContainerStyle={{ padding: currentHeight < 661 ? "15px 30px" : "25px 30px", }}
    >
      <Form
        onSubmitForm={(droneDetails) => {
          droneId ? updateDSPDroneInfo(droneDetails) : createDSPDrone(droneDetails);
        }}
        initialValues={droneInfo}
      >
        {(onSubmit, formValues) => (
          <div className="addDrone_form_wrapper" >
            <div className="add_drone_form" style={{ padding: currentHeight < 661 ? "5px 30px" : "10px 30px" }}>
              <CreatableSelectField
                name={"model_name"}
                label={"Model name"}
                list={modelOptions}
                isDisabled={droneId}
                styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                controlStyle={{ maxWidth: "280px" }}
                validation={selectFieldValidation}
                handleChange={(state) => {
                  if (state) {
                    const selectedModel = droneUas.filter((d) => d.id === state?.value)[0];
                    setselectedModel(selectedModel ? selectedModel : null)
                  }
                }}
                containerStyle={{
                  width: "100%",
                  maxWidth: "100%",
                  minHeight: "80px",
                }}
                onCreateOption={handleNewModelOption}
                defaultValue={droneInfo.model_name}
              />
              <CreatableSelectField
                name={"manufacturer"}
                label={"Manufacturer"}
                list={manufacturerOptions}
                styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                validation={selectFieldValidation}
                isDisabled={droneInfo?.model_name?.value && droneUas.some(d => d.id === droneInfo?.model_name?.value)}
                defaultValue={droneInfo.manufacturer}
                controlStyle={{ maxWidth: "280px" }}
                onCreateOption={handleNewManufacturerOption}
                containerStyle={{
                  width: "100%",
                  maxWidth: "100%",
                  minHeight: "80px",
                }}
              />
              {droneId ? (
                <InputField
                  defaultValue={droneInfo.type}
                  label={"Type of drone"}
                  type={"text"}
                  id={"type"}
                  disabled={droneId || (droneInfo?.model_name?.value && droneUas.some(d => d.id === droneInfo?.model_name?.value))}
                  name={"type"}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px",
                  }}
                />
              ) : (
                <SelectField
                  name={"type"}
                  label={"Type"}
                  list={DRONE_TYPES?.map((type) => ({
                    value: type,
                    label: type,
                  }))}
                  isDisabled={droneId || (droneInfo?.model_name?.value && droneUas.some(d => d.id === droneInfo?.model_name?.value))}
                  styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                  validation={selectFieldValidation}
                  defaultValue={droneInfo.type?.value}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px",
                  }}
                />
              )}
              <SelectField
                name={"service_type"}
                label={"Service type"}
                list={SERVICE_TYPES?.map((service) => ({
                  value: service,
                  label: service,
                }))}
                styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                validation={selectFieldValidation}
                isDisabled={droneId || (droneInfo?.model_name?.value && droneUas.some(d => d.id === droneInfo?.model_name?.value))}
                defaultValue={droneInfo.service_type}
                containerStyle={{
                  width: "100%",
                  maxWidth: "100%",
                  minHeight: "80px",
                }}
              />
              <InputField
                defaultValue={droneInfo.battery_capacity}
                label={"Battery capacity (mAh)"}
                type={"text"}
                id={"type"}
                placeholder={"Please Enter battery capacity"}
                name={"battery_capacity"}
                disabled={droneId || (droneInfo?.model_name?.value && droneUas.some(d => d.id === droneInfo?.model_name?.value))}
                validation={BatteryCapacityValidation}
                containerStyle={{
                  width: "100%",
                  maxWidth: "100%",
                  minHeight: "80px",
                }}
              />
              <InputField
                defaultValue={droneInfo.endurance}
                label={"Endurance (Minutes)"}
                type={"text"}
                id={"endurance"}
                placeholder={"Please Enter Endurance"}
                name={"endurance"}
                disabled={droneId || (droneInfo?.model_name?.value && droneUas.some(d => d.id === droneInfo?.model_name?.value))}
                validation={enduranceValidation}
                containerStyle={{
                  width: "100%",
                  maxWidth: "100%",
                  minHeight: "80px",
                }}
              />
              {!(formValues?.service_type?.value === "Survey") &&
                <SelectField
                  name={"tank_capacity"}
                  label={"Tank capacity"}
                  list={DRONE_CAPACITIES?.map((capacity) => ({
                    value: capacity,
                    label: capacity,
                  }))}
                  styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                  validation={selectFieldValidation}
                  isDisabled={droneId || (droneInfo?.model_name?.value && droneUas.some(d => d.id === droneInfo?.model_name?.value))}
                  defaultValue={droneInfo.tank_capacity}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px",
                  }}
                />
              }
              <SelectField
                name={"certified"}
                label={"Certified"}
                id={"certified"}
                list={[
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ]}
                styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                validation={selectFieldValidation}
                isDisabled={droneId || (droneInfo?.model_name?.value && droneUas.some(d => d.id === droneInfo?.model_name?.value))}
                defaultValue={droneInfo.certified}
                containerStyle={{
                  width: "100%",
                  maxWidth: "100%",
                  minHeight: "80px",
                }}
              // handleChange={(event) => {
              //   const value = event.value
              //   setDroneUinVal((prev) => ({
              //     ...prev,
              //     required: {
              //       value: value ? true : false,
              //       message: value ? "Required" : "",
              //     },
              //   }))
              // }}
              />
              <InputField
                defaultValue={droneInfo.name}
                label={"Name"}
                type={"text"}
                id={"name"}
                placeholder={"Please Enter drone name"}
                name={"name"}
                validation={droneNameValidation}
                containerStyle={{
                  width: "100%",
                  maxWidth: "100%",
                  minHeight: "80px",
                }}
              />
              <SelectField
                name={"location_id"}
                label={"Location"}
                list={locations?.map((location) => ({
                  value: location.id,
                  label: location.name,
                }))}
                styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                validation={selectFieldValidation}
                defaultValue={droneInfo.location_id ? droneInfo.location_id : undefined}
                containerStyle={{
                  width: "100%",
                  maxWidth: "100%",
                  minHeight: "80px",
                }}
              />
              <InputField
                defaultValue={droneInfo.drone_uin}
                label={"Drone UIN"}
                type={"text"}
                id={"drone_uin"}
                placeholder={"Please Enter drone UIN"}
                name={"drone_uin"}
                validation={DroneUINValidation}
                containerStyle={{
                  width: "100%",
                  maxWidth: "100%",
                  minHeight: "80px",
                }}
              />

            </div>
            <div
              className="buttons_wrapper"
              style={{ padding: currentHeight < 661 ? "5px 30px" : "10px 30px" }}
            >
              <Button
                className={"btn-large"}
                text={droneId ? "Update" : "Add"}
                onClick={onSubmit}
                style={{ width: "100px" }}
              />
              <Button
                className={"btn-large lg-cancel-btn "}
                text={"Cancel"}
                onClick={onClose}
                isBtnNotActive={true}
              />
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default AddDSPDrone;
