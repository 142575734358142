import { getDSPId } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getServices = (user) => {
  const getServices = SERVER_URLS.BACKEND_SERVER + "/dsp/services" ;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(getServices, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });

      let data = undefined
      if (response.ok && response.status === 200) {
        data = await response.json();
        resolve(data.services);
      } else if (response.status === 204) {
        resolve([])
      }
      else {
        reject({
          errorMessage: data?.message || "Something went wrong while getting services.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage || "Something went wrong while getting services.",
      });
    }
  });
};

export const getService = (serviceId) => {
  const getService = SERVER_URLS.BACKEND_SERVER + `/dsp/services/${serviceId}` ;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(getService, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            data?.message || "Something went wrong while geting service.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage || "Something went wrong while getting service.",
      });
    }
  });
};

export const getAeroMeghServices = (user) => {
  const getServices = SERVER_URLS.BACKEND_SERVER + "/dsp/aeromegh/services" ;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(getServices, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage: data?.message || "Something went wrong while logging.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage || "Something went wrong while logging.",
      });
    }
  });
};

export const addService = (service) => {
  const addService = SERVER_URLS.BACKEND_SERVER + "/dsp/services" ;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(addService, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          service,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            data?.message || "Something went wrong while adding service.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage || "Something went wrong while adding service.",
      });
    }
  });
};

export const updateService = (service) => {
  const updateService = SERVER_URLS.BACKEND_SERVER + "/dsp/services" ;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(updateService, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify({
          service,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            data?.message || "Something went wrong while adding service.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage || "Something went wrong while adding service.",
      });
    }
  });
};
