import React, { useEffect, useState } from "react";
import { getLocations } from "../../../apis/locations";
import { Button, CrossCloseButton } from "../../../Components/Helper";
import {
  addDronePilot,
  getDronePilot,
  updateDronePilot,
} from "../../../apis/dronePilots";
import Popup from "../../../ReusableFunctions/Popup";
import Modal from "../../../ReusableComponents/Modal";
import { v4 as uuid } from "uuid"
import Loading from "../../../ReusableFunctions/Loader";
import InputField from "../../../ReusableComponents/InputField";
import { aadharCardValidation, addressValidation, emailValidation, licenceValidation, nameValidation, panValidation, phoneNumValidation, pincodeValidation, selectFieldValidation } from "../../../utils/inputValidations";
import Form from "../../../ReusableComponents/Form";
import SelectField from "../../../ReusableComponents/SelectField";
import ToggleField from "../../../ReusableComponents/ToggleField";
import useWindowSize from "../../../hooks/useWindowSize";

const AddDronePilot = ({ open, onClose, pilotId, onPilotAdd, indianStates }) => {
  const [locations, setLocations] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState(undefined);
  const [oldPilot, setOldPilot] = useState({})
  const [cityLoading, setcityLoading] = useState(false)
  const { currentHeight, currentWidth } = useWindowSize();
  const [pilot, setPilot] = useState({
    id: pilotId ? pilotId : uuid(),
    first_name: "",
    middle_name: '',
    last_name: '',
    email: "",
    phone: "",
    first_line_address: "",
    second_line_address: '',
    state: '',
    city: '',
    pincode: '',
    aadhar_number: "",
    pan_number: "",
    licence_id: "",
    is_active: true,
    location_id: null,
    dsp_id: "",
    date_created: new Date(),
  });

  const handleAddDronePilot = (pilot) => {
    Loading.set(true)
    addDronePilot(pilot)
      .then((res) => {
        Loading.set(false)
        Popup.alert("Drone Pilot", "Pilot added successfully", "SUCCESS");
        onPilotAdd({ ...pilot, location_name: locations.find((l) => l.id === pilot?.location_id)?.name })
      })
      .catch((err) => {
        console.log(err);
        Loading.set(false)
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };

  const handleUpdateDronePilot = (pilot) => {
    Loading.set(true)
    updateDronePilot(pilot)
      .then((res) => {
        Loading.set(false)
        Popup.alert(
          "Drone Pilot",
          "Pilot updated successfully",
          "SUCCESS"
        )
        onPilotAdd({ ...pilot, location_name: locations.find((l) => l.id === pilot?.location_id)?.name })
      })
      .catch((err) => {
        console.log(err);
        Loading.set(false)
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };

  const getCities = async () => {
    try {
      const data = {
        "country": "India",
        "state": selectedState.label
      }

      let res = await fetch("https://countriesnow.space/api/v0.1/countries/state/cities", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
      let cities = await res.json()
      setCities(cities.data)
    } catch (err) {
      console.log("ERROR :", err);
    }
  }

  useEffect(() => {
    const fetchInitalData = async () => {
      Loading.set(true);
      const locations = await getLocations();
      setLocations(locations);
      if (pilotId) {
        const res = await getDronePilot(pilotId);
        const pilot = res.dronePilot
        const { id, first_name, middle_name, last_name, email, phone, first_line_address, second_line_address, state, city, pincode, aadhar_number, pan_number, licence_id, is_active, location_id, } = pilot;
        setPilot((prev) => ({
          ...prev,
          id: id,
          first_name: first_name,
          middle_name: middle_name,
          last_name: last_name,
          email: email,
          phone: phone,
          first_line_address: first_line_address,
          second_line_address: second_line_address,
          state: {
            label: state,
            value: state,
          },
          city: {
            label: city,
            value: city,
          },
          pincode: pincode,
          aadhar_number: aadhar_number,
          pan_number: pan_number,
          licence_id: licence_id,
          is_active: is_active,
          location_id: {
            value: location_id,
            label: locations.find(loc => loc.id === pilot.location_id)?.name
          }
        }));
        setOldPilot({ ...pilot })
        let filterstate = indianStates.find((st) => st.name === pilot.state);
        if (filterstate) {
          setSelectedState(() => ({
            label: filterstate.name,
            value: filterstate.state_code,
          }))
        }
      }
      Loading.set(false);
    }
    fetchInitalData().catch(err => console.log(err)).finally(() => {
      Loading.set(false);
    })
  }, [pilotId])

  useEffect(() => {
    setcityLoading(true);
    getCities().finally(() => {
      setcityLoading(false);
    })
  }, [selectedState])

  return (
    <Modal
      header={pilotId ? "Edit Pilot" : "Add Pilot"}
      open={open}
      onClose={onClose}
      contentStyle={{ width: "735px", height: "auto", borderRadius: "10px" }}
      headerContainerStyle={{ padding: currentHeight < 695 ? "20px 30px" : "25px 30px" }}
    >
      <Form
        onSubmitForm={(pilot) => {
          pilot = { ...pilot, city: pilot.city.value, state: pilot.state.label, location_id: pilot.location_id.value };
          pilotId ? handleUpdateDronePilot(pilot) : handleAddDronePilot(pilot)
        }}
        initialValues={pilot}
      >
        {(onsubmit) => (
          <div style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: currentHeight < 695 ? "2px" : "5px",
            padding: currentHeight < 695 ? "15px 20px" : "20px",

          }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <InputField
                defaultValue={pilot.first_name}
                label={"First name"}
                type={"text"}
                id={"type"}
                placeholder={"first name"}
                name={"first_name"}
                validation={nameValidation}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
              />
              <InputField
                defaultValue={pilot.middle_name}
                label={"Middle name"}
                type={"text"}
                id={"type"}
                placeholder={"middle name"}
                name={"middle_name"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={{
                  ...nameValidation,
                  required: {
                    value: false,
                    message: '',
                  },
                  validate: () => {
                    return true;
                  }
                }}
              />
              <InputField
                defaultValue={pilot.last_name}
                label={"Last Name"}
                type={"text"}
                id={"type"}
                placeholder={"last name"}
                name={"last_name"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={nameValidation}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <InputField
                defaultValue={pilot.aadhar_number}
                label={"Aadhaar No."}
                type={"text"}
                id={"type"}
                placeholder={"Pilot Aadhaar No"}
                name={"aadhar_number"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={aadharCardValidation}
              />
              <InputField
                defaultValue={pilot.pan_number}
                label={"PAN"}
                type={"text"}
                id={"type"}
                placeholder={"Pilot PAN"}
                name={"pan_number"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={panValidation}
              />
              <InputField
                defaultValue={pilot.licence_id}
                label={"License ID"}
                type={"text"}
                id={"type"}
                placeholder={"Pilot license id"}
                name={"licence_id"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={licenceValidation}
              />
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
              <InputField
                defaultValue={pilot.phone}
                label={"Phone"}
                type={"text"}
                id={"type"}
                placeholder={"Pilot phone number"}
                name={"phone"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={phoneNumValidation}
              />
              <InputField
                defaultValue={pilot.email}
                label={"Email"}
                type={"text"}
                id={"type"}
                placeholder={"Pilot email"}
                name={"email"}
                containerStyle={{
                  width: "30%",
                  maxWidth: "30%",
                  minHeight: "80px",
                }}
                validation={emailValidation}
              />
              <div style={{
                width: '30%',
                paddingTop: '4px'
              }}>
                <SelectField
                  name={"location_id"}
                  label={"Location"}
                  list={locations?.map(location => ({ value: location.id, label: location.name }))}
                  styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                  validation={selectFieldValidation}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                  defaultValue={pilot.location_id ? { value: pilot.location_id, label: locations.find(loc => loc.id === pilot.location_id)?.name } : "Select Location"}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: "48%" }}>
                <InputField
                  defaultValue={pilot.first_line_address}
                  label={"First line address"}
                  type={"text"}
                  id={"type"}
                  placeholder={"First line address"}
                  name={"first_line_address"}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                  validation={addressValidation}
                />
              </div>
              <div style={{ width: "48%" }}>
                <InputField
                  defaultValue={pilot.second_line_address}
                  label={"Second line address"}
                  type={"text"}
                  id={"type"}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                  placeholder={"Second line address"}
                  name={"second_line_address"}
                  validation={{
                    ...addressValidation,
                    required: {
                      value: false,
                      message: '',
                    }
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: "30%" }}>
                <SelectField
                  name={"state"}
                  label={"State"}
                  list={indianStates?.map(state => ({ value: state.state_code, label: state.name }))}
                  styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                  validation={selectFieldValidation}
                  handleChange={(state) => {
                    setSelectedState(state);
                  }}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                  isSearchable={true}
                  defaultValue={pilot.state}

                />
              </div>
              <div style={{ width: "30%" }}>
                <SelectField
                  name={"city"}
                  isLoading={cityLoading}
                  isDisabled={cityLoading}
                  label={"City"}
                  list={cities?.map(city => ({ value: city, label: city }))}
                  styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                  validation={selectFieldValidation}
                  defaultValue={pilot.city}
                  isSearchable={true}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                />
              </div>
              <div style={{ width: "30%" }}>
                <InputField
                  defaultValue={pilot.pincode}
                  label={"Pincode"}
                  type={"text"}
                  id={"type"}
                  placeholder={"pincode"}
                  name={"pincode"}
                  containerStyle={{
                    width: "100%",
                    maxWidth: "100%",
                    minHeight: "80px"
                  }}
                  validation={pincodeValidation}
                />
              </div>
            </div>
            <div>
              <ToggleField
                label={"Status"}
                name={"is_active"}
                onText={"Active"}
                offText={"Inactive"}
                defaultValue={pilot.is_active}
                handleOnChange={(state) => {
                  setPilot((prev) => ({
                    ...prev,
                    is_active: state
                  }))
                }}
              />

            </div>
            <div style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "20px",
              padding: currentHeight < 695 ? "10px" : "20px",
              height: "70px"
            }}>
              <Button
                text={pilotId ? "Update" : "Add"}
                onClick={onsubmit}
                className={"btn-large"}
              />
              <Button
                className={"btn-large lg-cancel-btn"}
                text={"Cancel"}
                onClick={onClose}
                isBtnNotActive={true}
              />
            </div>
          </div>
        )}
      </Form>
    </Modal >
  );
};

export default AddDronePilot;
