import React, { useEffect, useState } from "react";
import Modal from "../../../ReusableComponents/Modal";
import Loading from "../../../ReusableFunctions/Loader";
import Popup from "../../../ReusableFunctions/Popup";
import { Button } from "../../../Components/Helper";
import {
    addDSPDroneUAS,
    getAllManufacturers,
    getDSPDroneUAS,
    updateDSPDroneUAS,
} from "../../../apis/drones";
import {
    DRONE_CAPACITIES,
    DRONE_TYPES,
    SERVICE_TYPES,
} from "../../../appConstants";
import Form from "../../../ReusableComponents/Form";
import InputField from "../../../ReusableComponents/InputField";
import SelectField from "../../../ReusableComponents/SelectField";
import { BatteryCapacityValidation, droneNameValidation, enduranceValidation, selectFieldValidation } from "../../../utils/inputValidations";
import CreatableSelectField from "../../../ReusableComponents/CreatableSelectField";

const AddDSPDroneModel = ({ open, onClose, modelId, onModelAdd }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerHeight <= 600);
    const [modelInfo, setModelInfo] = useState({
        type: "",
        model_name: null,
        manufacturer: null,
        certified: {
            label: "Yes",
            value: true,
        },
        tank_capacity: "",
        battery_capacity: "",
        service_type: "",
        endurance: "",
    });
    const [manufacturerOptions, setManufacturerOptions] = useState([]);
    const [droneUas, setDroneUas] = useState([]);

    const handleNewManufacturerOption = (newOption) => {
        setManufacturerOptions((prev) => ([
            ...prev,
            {
                label: newOption,
                value: newOption,
            }
        ]))
        setModelInfo((prev) => ({
            ...prev,
            manufacturer: {
                label: newOption,
                value: newOption
            }
        }))
    }


    const createDSPModel = (modelInfo) => {
        Loading.set(true);
        addDSPDroneUAS(modelInfo)
            .then((res) => {
                let addedModel = res;
                onModelAdd(addedModel);
                Popup.alert(
                    "Success",
                    "Model added successfully",
                    "SUCCESS",
                    () => { }
                );
            })
            .catch((err) => {
                Loading.set(false);
                console.log(err);
                Popup.alert(
                    "Model",
                    "Something went wrong while creating DSP Model",
                    "ERROR",
                    () => { }
                );
            }).finally(() => {
                Loading.set(false);
            });

    };
    const updateDSPModelInfo = (modelId, modelInfo) => {
        Loading.set(true);
        updateDSPDroneUAS(modelId, modelInfo)
            .then((res) => {
                Loading.set(false);
                let updatedModel = res.drone_uas
                onModelAdd(updatedModel)
                Popup.alert("Model", "Model updated successfully", "SUCCESS", () => { });
            })
            .catch((err) => {
                Loading.set(false);
                console.log(err);
                Popup.alert(
                    "Model",
                    "Something went wrong while updating DSP Model",
                    "ERROR",
                    () => { }
                );
            });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerHeight <= 600);
        };

        window.addEventListener('resize', handleResize);
        Loading.set(true);
        getAllManufacturers().then((res) => {
            const manufacturerOptions = res?.map((manufacturer) => ({
                label: manufacturer.name,
                value: manufacturer.name,
            }))
            setManufacturerOptions(() => manufacturerOptions)
            if (modelId) {
                getDSPDroneUAS(modelId)
                    .then((res) => {
                        const model = res;
                        const {
                            id,
                            type,
                            manufacturer,
                            certified,
                            tank_capacity,
                            battery_capacity,
                            service_type,
                            model_name,
                            endurance,
                        } = model;

                        setModelInfo((prev) => ({
                            ...prev,
                            id: id,
                            model_name: model_name,
                            type: {
                                label: type,
                                value: type,
                            },
                            manufacturer: {
                                label: manufacturer,
                                value: manufacturer,
                            },
                            certified: {
                                label: certified ? "Yes" : "No",
                                value: certified,
                            },
                            tank_capacity: {
                                label: tank_capacity,
                                value: tank_capacity,
                            },
                            battery_capacity: battery_capacity,
                            service_type: {
                                label: service_type,
                                value: service_type,
                            },
                            endurance: endurance,
                        }));
                    })
                    .catch((e) => {
                        console.log(e);
                        Popup.alert(
                            "Model",
                            "Something went wrong while updating DSP model",
                            "ERROR",
                            () => { }
                        );
                    });
            }
        }).catch((err) => {
            console.log("error", err);
        }).finally(() => {
            Loading.set(false);
        })

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [modelId]);

    console.log("modelInfo", modelInfo);
    return (
        <Modal
            header={modelId ? "Edit Model" : "Add Model"}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "640px", height: "auto", borderRadius: "10px" }}
        >
            <Form
                onSubmitForm={(model) => {
                    let submitModelInfo =
                    {
                        ...model,
                        certified: model.certified.value,
                        manufacturer: model.manufacturer.value,
                        service_type: model.service_type.value,
                        tank_capacity: model.tank_capacity.value,
                        type: model.type.value,
                    }

                    modelId ? updateDSPModelInfo(modelId, submitModelInfo) : createDSPModel(submitModelInfo)
                }}
                initialValues={modelInfo}

            >{(onsubmit, formValues) => (
                <div className="addDrone_form_wrapper">
                    <div className="add_drone_form" >
                        <InputField
                            defaultValue={modelInfo.model_name}
                            label={"Model Name"}
                            type={"text"}
                            id={"model_name"}
                            placeholder={"Enter model name "}
                            name={"model_name"}
                            validation={droneNameValidation}
                            containerStyle={{
                                width: "100%",
                                maxWidth: "100%",
                                minHeight: "80px",
                            }}
                        />
                        <CreatableSelectField
                            name={"manufacturer"}
                            label={"Manufacturer"}
                            list={manufacturerOptions}
                            styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                            validation={selectFieldValidation}
                            onCreateOption={handleNewManufacturerOption}
                            defaultValue={modelInfo.manufacturer}
                            controlStyle={{ width: "280px", overflowWrap: "break-word", height: isSmallScreen ? "30px" : "35px", minHeight: isSmallScreen ? "30px" : "35px" }}
                            containerStyle={{
                                width: "100%",
                                maxWidth: "100%",
                                minHeight: "80px",
                            }}
                        />
                        <SelectField
                            name={"type"}
                            label={"Type"}
                            list={DRONE_TYPES?.map((type) => ({
                                value: type,
                                label: type,
                            }))}
                            isDisabled={modelInfo?.model_name?.value && droneUas.some(d => d.id === modelInfo?.model_name?.value)}
                            styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                            validation={selectFieldValidation}
                            defaultValue={modelInfo.type.label}
                            controlStyle={{ height: isSmallScreen ? "30px" : "35px", minHeight: isSmallScreen ? "30px" : "35px" }}
                            containerStyle={{
                                width: "100%",
                                maxWidth: "100%",
                                minHeight: "80px",
                            }}
                        />
                        <SelectField
                            name={"service_type"}
                            label={"Service type"}
                            list={SERVICE_TYPES?.map((service) => ({
                                value: service,
                                label: service,
                            }))}
                            styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                            validation={selectFieldValidation}
                            defaultValue={modelInfo.service_type}
                            containerStyle={{
                                width: "100%",
                                maxWidth: "100%",
                                minHeight: "80px",
                            }}
                        />
                        <InputField
                            defaultValue={modelInfo.battery_capacity}
                            label={"Battery capacity (mAh)"}
                            type={"text"}
                            id={"type"}
                            placeholder={"Please Enter battery capacity"}
                            name={"battery_capacity"}
                            validation={BatteryCapacityValidation}
                            containerStyle={{
                                width: "100%",
                                maxWidth: "100%",
                                minHeight: "80px",
                            }}
                        />
                        <InputField
                            defaultValue={modelInfo.endurance}
                            label={"Endurance (Minutes)"}
                            type={"text"}
                            id={"endurance"}
                            placeholder={"Please Enter Endurance"}
                            name={"endurance"}
                            validation={enduranceValidation}
                            containerStyle={{
                                width: "100%",
                                maxWidth: "100%",
                                minHeight: "80px",
                            }}
                        />
                        {!(formValues?.service_type?.value === "Survey") &&
                            <SelectField
                                name={"tank_capacity"}
                                label={"Tank capacity"}
                                list={DRONE_CAPACITIES?.map((capacity) => ({
                                    value: capacity,
                                    label: capacity,
                                }))}
                                styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                                validation={selectFieldValidation}
                                defaultValue={modelInfo.tank_capacity}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                            />
                        }
                        <SelectField
                            name={"certified"}
                            label={"Certified"}
                            id={"certified"}
                            list={[
                                { value: true, label: "Yes" },
                                { value: false, label: "No" },
                            ]}
                            styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                            validation={selectFieldValidation}
                            defaultValue={modelInfo.certified ? { label: modelInfo.certified ? "Yes" : "No", value: modelInfo.certified.value } : undefined}
                            containerStyle={{
                                width: "100%",
                                maxWidth: "100%",
                                minHeight: "80px",
                            }}
                        />
                    </div>
                    <div
                        className="buttons_wrapper"
                    >
                        <Button
                            text={modelId ? "Update" : "Add"}
                            onClick={onsubmit}
                            className={"btn-large"}
                        />
                        <Button
                            className={"btn-large lg-cancel-btn "}
                            text={"Cancel"}
                            onClick={onClose}
                            isBtnNotActive={true}
                        />
                    </div>
                </div>
            )}

            </Form>

        </Modal>
    );
};

export default AddDSPDroneModel;
