import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";


export const getAllManufacturers = (pageSize, pageNumber) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/dsp/manufacturer?pageSize=${pageSize}&pageNumber=${pageNumber}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting manufacturers."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting manufacturers.");
        }
    });
}


export const addManufacturer=(manufacturerInfo)=>{
   return new Promise (async(resolve,reject)=>{
    try{
       const token =getToken();
       const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/manufacturer`,
        {
            method: "POST",
            body:JSON.stringify(manufacturerInfo),
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    );
    const data = await response.json();
    if (response.ok) {
        resolve(data);
    } else {
        reject(
            data?.message || "Something went wrong while add manufacturer."
        );
    }
    }catch(err){
        reject("Something went wrong while add manufacturer.");
    }
   })
}


export const deletedManufacturer=(id)=>{
    return new Promise(async(resolve ,reject)=>{
      try{
        const token =getToken();
       const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/manufacturer/${id}`,
        {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    );
    const data = await response.json();
    if (response.ok) {
        resolve(data);
    } else {
        reject(
            data?.message || "Something went wrong while delete manufacturer."
        );
    }
      }catch(err){
        reject("Something went wrong while delete manufacturer.");
      }
    })
}

export const getManufacturer=(id)=>{
    return new Promise(async(resolve,reject)=>{
        try{
            const token =getToken();
            const response = await fetch(
             SERVER_URLS.BACKEND_SERVER + `/dsp/manufacturer/${id}`,
             {
                 method: "GET",
                 headers: {
                     Authorization: `Bearer ${token}`,
                 },
             }
         );
         const data = await response.json();
         if (response.ok) {
             resolve(data[0]);
         } else {
             reject(
                 data?.message || "Something went wrong while getting manufacturer."
             );
         }
        }catch(err){
            reject("Something went wrong while getting manufacturer.");
        }
    })
}


export const updateManufacturer=(id,manufacturerInfo)=>{
    return new Promise(async(resolve,reject)=>{
        try{
            const token =getToken();
            const response = await fetch(
             SERVER_URLS.BACKEND_SERVER + `/dsp/manufacturer/${id}`,
             {
                 method: "PUT",
                 body:JSON.stringify(manufacturerInfo),
                 headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "application/json", 
                 },
             }
         );
         const data = await response.json();
         if (response.ok) {
             resolve(data);
         } else {
             reject(
                 data?.message || "Something went wrong while update manufacturer."
             );
         }
        }catch(err){
            reject("Something went wrong while update manufacturer.");
        }
    })
}