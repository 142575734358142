import React from 'react'
import ReactLoading from "react-loading";
import { GLOBAL_STYLES } from '../appConstants';

const LoaderComponent = ({ height, width, left, top, customClassName }) => {
    return (
        <div
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                left: left || 0,
                top: top || 0,
                zIndex: 95,
            }}
        >
            <ReactLoading
                className={customClassName || "loading"}
                type={"spin"}
                color={GLOBAL_STYLES.BG_ACTIVE}
                height={height || "5%"}
                width={width || "5%"}
            />
        </div>
    )
}

export default LoaderComponent