import React from 'react'
import { Button, CrossCloseButton } from '../../../Components/Helper'
import MailIcon from "../../../assets/mail icon.svg";
import Modal from '../../../ReusableComponents/Modal';
import { forgetPasswordAPI } from '../../../apis/authentication';
import Popup from '../../../ReusableFunctions/Popup';
import Loading from '../../../ReusableFunctions/Loader';
import Form from '../../../ReusableComponents/Form';
import InputField from '../../../ReusableComponents/InputField';
import { emailValidation } from '../../../utils/inputValidations';

const ForgotPassword = ({ open, onClose, setOpenChangePassPopup }) => {
    const handleResetPassword = async (email) => {
        Loading.set(true)
        try {
            await forgetPasswordAPI(email)
                .then((res) => {
                    Loading.set(false);
                    Popup.alert("Forget Password", "Please check your email for the password reset OTP.", "SUCCESS", () => {
                        setOpenChangePassPopup(true,email)
                    });
                })
                .catch((e) => {
                    Loading.set(false);
                    console.log(e);
                    Popup.alert("Forget Password", e, "ERROR", () => { });
                })
        } catch (e) {
            Loading.set(false)
            console.log(e.errorMessage);
            Popup.alert("Forget Password", e.errorMessage, "ERROR", () => { });
        }
    }

    return (
        <>
            {open &&
                <Modal contentStyle={{ width: "400px", height: "300px" }} open={open} onClose={onClose}>
                    <Form
                        onSubmitForm={(data) => {
                            console.log('data:', data)
                            handleResetPassword(data.email)
                        }}
                        style={{ padding: "30px" }}
                    >
                        {(onSubmit) =>
                            <>
                                <CrossCloseButton
                                    style={{ right: "10px", top: "10px", position: "absolute" }}
                                    onClick={onClose}
                                />
                                <div style={{
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    color: "#252525",
                                    marginBottom: "15px"
                                }}>
                                    Forgot Password
                                </div>
                                <div style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    textAlign: "left",
                                    color: "#727272",
                                    marginBottom: "30px"
                                }}>
                                    Please enter your email address. We will send an OTP to this email.
                                </div>
                                <InputField
                                    icon={MailIcon}
                                    name={'email'}
                                    placeholder={"Email Address"}
                                    validation={emailValidation}
                                    containerStyle={{
                                        marginBottom: "55px"
                                    }}
                                />
                                <Button
                                    onClick={onSubmit}
                                    style={{
                                        marginTop: "15px",
                                        width: "100%",
                                        borderRadius: "2px",
                                        border: "1px",
                                        color: "white",
                                        fontSize: "13px",
                                        fontWeight: "500",
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "17px 0px",
                                    }}
                                    text={"Reset password"}
                                    type="submit"
                                />
                            </>
                        }
                    </Form>
                </Modal>
            }
        </>
    )
}

export default ForgotPassword