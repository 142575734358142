import { getDSPId } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const sendVerificationOTP = (otpCarrier) => {
  const sendVerificationOTP =
    SERVER_URLS.BACKEND_SERVER + "/dsp/sendVerificationOTP" ;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(sendVerificationOTP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(otpCarrier),
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            data?.message || "Something went wrong while sending OTP.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage || "Something went wrong while sending OTP.",
      });
    }
  });
};

export const verifyOTP = (otpCarrier) => {
  const verifyOTP = SERVER_URLS.BACKEND_SERVER + "/dsp/verifyVerificationOTP" ;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(verifyOTP, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(otpCarrier),
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject({
          errorMessage:
            data?.message || "Something went wrong while verifying OTP.",
        });
      }
    } catch (error) {
      reject({
        errorMessage:
          error.errorMessage || "Something went wrong while verifying OTP.",
      });
    }
  });
};
