import { getDSPId } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getLocations = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/locations",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data.locations);
      } else {
        reject(
          data?.message || "Something went wrong while getting locations."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting locations.");
    }
  });
};

export const addLocation = (location) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/locations",
        {
          method: "POST",
          body: JSON.stringify({ location }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        resolve(data);
      }
      else if (response.status === 409) {
        reject(data?.message || "Location already added.")
      }
      else {
        reject(data?.message || "Something went wrong while adding location.");
      }
    } catch (error) {
      reject("Something went wrong while adding location.");
    }
  });
};

export const updateLocation = (location) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/locations",
        {
          method: "PUT",
          body: JSON.stringify({ location }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        resolve(data);
      }
      else if (response.status === 409) {
        reject(
          data?.message || "The location your are trying to update is already added to your locations."
        );
      }
      else {
        reject(
          data?.message || "Something went wrong while updating location."
        );
      }
    } catch (error) {
      reject("Something went wrong while updating location.");
    }
  });
};

export const getLocation = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/locations/" + id,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while getting member location."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting member location.");
    }
  });
};

