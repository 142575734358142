import React, { useState } from 'react'
import { isEmailValid } from '../../../../../ReusableFunctions/reusableFunctions'
import { Button, CrossCloseButton } from '../../../../../Components/Helper'
import Modal from '../../../../../ReusableComponents/Modal'
import Input from '../../../../../ReusableComponents/Input'
import Popup from "../../../../../ReusableFunctions/Popup";
import { shareAppLinkAPI } from '../../../../../apis/users'


const ShareAppLinkPopup = ({ open, onClose }) => {
    const [emails, setEmails] = useState([])
    const [email, setEmail] = useState('')
    const [error, setError] = useState(null)
    const [isSharing, setIsSharing] = useState(false)


    const shareAppLink = async (e) => {
        e.preventDefault()
        try {
            if (emails.length > 0) {
                setIsSharing(true)
                await shareAppLinkAPI(emails)
                Popup.alert("App link / DSP QR shared", "App link / DSP QR shared successfully on given emails.", "SUCCESS", () => { }, null, "Ok")
                closePopup()
            }
        } catch (e) {
            setError(e.message)
        }
    }

    const handleKeyDown = (event) => {
        setError("")
        if (event.key === 'Enter') {
            event.preventDefault();
            if (isEmailValid(email)) {
                if (!(emails.some(e => { return e.toLowerCase() == email.toLowerCase() }))) {
                    setEmails([...emails, email])
                    setEmail("")
                }
                else { setError("Email already exist") }
            } else {
                setError("Invalid email")
            }
        }
    };

    const removeEmail = (email) => {
        setEmails(emails.filter(eml => email !== eml))
    }

    const closePopup = () => {
        setEmails([])
        setEmail("")
        setError("")
        onClose()
        setIsSharing(false)
    }



    return (
        <Modal
            header={'Share app link'}
            open={open}
            onClose={closePopup}
            contentStyle={{ width: "450px", height: "410px", top: "-25px", left: "-28px" }}
            headingStyle={{ fontSize: "18px", fontWeight: "500", color: "#3c3c3c" }}
        >
            <form style={{ padding: "10px 30px 0px", display: "flex", flexDirection: "column", gap: "5px" }}>
                <div>
                    <div style={{ lineHeight: "30px", fontSize: "15px", fontWeight: 500, color: "#3C3C3C" }}>Email</div>
                    <Input
                        name='email'
                        onChange={(e) => {
                            let value = e.target.value
                            setEmail(value)
                            if (isEmailValid(value) || value == "") setError("")
                            else setError("Invalid email")
                        }}
                        value={email}
                        type="email"
                        onKeyDown={handleKeyDown}
                        style={{ height: "35px", }}
                    />

                </div>
                <div style={{ fontSize: '13px', padding: "10px 0", fontWeight: '500', color: "#3c3c3c" }}>Link / DSP QR will be shared to following emails</div>


                <div style={{
                    width: "100%",
                    height: "180px",
                    overflow: "auto",
                }}>

                    {emails.length > 0 ? emails.map(email =>
                        <div style={{ display: "flex", justifyContent: "space-between", gap: '5px', alignItems: 'center', padding: "0 10px" }}>
                            <div style={{
                                fontSize: "14px",
                                color: "#3c3c3c",
                                fontWeight: "500",
                                opacity: "0.9",
                            }}> {email} </div>
                            <div>
                                <CrossCloseButton onClick={() => removeEmail(email)} width="7px" />
                            </div>
                        </div>
                    ) : <div style={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ fontSize: '15px', fontWeight: '500', color: "#3c3c3c9c" }}> There are no emails added in the list. </div>
                    </div>
                    }
                </div>

                <div style={{
                    position: "absolute",
                    bottom: "0px",
                    padding: "20px",
                    width: "100%",
                    display: "flex",
                    left: "0px",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}>
                    <div style={{ color: "red", fontSize: "12px", paddingTop: "3px", marginLeft: '5px' }}>{error}</div>
                    <div style={{ display: "flex", flexDirection: "row-reverse", gap: "15px", }}>
                        <Button
                            isDisabled={emails.length === 0 || isSharing}
                            onClick={shareAppLink}
                            text={isSharing ? "Sharing" : "Share"}
                            style={{ height: "30px", width: "100px" }}
                        />
                        <Button
                            type={"button"}
                            isDisabled={isSharing}
                            onClick={closePopup}
                            text={"Cancel"}
                            isBtnNotActive={true}
                            style={{ height: "30px", width: "100px" }}
                        />
                    </div>

                </div>
            </form>
        </Modal>
    )
}

export default ShareAppLinkPopup