import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

const Form = ({ children, onSubmitForm, initialValues, style }) => {
    const methods = useForm({
        defaultValues: initialValues,
        mode: "onChange"
    });

    const onSubmit = methods.handleSubmit(data => {
        onSubmitForm(data);
    });

    useEffect(() => {
        methods.reset(initialValues);
    }, [initialValues]);

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    onSubmit();
                }}
                noValidate
                autoComplete="off"
                style={style}
            >
                {children(onSubmit, methods.getValues())}
            </form>
        </FormProvider>
    );
};

export default Form;