import React, { useEffect, useState } from "react";
import Input from "../../../../../ReusableComponents/Input";
import { Button } from "../../../../../Components/Helper";
import { getLocations } from "../../../../../apis/locations";
import { addService, updateService } from "../../../../../apis/services";
import { GLOBAL_STYLES } from "../../../../../appConstants";
import Cross from "../../../../../assets/cross.svg";
import Popup from "../../../../../ReusableFunctions/Popup";
import Loading from "../../../../../ReusableFunctions/Loader";
import { ROUTES } from "../../../../../routes";
import { useNavigate } from "react-router-dom"
const AddInsuranceService = ({ aeroMeghService, service }) => {
  const navigate = useNavigate()
  const isServiceToBeUpdated = service.aeromegh_service_id; //if service has aeromegh_service_id this means it is to be updated else new service
  const [insurance_service, setInsuranceService] = useState(
    isServiceToBeUpdated
      ? service
      : {
        name: aeroMeghService.name,

        description: aeroMeghService.description,
        is_active: true,
        start_date: new Date(),
        aeromegh_service_id: aeroMeghService.id,
        types: [],
        locations: [],
      }
  );
  const [types, setTypes] = useState([]);
  const [type, setType] = useState({
    name: "",
    cost: "",
    insurance_company_name: "",
    is_active: false,
  });
  const [locations, setLocations] = useState([]);
  const handleOnChangeType = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setType((prev) => ({ ...prev, [name]: value }));
  };
  const handleAddService = () => {
    Loading.set(true);
    addService(insurance_service)
      .then((res) => {
        Loading.set(false);
        Popup.alert(
          "insurance Service",
          "Service added successfully",
          "SUCCESS",
          () => {
            navigate("/")
          }
        );
      })
      .catch((err) => {
        Loading.set(false);
        console.log(err);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };
  const handleUpdateService = () => {
    Loading.set(true);
    updateService(insurance_service)
      .then((res) => {
        Loading.set(false);

        Popup.alert(
          "insurance Service",
          "Service updated successfully",
          "SUCCESS",
          () => {
            navigate(ROUTES.MYSERVICES)
          }
        );
      })
      .catch((err) => {
        Loading.set(false);
        console.log(err);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };
  const handleOnChangeLocation = (e, location_id) => {
    if (e.target.checked) {
      //location is being added
      const addedLocation = locations.find((loc) => loc.id === location_id);
      setInsuranceService((prev) => ({
        ...prev,
        locations: [...prev.locations, addedLocation],
      }));
    } else {
      setInsuranceService((prev) => ({
        ...prev,
        locations: prev.locations.filter((loc) => loc.id !== location_id),
      }));
    }
  };
  useEffect(() => {
    getLocations()
      .then((locations) => {
        setLocations(locations);
        Loading.set(false);
      })
      .catch((err) => {
        console.log(err);
        Popup.alert("Error", err.errorMessage, "ERROR");
        Loading.set(false);
      });
    if (isServiceToBeUpdated) {
      setTypes(service.types);
    }
  }, []);
  useEffect(() => {
    if (service.aeromegh_service_id) {
      setInsuranceService(service);
      setTypes(service.types);
    }
  }, [service]);
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // if (isAddServiceFormFilled()) {
          if (isServiceToBeUpdated) handleUpdateService();
          else handleAddService();
          // }
        }}
        style={{
          width: "100%",
          height: "100%",
          padding: "50px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h1>Insurance Service</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              rowGap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <select
                value={insurance_service.is_active}
                onChange={(e) => {
                  setInsuranceService((prev) => ({
                    ...prev,
                    is_active: e.target.value === "true" ? true : false,
                  }));
                }}
              >
                <option value={true}>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </div>
            <div
              style={{
                display: "grid",
                gap: "10px",
                gridTemplateColumns: "1fr 1fr",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <span>Types</span>
                <div
                  style={{
                    border: "1px solid black",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ height: "50%" }}>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        columnGap: "50px",
                        justifyItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <Input
                          maxLength={10}
                          value={type.name}
                          name="name"
                          placeholder={"Name"}
                          onChange={handleOnChangeType}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <select
                          name="is_active"
                          style={{ width: "100%" }}
                          value={type.is_active}
                          onChange={(e) =>
                            setType((prev) => ({
                              ...prev,
                              is_active:
                                e.target.value === "true" ? true : false,
                            }))
                          }
                        >
                          <option name="is_active" value={true}>
                            Active
                          </option>
                          <option name="is_active" value={false}>
                            Inactive
                          </option>
                        </select>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <Input
                          type={"number"}
                          value={type.cost}
                          name="cost"
                          placeholder={"Cost"}
                          onChange={handleOnChangeType}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                        }}
                      >
                        <Input
                          value={type.insurance_company_name}
                          name="insurance_company_name"
                          placeholder={"Insurance company name"}
                          onChange={handleOnChangeType}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        margin: "10px",
                      }}
                    >
                      <Button
                        text={isServiceToBeUpdated ? "Update" : "Add"}
                        onClick={() => {
                          if (isServiceToBeUpdated) {
                            const newTypes = types.map((t, i) => {
                              if (t.id === type.id) return type;
                              else return t;
                            });
                            setTypes(newTypes);
                            setInsuranceService((prev) => ({
                              ...prev,
                              types: newTypes,
                            }));
                          } else {
                            setTypes((prev) => [...prev, type]);
                            setType({
                              name: "",
                              cost: "",
                              duration: "",
                              is_active: false,
                            });
                            setInsuranceService((prev) => ({
                              ...prev,
                              types: [...types, type],
                            }));
                          }
                        }}
                      />
                    </div>
                  </div>
                  {!isServiceToBeUpdated && (
                    <div
                      style={{
                        border: "1px solid black",
                        margin: "10px",
                        height: "50%",
                        overflowY: "auto",
                        flexWrap: "wrap",
                        display: "flex",
                        alignItems: "start",
                      }}
                    >
                      {types.map((type) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            background: GLOBAL_STYLES.BG_ACTIVE,
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            width: "fit-content",
                          }}
                        >
                          <span>{type.name}</span>
                          <img
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setTypes((prev) =>
                                prev.filter((t) => t.name !== type.name)
                              );
                            }}
                            src={Cross}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  rowGap: "0px",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>Locations</span>
                  {/* <Button text={"Add"} /> */}
                </div>
                <div
                  style={{
                    overflowY: "auto",
                    display: "flex",
                    alignItems: "flex-start",
                    flexWrap: "wrap",
                    gap: "10px",
                    height: "100%",
                    border: "1px solid black",
                    padding: "20px",
                  }}
                >
                  {locations.map((location) => (
                    <div
                      key={location.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid black",
                        padding: "5px 10px",
                      }}
                    >
                      <label>{location.name}</label>{" "}
                      <input
                        type="checkbox"
                        checked={insurance_service.locations.find(
                          (loc) => loc.id === location.id
                        )}
                        onChange={(e) => handleOnChangeLocation(e, location.id)}
                      />
                    </div>
                  ))}
                </div>
              </div>
              {isServiceToBeUpdated && (
                <div
                  style={{
                    overflowY: "auto",
                    height: "100%",
                    border: "1px solid black",
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    padding: "20px",
                  }}
                >
                  {types.map((type, i) => (
                    <Button
                      key={i}
                      text={type.name}
                      style={{ textAlign: "center" }}
                      onClick={() => setType(type)}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            margin: "20px 0px",
          }}
        >
          {isServiceToBeUpdated ? (
            <Button text={"Update"}
            />
          ) : (
            <Button text={"Save"}
            />
          )}
        </div>
      </form>
    </>
  );
};

export default AddInsuranceService;
