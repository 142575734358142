import React from 'react'
import Modal from '../../../../../ReusableComponents/Modal'
import Loading from '../../../../../ReusableFunctions/Loader'
import { updateUserData } from '../../../../../apis/users'
import Popup from '../../../../../ReusableFunctions/Popup'
import { Button } from '../../../../../Components/Helper'
import InputField from '../../../../../ReusableComponents/InputField'
import { firstLastNameValidation} from '../../../../../utils/inputValidations'
import Form from '../../../../../ReusableComponents/Form'

const EditName = ({ user, open, onClose, onUserUpdate }) => {
    const updateProfileInfo = async (name) => {
        Loading.set(true);
        try {
            await updateUserData({ ...user, name: name })
                .then((res) => {
                    console.log("res:", res)
                    onUserUpdate({ ...user, name })
                    Loading.set(false);
                    Popup.alert(
                        "Profile",
                        <p>Congratulations ! <br />
                            We are delighted to inform you that your name has been changed successfully!</p>,
                        "SUCCESS",
                        () => { }
                    );
                })
                .catch((e) => {
                    Loading.set(false);
                    console.log(e)
                    Popup.alert(
                        "Profile",
                        e,
                        "ERROR",
                        () => { }
                    );
                })
        } catch (e) {
            Loading(false)
            console.log(e)
            Popup.alert(
                "Profile",
                "Something went wrong while updating profile",
                "ERROR",
                () => { }
            );
        }
    }
    return (
        <Modal
            header={'Edit Name'}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "534px", height: "auto" }}
            divider={true}
            headingStyle={{ fontSize: "18px" }}
            iconSize={"18px"}
            headerContainerStyle={{ padding: '20px 40px' }}
        >
            <Form
                onSubmitForm={(data) => {
                    console.log("data:", data)
                    updateProfileInfo(data.firstname + " " + data.lastname);
                }}
                initialValues={user}
            >
                {(onSubmit) =>
                    <>
                        <div style={{ padding: "15px 46px" }}>
                            <p style={{ paddingBottom: "17px", fontSize: '16px', color: '#1e1e1e', fontWeight: 500 }}>
                                Enter your name and it will appear on your profile
                            </p>
                            <div style={{ display: 'flex', flexDirection: "column", gap: "0.5rem" }}>
                                <InputField
                                    label={"First Name"}
                                    name={"firstname"}
                                    placeholder={"Enter first name"}
                                    required={true}
                                    style={{ height: '51px', borderRadius: "8px", padding: '2px 15px' }}
                                    inputStyle={{ fontSize: "16px" }}
                                    labelStyle={{ paddingBottom: "7px", fontSize: '16px', color: '#3b3b3b', fontWeight: "400" }}
                                    validation={firstLastNameValidation}
                                    defaultValue={user?.name?.split(" ")[0]}
                                />
                                <InputField
                                    label={"Last Name"}
                                    name={"lastname"}
                                    placeholder={"Enter last name"}
                                    required={true}
                                    style={{ height: '51px', borderRadius: "8px", padding: '2px 15px' }}
                                    inputStyle={{ fontSize: "16px" }}
                                    labelStyle={{ paddingBottom: "7px", fontSize: '16px', color: '#3b3b3b', fontWeight: "400" }}
                                    validation={firstLastNameValidation}
                                    defaultValue={user?.name?.split(" ")[1]}
                                />
                            </div>

                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                padding: "0px 48px 22px 122px"
                            }}
                        >
                            <Button
                                text={"Update"}
                                onClick={() => {
                                    onSubmit()
                                }}
                                style={{ width: "141px", height: "47px", fontSize: '16px' }}
                            />
                        </div>
                    </>
                }
            </Form>
        </Modal >
    )
}

export default EditName