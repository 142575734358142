import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import Popup from '../../ReusableFunctions/Popup'
import Loading from '../../ReusableFunctions/Loader'
import { getAllRequests, getAllRequestsCount } from '../../apis/adminAPIs/requests'
import Pagination from '../../ReusableComponents/Pagination'
import LoaderComponent from '../../ReusableComponents/LoaderComponent'
import ViewRequest from './SubComponents/ViewRequest'
import { Table, TableHeader } from '../../ReusableComponents/Table'

const PAGE_SIZE = 15

const Requests = () => {
    const [loading, setLoading] = useState()
    const [requests, setRequests] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [requestsCount, setRequestsCount] = useState(0)
    const [selectedRequest, setSelectedRequest] = useState()
    let srNo = (currentPage - 1) * PAGE_SIZE
    const getAllRequestsFunc = async (page) => {
        try {
            const requests = await getAllRequests(PAGE_SIZE, page)
            setRequests(requests)
            Loading.set(false)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            Loading.set(false)
            Popup.alert("Error", err, "ERROR")
        }
    }
    const getAllRequestsCountFunc = async () => {
        try {
            const requestsCount = await getAllRequestsCount()
            setRequestsCount(Object.values(requestsCount).reduce((acc, curr) => acc + Number(curr), 0))
            getAllRequestsFunc(currentPage)
        } catch (err) {
            Loading.set(false)
            Popup.alert("Error", err, "ERROR")
        }
    }
    useEffect(() => {
        Loading.set(true)
        getAllRequestsCountFunc()

    }, [])
    return (
        <MainContentWrapper leftContent={"Requests"}>
            {selectedRequest && <ViewRequest open={selectedRequest} onClose={() => {
                setSelectedRequest()
                localStorage.setItem('dsp_id', "admin")
            }}
                request={selectedRequest}
                callback={() => { }}
            />}
            {loading ?
                <div style={{ height: "100%" }}>
                    <LoaderComponent height={'2%'} width={'2%'} />
                </div>
                :
                <>
                    <Table style={{ overflow: "hidden", height: "calc(100% - 75px)" }}>
                        <col style={{ width: "10%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "15%" }} />
                        <col style={{ width: "30%" }} />
                        <col style={{ width: "25%" }} />
                        <TableHeader>
                            <th style={{ textAlign: "center" }}>Sr No.</th>
                            <th>Type</th>
                            <th>Request Number</th>
                            <th>Status</th>
                            <th>User Name</th>
                            <th>Phone number</th>
                        </TableHeader>
                        {requests.map((reqest, i) => (
                            // <Link to={"/"}>
                            <tr
                                className='table-row-hover'
                                key={i} onClick={(e) => {
                                    setSelectedRequest(reqest)
                                }}
                            >
                                <td style={{ textAlign: "center" }}>{++srNo}</td>
                                <td>{reqest.type || '-'}</td>
                                <td>{reqest.req_number || '-'}</td>
                                <td>{reqest.status || "-"}</td>
                                <td>{reqest.user_name || "-"}</td>
                                <td>{reqest.user_phone || "-"}</td>

                            </tr>
                            // </Link>
                        ))}
                    </Table>
                </>
            }
            <Pagination
                className="pagination-bar pagination-right"
                currentPage={currentPage}
                totalCount={requestsCount}
                pageSize={15}
                onPageChange={(page) => {
                    setLoading(true);
                    setCurrentPage(page)
                    getAllRequestsFunc(page)
                }}
            />
        </MainContentWrapper>
    )
}

export default Requests