import React, { useEffect, useState } from "react";
const Table = (props) => {
    return <div className="table-container" style={{ height: props.height || "calc(100% - 100px)", overflow: "auto", marginTop: props.marginTop || "10px" }}>
        <table>
            {props.children}
        </table>
    </div>
}

const TableHeader = (props) => <tr className='table_header_row'> {props.children} </tr>

export { Table, TableHeader }