import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getRevenue = (filter,type) => {
    if (filter.value === "All") filter = { ...filter, value: "" }
    return new Promise(async (resolve, reject) => {
        try {
            let data;
            const token = getToken();
            const params = new URLSearchParams();
            if (filter.type !== undefined && filter.value !== undefined && filter.value!=='') {
                params.append(filter.type, filter.value);
            }
            if (filter.drone !== undefined &&filter.drone!=="All") {
                params.append("drone", filter.drone);
            }
            if (filter.droneuin !== undefined && filter.droneuin!=="All") {
                params.append("droneuin", filter.droneuin);
            }
            let url = SERVER_URLS.BACKEND_SERVER + `/dsp/revenue/${type}`;
            if(params.size>0){
                url += `?${params.toString()}`
            }
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.ok && response.status === 200) {
                data = await response.json();
                resolve(data.totalRevenue);
            } else if (response.status === 204) {
                resolve({ requests: [] });
            } else {
                reject(data?.message || "Something went wrong while getting requests.");
            }
        } catch (error) {
            console.log(error);
            reject("Something went wrong while getting requests.");
        }
    });
};
export const getRevenueForWeekOrMonth = (type, startDate, endDate, filter,selectedTab) => {
    if (filter.value === "All") filter = { ...filter, value: "" }
    return new Promise(async (resolve, reject) => {
        try {
            let data;
            const token = getToken();
            const params = new URLSearchParams();
            if (filter.type !== undefined && filter.value !== undefined && filter.value!=="") {
                params.append(filter.type, filter.value);
            }
            if (filter.drone !== undefined &&filter.drone!=="All") {
                params.append("drone", filter.drone);
            }
            if (filter.droneuin !== undefined && filter.droneuin!=="All") {
                params.append("droneuin", filter.droneuin);
            }
            let url = SERVER_URLS.BACKEND_SERVER + `/dsp/revenue/${selectedTab}/${startDate}/${endDate}/${type}`;
            if(params.size>0){
                url += `?${params.toString()}`
            }
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.ok && response.status === 200) {
                data = await response.json();
                resolve(data);
            } else if (response.status === 204) {
                resolve({ requests: [] });
            } else {
                reject(data?.message || "Something went wrong while getting requests.");
            }
        } catch (error) {
            console.log(error);
            reject("Something went wrong while getting requests.");
        }
    });
};