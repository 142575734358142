import React, { useEffect, useState } from 'react'
import { Button } from '../../Components/Helper'
import { ROUTES } from '../../routes'
import { reduceLongName } from '../../ReusableFunctions/reusableFunctions'
import { AEROMEGH_SERVICES, STATUS, STATUS_MESSAGES } from '../../appConstants'
import dateFormat from 'dateformat';
import useWindowSize from '../../hooks/useWindowSize'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { useLocation } from 'react-router'
import VerticalStepper from '../../ReusableComponents/VerticalStepper'
import { getRequest } from '../../apis/requests'
import RequestDiscount from '../../ReusableComponents/RequestDiscount'
import Loading from '../../ReusableFunctions/Loader'
const RequestDetails = () => {
    const { state } = useLocation();
    console.log('state:', state.request)
    const [steps, setSteps] = useState([])

    const [request, setRequest] = useState(state.request)

    const isTrainingService = request?.type === AEROMEGH_SERVICES.TRAINING
    const isSprayingService = request?.type === AEROMEGH_SERVICES.SPRAYING;
    const isSurveyService = request?.type === AEROMEGH_SERVICES.SURVEY;

    const fieldData = [
        { label: 'Request Number', value: request?.req_number },
        { label: 'Customer Name', value: request?.user_name },
        { label: 'Location', value: request?.location_address, overflow: true },
        { label: 'Contact No.', value: request?.user_phone },
        { label: 'Service', value: request?.type },
        { label: 'Service Location', value: request?.field_address, overflow: true },
        (isSprayingService || isSurveyService) && { label: 'Drone Type', value: request?.type },
        isSprayingService && { label: 'Crop', value: request?.crop_name },
        isSprayingService && { label: 'Agro Chemicals', value: request?.pesticides },
        (isSprayingService || isSurveyService) && { label: 'Drone UIN', value: request?.drone_uin },
        isTrainingService && { label: 'Date', value: request?.date_created ? dateFormat(request.date_created, "dd/mm/yyyy") : "-" },
        isTrainingService && { label: 'Time', value: request?.date_created ? dateFormat(request.date_created, "hh:MM TT") : "-" },
        (isSprayingService || isSurveyService) && { label: 'Date', value: request?.time ? dateFormat(request.time, "dd/mm/yyyy") : "-" },
        (isSprayingService || isSurveyService) && { label: 'Time', value: request?.time ? dateFormat(request.time, "hh:MM TT") : "-" },
        (isSprayingService || isSurveyService) && {
            label: 'Pilot',
            value: request?.pilot_name ? reduceLongName(request.pilot_name, 20) : "-"
        },
        { label: 'Status', value: request?.status },
        isTrainingService && { label: 'Price', value: request?.price ? request.price + " Rs." : "-" }
    ].filter(Boolean);

    const Field = ({ label, value, overflow }) => (
        <div className='single_field_wrapper' style={{ lineHeight: overflow ? 1.15 : 1.1 }}>
            <div className='single_field_label' >{label}</div>
            <div className='single_field_value' style={{
                maxHeight: overflow && value?.length > 120 ? "100px" : "none",
                overflowY: overflow && value?.length > 120 ? "auto" : "hidden"
            }}>
                {value || "-"}
            </div>
        </div>
    );

    const getRequestDetails = async () => {
        let requestData = await getRequest({ type: state.request.type, id: state.request.id })
        setRequest({ ...request, ...requestData.request })
        setSteps(requestData.request.status_history.map(record => {
            return {
                label: record.updated_status,
                content: <>
                    <div style={{
                        color: "rgb(59, 59, 59)",
                        fontSize: "13px",
                        fontWeight: "500",
                        wordBreak: "break-word",
                        marginBottom: "6px",
                    }}>{
                            record.updated_status == STATUS.PLACED ? STATUS_MESSAGES.PLACED :
                                record.updated_status == STATUS.ACCEPTED ? STATUS_MESSAGES.ACCEPTED :
                                    record.updated_status == STATUS.RESCHEDULED ? STATUS_MESSAGES.RESCHEDULED :
                                        record.updated_status == STATUS.OUT_FOR_SERVICE ? STATUS_MESSAGES.OUT_FOR_SERVICE :
                                            record.updated_status == STATUS.INPROGRESS ? STATUS_MESSAGES.INPROGRESS :
                                                record.updated_status == STATUS.COMPLETED ? STATUS_MESSAGES.COMPLETED :
                                                    record.updated_status == STATUS.PAID ? STATUS_MESSAGES.PAID :
                                                        record.updated_status == STATUS.CANCELLED ? STATUS_MESSAGES.CANCELLED : ""
                        }</div>
                    <div style={{ color: "#727272", fontSize: "13px", fontWeight: "600" }}>{`${dateFormat(new Date(record.time), "h : MM TT")}, ${dateFormat(new Date(record.time), "d mmmm yyyy")}`}</div>
                </>,
                statusColor: record.updated_status == STATUS.RESCHEDULED ? "#E27444" : record.updated_status == STATUS.CANCELLED ? "#EB0000" : "#32B338",
                highlightStatus: record.updated_status == STATUS.RESCHEDULED || record.updated_status == STATUS.CANCELLED
            }
        }))
        Loading.set(false)
    }
    useEffect(() => {
        Loading.set(true)
        getRequestDetails()
    }, [])

    return (<><MainContentWrapper >
        <div style={{ background: "white", height: "auto", width: "100%", }}>

            <div style={{
                position: "relative",
                width: "100%",
                height: "12%",
                padding: "20px 30px",
                borderBottom: "1px solid rgba(128, 128, 128, 0.24)",
                fontSize: "1rem",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                color: "#1E1E1E",
                fontWeight: "500",
            }}>
                <div>
                    Request Details
                </div>
                <div className='request_details_footer' style={{ justifyContent: (request?.user_id == null || request?.user_id == '') ? "space-between" : "flex-end" }}>
                    {(request?.user_id == null || request?.user_id == '') && (
                        <div style={{
                            color: "crimson",
                            maxWidth: "400px",
                            wordWrap: "break-word",
                            lineHeight: 1.1,
                            fontSize: "13px",
                        }}>
                            Request cannot be modified as the user has deleted their account.
                        </div>)}
                    {request?.status === STATUS.PAID ? null
                        : <Button
                            isDisabled={request?.user_id === null || request?.user_id === ''}
                            text={"Modify Request"}
                            redirectTo={request?.status === STATUS.PAID || request?.user_id === null || request?.user_id === '' ? null : ROUTES.VIEWREQUEST}
                            state={{ requestId: request?.id, requestType: request?.type, assingedPilot: request?.pilot_name }}
                            className={"request_details_modify_btn"}
                        />}
                </div>
            </div>
            <div style={{ background: "white", position: "relative", display: "flex", width: "100%" }}>
                <div style={{ width: "100%", height: "auto", borderRight: "1px solid #8080803d" }}>
                    <div style={{ position: "relative", padding: "20px 30px", height: "auto", width: "100%", }}>
                        <div className='request_fields_wrapper'>
                            {fieldData.map((field, index) => (
                                <Field key={index} label={field.label} value={field.value} overflow={field.overflow} />
                            ))}
                        </div>
                    </div>
                    <div style={{ width: "35%", margin: "25px 25px 30px 25px" }}>
                        {request?.invoice && <RequestDiscount
                            hideToggle
                            request={request}
                        />}
                    </div>
                </div>
                {steps.length > 0 && <div style={{ position: "relative", height: "100%", width: "30%", }}>
                    <VerticalStepper steps={steps} />
                </div>}
            </div>
        </div>
    </MainContentWrapper > </>);
};
export default RequestDetails;