import React from 'react'
import { GLOBAL_STYLES } from '../../../../../appConstants'

const SideIcon = ({ icon, style, iconsStyle }) => {
    return (
        <div style={{ background: GLOBAL_STYLES.LOW_INACTIVE_GREY_COLOR, borderRadius: "50%", overflow: 'hidden', width: "24px", height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center', ...style }}>
            <img src={icon} alt="phone icon" style={{ ...iconsStyle }} />
        </div>
    )
}

export default SideIcon