import { getDSPId } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const createDSPOperator = (operator) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + "/dsp/operator" ,
                {
                    method: "POST",
                    body: JSON.stringify({ operator }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data.createdOperator);
            } else {
                reject(data?.message || "Something went wrong while creating team member.");
            }
        } catch (error) {
            console.log(error)
            reject(error || "Something went wrong while creating team member.");
        }
    });
};

export const getOperator = (operatorId, email) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/dsp/operator?id=${operatorId}&email=${email}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting member details."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting member details.");
        }
    });
};

export const checkDSPExist = (email) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/dsp/operator/` + email,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.status === 200) {
                resolve(data);
            } else if (response.status === 403) {
                reject(
                    data?.message || "Phone or email already exists."
                )
            }
            else {
                reject(
                    data?.message || "Something went wrong while checking dsp existance."
                );
            }
        } catch (error) {
            reject("Something went wrong while checking dsp existance.");
        }
    });
};

export const getDSPOperators = (pageNumber, pageSize) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/dsp/operator/getDspOperators?pageNumber=${pageNumber}&pageSize=${pageSize}` ,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting members."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting members.");
        }
    });
};

export const getDSPOperatorsCount = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/dsp/operator/opCount` ,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data.operatorCount[0]);
            } else {
                reject(
                    data?.message || "Something went wrong while getting members count."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting members count.");
        }
    });
};

export const updateDSPOperator = (operator) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + "/dsp/operator" ,
                {
                    method: "PUT",
                    body: JSON.stringify({ operator }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(data?.message || "Something went wrong while updating team member.");
            }
        } catch (error) {
            reject("Something went wrong while updating team member.");
        }
    });
};

export const deleteDSPOperator = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                `${SERVER_URLS.BACKEND_SERVER}/dsp/operator/` + id ,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while deleting oprator"
                );
            }
        } catch (error) {
            reject("Something went wrong while while deleting oprator");
        }
    });
};